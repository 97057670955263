@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#design {
  .main_wrap {
    .imgbox {
      background: url('../img/design/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .commn_space_wrap {
    .max1000 {
      max-width: 1000px;
      width: 95%;
      margin: 0 auto;
    }

    .sec {
      .flex_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .commn_space_ttl {
        text-align: center;
        margin-bottom: 80px;

      }

      .sec-inner {
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
      }
    }

    .t_pa {
      color: rgba(135, 98, 57, 0.2);
      font-size: clamp(10px, 14vw, 40px);
      letter-spacing: clamp(0em, 0vw, 0.03em);
      line-height: 1;
      font-family: "tsunagi_g", sans-serif;
      margin-bottom: 40px;
    }

    .t_p1 {
      font-size: clamp(10px, 5vw, 28px);
      font-family: 'k_goth6', sans-serif;
      margin-bottom: 30px;

      &.sty2 {
        font-size: clamp(10px, 5.5vw, 22px);
        margin-bottom: 10px;
      }

      &.alp1 {
        color: #876239;
        font-family: "apotf", sans-serif;
        font-size: clamp(10px, 8vw, 40px);
        margin-bottom: 10px;
        letter-spacing: .1em;
      }

      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
      }
    }

    .t_p2 {
      font-size: clamp(10px, 4vw, 16px);
      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
        line-height: 2em;
      }
    }

    .t_p3 {
      font-size: clamp(10px, 3.5vw, 14px);
      font-feature-settings: "palt";
      letter-spacing: 0.1em;
      line-height: 1.6;
    }

    sup {
      position: relative;

      i {
        font-size: clamp(10px, 2.5vw, 12px);
        font-feature-settings: "palt";
        letter-spacing: 0em;
        line-height: 1;
        position: relative;
        font-style: normal;
        position: absolute;
        position: absolute;
        right: -1.2em;
      }
    }

    .t_note {
      font-size: clamp(10px, 2.5vw, 12px);
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1.3;
    }

    .ttl_p {
      font-size: clamp(10px, 7vw, 26px);
      //font-family: "k_goth6",sans-serif;
      line-height: 2;
      position: relative;
    }

    .i_h2_01 {
      position: relative;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1000px;
      margin-bottom: 80px;
    }

    .i_img_04 {
      position: relative;
      width: 20%;
      max-width: 140px;
      float: right;
      display: inline-block;
      margin-right: 60px;
    }

    .ill1 {
      position: absolute;
      width: 24%;
      right: 0;
      bottom: -36px;
      z-index: 5;
      transform: translateX(-20px) translateY(0);

      &.scrollin {
        transform: translate(0, 0);
      }
    }


    .h3_p {
      text-align: center;
      color: rgba(#876239, .2);
      font-size: clamp(10px, 14vw, 120px);
      letter-spacing: clamp(0em, 0vw, 0.03em);
      line-height: 1;
      font-family: 'tsunagi_g', sans-serif;
      margin-bottom: 50px;
    }

    figure {
      position: relative;

      .note {
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 3;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
        &.b {
          color: #000;
          text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
        }

        &.rrt {
          //position: relative;
          //right: auto;
          //bottom: auto;
          bottom: -12px;
          text-align: right;
        }
      }
    }


    .txt_bg {
      position: relative;
      z-index: -1;
      padding-top: 30px;
      margin-bottom: 80px;

      &.concept-p{
        margin-bottom: 40px;
      }
      .bg_p {
        position: absolute;
        //top: 0;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(#876239, .2);
        font-size: clamp(10px, 24vw, 280px);
        letter-spacing: clamp(0em, 0vw, 0.03em);
        line-height: 1;
        font-family: 'tsunagi_g', sans-serif;
        &.concept_p {
          top: 10%;
          font-size: clamp(10px, 20vw, 240px);

        }
        @media screen and (max-width: inc_set.$media_sp) {
          top: 0%;
          &.concept_p {
            top: 0%;
          }
        }
        &.info_p {
          font-size: clamp(10px, 15vw, 175px);
        }
      }

      .txt_bg_in {
        p {
          text-align: center;
          letter-spacing: .01em;
        }

        .bg_p1 {
          font-size: clamp(10px, 5.5vw, 28px);
          //margin-bottom: 20px;
          margin-bottom: 80px;
          font-family: 'k_goth6', sans-serif;
          &.concept-p{
            margin-bottom: 100px;

          }
          @media screen and (max-width: inc_set.$media_sp) {
            margin-bottom: 10vw;
            &.concept-p{
              margin-bottom: 8vw;
            }
          }
        }

        .bg_p2 {
          font-size: clamp(10px, 3.8vw, 18px);
          font-feature-settings: "palt";
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .main_wrap {
      .imgbox {
        // background: url('../img/design/main_sp.jpg');
        background-position: center top;
        background-size: cover;
      }
    }
    .main_txt .p1 {
      font-feature-settings: "palt";
      //letter-spacing: 0.1em;
      i {
        display: inline-block;
        margin-right: -1em;
      }
    }
    .commn_space_wrap {
      .sec {
        .commn_space_ttl {

          margin-bottom: 10vw;

        }
      }

      .ttl_p {
        font-size: clamp(10px, 5.2vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        transform: translateY(-20px);
      }

      .h3_p {
        font-size: clamp(10px, 12vw, 120px);
      }

      .h4_p {
        font-size: clamp(14px, 3.8vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        white-space: nowrap;
        height: 28px;
        line-height: 28px;

        &::after {
          width: 100%;
        }

        sup {
          display: block;

          i {
            right: 0;
            top: -30px;
          }
        }

        &.sc9 {
          b {
            display: inline-block;
            transform: scaleX(0.8);
            transform-origin: 0% 0%;

          }


        }

        &::before {
          top: 2px;
          width: 22px;
          height: 22px;

        }

        padding-left: 26px;
      }

      .t_pa {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }

      .t_p1 {

        font-size: clamp(10px, 5.5vw, 22px);
        margin-bottom: 6vw;
        letter-spacing: 0;

        &.sty2 {
          //font-size: clamp(10px,4.2vw,20px);
          font-size: clamp(10px, 4.8vw, 20px);
        }
      }

      .t_p2 {
        font-size: clamp(10px, 3.9vw, 16px);
        letter-spacing: 0.01em;

        &.sty2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }

      .t_p3 {
        font-size: clamp(10px, 3.5vw, 14px);
        font-feature-settings: "palt";
        letter-spacing: 0.1em;
        line-height: 1.6;
      }

      .txt_bg {
        padding-top: 0px;
        margin-bottom: 6vw;


      }

      .i_h2_01 {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }

      .i_img_04 {
        width: 30%;
        max-width: 140px;
        margin-right: 0px;
        margin-top: -6vw;
      }
    }


    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1 {
    padding: 80px 0;


  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
      padding: 6vw 0;
      padding-bottom: 12vw;


    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2 {
    padding: 80px 0;

    &.conceptsec{
      padding-bottom: 0;
    }
    .sec2a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      flex-wrap: wrap;
      margin-bottom: 80px;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;

      dt {
        width: 40%;
      }

      dd {
        width: 55%;
      }
    }

    .sec2b{
      position: relative;
      margin: 115px 0 50px;
      &::before{
        content: "";
        width: 100%;
        height: 80%;
        background: #F5F2E9;
        position: absolute;
        top: -5%;
        left: 0;
        z-index: -1;
      }
      .sec2b_in{
        display: flex;
        justify-content: flex-end;
        .txt_area{
          width: 45%;
          // max-width: 450px;
          max-width: 500px;
          margin-right: -18vw;
          .t_p2{
            font-size: clamp(10px,1.5vw,17px);
          }
        }
        .img_area{
          width: 70%;
        }
      }

    }

    .sec2_txt_bg{
      .txt_bg_in{
        .bg_p1{
          margin-bottom: 30px;
        }
      }
    }
  }


  .sec3 {
    padding: 80px 0;
    padding-top: 0;
    .txt_bg{
      .bg_p{
        font-size: clamp(10px, 20vw, 200px);
      }
    }
    .sec2a{
      // max-width: 1400px;
      max-width: 1550px;
      width: 88%;
      margin-left: 0;
      margin-right: 0;
      align-items: flex-end;
      dt{
        width: 58%;
      }
      dd{
        width: 38%;
      }
    }
    .sec3a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin-top: 40px;

      li {
        width: calc(90% / 5);
        box-sizing: border-box;
        margin-bottom: 20px;

        p {
          font-feature-settings: "palt";
          letter-spacing: 0;


        }
      }
    }
  }

  .sec4{
    .bnr{
      max-width: 1100px;
      width: 95%;
      margin: 50px auto 0;
      transition: 1s;
      &:hover{
        opacity: .7;
      }
    }
  }


  @keyframes scroll-hint-appear {
    0% {
      transform: translateX(40px);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50%,
    100% {
      transform: translateX(-40px);
      opacity: 0;
    }
  }

  .scroll-hint.is-right-scrollable {
    background: linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-right-scrollable.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0)), linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint-icon {
    position: absolute;
    //top: calc(50% - 25px);
    top: calc(30% - 25px);
    left: calc(50% - 60px);
    box-sizing: border-box;
    width: 120px;
    height: 80px;
    border-radius: 5px;
    transition: opacity .3s;
    opacity: 0;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    padding: 20px 5px 10px 5px;
  }

  .scroll-hint-icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    pointer-events: none;
    z-index: 777;
    display: none;
    @media screen and (max-width: 584px) {
      display: block;
    }
  }

  .scroll-hint-text {
    font-size: 10px;
    color: #FFF;
    line-height: 1em;
    /*margin-top: 5px;*/
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon {
    opacity: .8;
  }

  .scroll-hint-icon:before {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #FFF;
    vertical-align: middle;
    text-align: center;
    content: "";
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDM8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIiBmaWxsPSIjZmZmIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon:after {
    content: "";
    width: 34px;
    height: 14px;
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDE8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIgZmlsbD0iI2ZmZiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiIGZpbGw9IiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==);
    opacity: 0;
    transition-delay: 2.4s;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:after {
    opacity: 1;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:before {
    animation: scroll-hint-appear 1.6s linear;
    animation-iteration-count: infinite;
  }

  .scroll-hint-icon-white {
    background-color: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .4);
  }

  .scroll-hint-icon-white:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDQ8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon-white:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDI8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiLz48L2c+PC9nPjwvc3ZnPg==);
  }

  .scroll-hint-icon-white .scroll-hint-text {
    color: #000;
    line-height: 1em;
  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec2 {
      padding: 6vw 0;

      .sec2a {
        margin-bottom: 6vw;
        width: 95%;
        margin-left: auto;
        margin-right: auto;

        dt {
          width: 100%;
          margin-bottom: 6vw;
        }

        dd {
          width: 100%;
        }
      }

      .sec2b{
        margin: 60px auto 50px;
        // &::before{
        //   height: 80%;
        //   top: -5%;
        // }
        .sec2b_in{
          flex-wrap: wrap;
          width: 95%;
          margin: 0 auto;
          .txt_area{
            width: 100%;
            max-width: none;
            margin-right: 0;
            .t_p2{
              font-size: clamp(10px,4vw,16px);
            }
          }
          .img_area{
            width: 100%;
            margin-top: 20px;
          }
        }

      }
    }

    .sec3 {
      padding: 6vw 0;

      .sec3a {
        margin-top: 12vw;

        li {
          width: 48%;
          margin-bottom: 6vw;

        }
      }
    }

    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
