@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#company {
  .main_wrap {
    .imgbox {
      background: url("../img/company/main.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .company__hr {
    border: 1px solid #787474;
  }

  .company__wrap {
    .sec {
      .max1300 {
        max-width: 1000px;
        width: 95%;
        margin-inline: auto;
      }
      .company__img-box {
        margin-top: 80px;
        margin-inline: auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        .box{
          width: calc(96%/3);
          margin: 8px 0;
        }
      }
      .img {
        position: relative;
        p {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 10px;
          color: #fff;
          padding: 10px;
          text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
        }
      }
      .box_img-txt {
        font-size: clamp(10px, 3.5vw, 14px);
        font-family: serif;
        letter-spacing: .1em;
      }
      .border_b{
        border-bottom: solid 1px #000;;
      }
      .s1_txt {
        text-align: center;
        .s1_p1 {
          font-size: clamp(10px, 5.5vw, 32px);
          letter-spacing: 0.01em;
          font-family: "k_goth6";
          margin-bottom: 30px;
        }
        .s1_p2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }
      .small {
        font-size: 10px;
        color: #fff;
      }
    }
  }

  .company__content {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .company__logo {
    margin-bottom: 60px;
    width: 100%;
    max-width: 405px;
    margin-inline: auto;
  }

  .company__txt {
    text-align: center;
    font-size: clamp(10px, 3.5vw, 16px);
    letter-spacing: -0.01em;
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .company__logo {
      margin-bottom: 40px;
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .company__wrap {
      .sec {
        .s1_txt {
          .s1_p1 {
            margin-bottom: 30px;
          }
        }
        .company__content {
          padding-top: 60px;
          padding-bottom: 60px;
        }

        .company__img-box {
          margin-top: 40px;
          width: 100%;
          .box{
            width: calc(100%/1);
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1 {
    padding: 80px 0;
    .sec_in {
      // .s1_map {
      //   max-width: 770px;
      //   width: 95%;
      //   margin: 60px auto;
      // }
      // .icon {
      //   justify-content: flex-start;
      //   img {
      //     height: 30px;
      //     width: auto;
      //     margin-right: 10px;
      //   }
      //   p {
      //     font-size: clamp(10px, 4.5vw, 18px);
      //     color: #568534;
      //     font-family: "k_goth6";
      //   }
      // }

      // .s1_box {
      //   .box {
      //     width: calc(97% / 3);
      //     margin: 10px 0;
      //     .box_img {
      //       img {
      //         vertical-align: bottom;
      //       }
      //     }
      //     .box_text {
      //       background: #f2eee7;
      //       text-align: center;
      //       padding: 20px 0 10px;
      //       border-bottom: solid 5px #568534;
      //       p {
      //         font-family: "k_goth6";
      //       }
      //       .s1_p3 {
      //         font-size: clamp(10px, 3.7vw, 20px);
      //         padding-bottom: 10px;
      //         margin-bottom: 10px;
      //         letter-spacing: 0.01em;
      //         position: relative;
      //         &::before {
      //           content: "";
      //           width: 90%;
      //           height: 1px;
      //           background: #000;
      //           position: absolute;
      //           bottom: 0;
      //           left: 50%;
      //           transform: translate(-50%, 0);
      //         }
      //         span {
      //           font-size: clamp(10px, 8vw, 55px);
      //           color: #568534;
      //           letter-spacing: -0.02em;
      //           padding: 0 5px;
      //         }
      //       }
      //       .s1_p4 {
      //         font-size: 12px;
      //         letter-spacing: 0.01em;
      //       }
      //     }
      //   }
      // }
    }
  }
  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
      padding: 50px 0;
      // .sec_in {
      //   .s1_map {
      //     margin: 30px auto;
      //   }
      //
      //   .s1_box {
      //     .box {
      //       width: calc(97% / 2);
      //       margin: 10px 0;
      //       .box_text {
      //         padding: 15px 0 5px;
      //         height: clamp(85px, 23vw, 90px);
      //       }
      //     }
      //   }
      // }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
