@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#map{
  .map_wrap{
    .sec{
      max-width: 1000px;
      width: 95%;
      margin: 150px auto 100px;
      .btn_wrap{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 550px;
        margin: 60px auto 0;
        .btn{
          width: calc(95%/2);
          height: 50px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #4c822e;
          position: relative;
          p{
            color: #fff;
            text-align: center;
            font-size: clamp(10px,3.8vw,16px);
          }
          a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          &:hover{
            opacity: .7;
          }
        }
      }
    }
    .sec2 {
      background-color: #dff0dd;
      max-width: none;
      width: 100%;
      padding: 3% 0 5% 0;
      .sec2a {
        max-width: 1000px;
        width: 95%;
        margin: 0 auto;
        align-items: flex-start;
        .ttl {
          width: 100%;
          text-align: center;
          margin-bottom: 5%;
          p {
            font-size: clamp(10px,5vw,27px);
            font-weight: 700;
          }
        }
        .box {
          width: 45%;
          margin-bottom: 5%;
          p {
            margin: 2% 0;
            font-size: clamp(10px,3.9vw,18px);
            letter-spacing: .03em;
            span{
              vertical-align: sub;
              margin-left: 5px;
              img{
                width: auto;
                height: 22px;
                height: clamp(16px,2vw,24px);
              }
            }
          }
          .box1 {
            margin-top: 3%;
            img {
              width: 30%;
            }
            p {
              width: 67%;
              letter-spacing: .05em;
            }
          }
        }
        .text {
          width: 100%;
          border-top: solid 1px #000;
          border-bottom: solid 1px #000;
          div {
            width: 90%;
            margin: 0 auto;
            padding: 2% 0;
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .map_wrap{
      .sec{
        margin: 80px auto 25px;
        .map_img{
          .pinch{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 0 30px 0;
            p{
              font-size: clamp(15px, 3vw, 22px);
              font-weight: bold;
              letter-spacing: 0;
            }
            img{
              width: clamp(30px, 6vw, 40px);
            }
          }
        }
        .btn_wrap{
          margin: 25px auto 0;
          .btn{
            width: calc(70%/1);
            margin: 5px auto;
          }
        }
      }
      .sec2 {
        padding: 10% 0;
        .sec2a {
          width: 90%;
          div {
            &:nth-child(4) {
              margin-top: 5%;
            }
          }
          .box {
            width: 100%;
            p{
              span{
                img{
                  height: clamp(17px,4.5vw,25px);
                }
              }
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
