@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#plan {
  .main_wrap {
    .imgbox {
      background: url('../img/plan/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .plan_wrap {
    .max1000 {
      max-width: 1000px;
      width: 95%;
      margin: 0 auto;
    }

    p{
      &.col_gray{
        color: #4D4D4F;
      }
    }

    .sec {
      .flex_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
      .plan_ttl {
        text-align: center;
        margin-bottom: 80px;
        .t_p1 {
          font-size: clamp(10px, 4.2vw, 22px);
          font-family: 'k_goth6', sans-serif;
          margin-bottom: 25px;
        }
        .t_p2 {
          font-size: clamp(10px, 3.5vw, 16px);
        }
      }

      .sec-inner {
        // max-width: 1100px;
        width: 95%;
        margin: 0 auto;
        position: relative;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .plan_wrap {
      .sec {
        .plan_ttl {

          margin-bottom: 8vw;

        }
      }

    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1 {
    padding: 80px 0;
    .typebtn_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: stretch;
      flex-wrap: wrap;
      @media screen and (max-width: inc_set.$media_sp) {
        justify-content: space-between;
        max-width: 650px;
      }

      .type {
        width: calc(88% / 3);
        margin-left: 2%;
        margin-right: 2%;
        height: 100%;
        background: #568334;
        border-radius: 5px 22% 5px 5px / 5px 20% 5px 5px;
        padding: 28px 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: .5s;
        position: relative;
        margin-bottom: 40px;
        &.for_space{
          padding: 0;
        }
        .soldtxt{
          display: none;
        }
        &.sold{
          .btn{
            opacity: 0;
          }
          &:hover{
            opacity: 1;
          }
          .soldtxt{
            width: 70%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 6;
            p{
              padding: 10px 17px;
              box-sizing: border-box;
              border: 1px solid #fff;
              border-radius: 40px;
              color: #fff;
              font-size: clamp(10px, 3vw, 30px);
              @media screen and (max-width: inc_set.$media_sp) {
                padding: 5px 10px;
                font-size: clamp(10px, 5vw, 30px);
              }
            }
          }
          .soldbox{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 6;
            @media screen and (max-width: inc_set.$media_sp) {
              width: 100%;
            }
          }
          &::after{
            content: "";
            width: 100%;
            height: 100%;
            // background: rgba(0, 0, 0, 0.6);
            border-radius: 5px 22% 5px 5px / 5px 20% 5px 5px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
          }
        }
        &.dummy{
          background: transparent;
        }
        .new{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background: #E61C24;
          border-radius: 0 35px 35px 0;
          height: 9%;
          width: 32%;
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            color: #fff;
            font-size: clamp(10px,3.5vw,15px);
          }
          @media screen and (max-width: inc_set.$media_sp) {
            height: 7%;
            width: 45%;
            max-width: 120px;
          }
        }

        a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        p {
          color: #fff;
          text-align: center;
          font-family: 'apotf', sans-serif;
          width: 100%;
        }

        .name {
          background: #42210B;
          // border-radius: 3px 20% 3px 3px / 3px 90% 3px 3px;
          border-radius: 0 35px 35px 0;
          width: 90%;
          // margin: 0 auto 25px 0;
          margin: 0 auto 10px 0;
          position: relative;
          .p_menu{
            background: #fff;
            padding: 2px 10px;
            border-radius: 15px;
            position: absolute;
            right: 5%;
            top: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.w_50{
              width: 56%;
              @media screen and (max-width: inc_set.$media_sp) {
                width: auto;
              }
            }
            @media screen and (max-width: inc_set.$media_sp) {
              flex-direction: column;
            }
            p{
              color: #42210b;
              font-size: clamp(10px,1.2vw,14px);
              letter-spacing: .01em;
              @media screen and (max-width: inc_set.$media_sp) {
                font-size: clamp(10px,2vw,15px);
              }
              &:nth-of-type(2){
                font-size: clamp(10px,1vw,11px);
                @media screen and (max-width: inc_set.$media_sp) {
                  margin-top: -5px;
                  font-size: clamp(10px,1.8vw,14px);
                }
              }
              &.letter{
                letter-spacing: -0.05em;
                font-size: clamp(10px,1vw,12px);
                @media screen and (max-width: inc_set.$media_sp) {
                  line-height: 1.2em;
                  font-size: clamp(10px,2vw,15px);
                }
              }
            }
          }
        }

        .p1 {
          font-size: clamp(10px, 2vw, 25px);
          text-align: left;
          padding-left: 25px;
          span {
            font-size: clamp(10px, 7vw, 70px);
            line-height: 1em;
          }
          @media screen and (max-width: inc_set.$media_sp) {
            font-size: clamp(10px, 4vw, 25px);
            span {
              font-size: clamp(10px, 13vw, 70px);
            }
          }
        }

        .p2_wrap{
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          @media screen and (max-width: inc_set.$media_sp) {
            // height: 65px;
            height: clamp(90px,24vw,120px);
          }
        }
        .p2 {
          font-size: clamp(10px, 2.8vw, 25px);
          // margin-bottom: 15px;
          position: relative;
          // padding-bottom: 10px;
          letter-spacing: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          .floor{
            margin-left: 3px;
            font-size: clamp(10px, 1.5vw, 16px);
          }
          @media screen and (max-width: inc_set.$media_sp) {
            flex-direction: column;
            font-size: clamp(10px, 5.3vw, 25px);
            .floor{
              margin-left: 0;
              margin-top: -10px;
              font-size: clamp(10px, 3.5vw, 16px);
              // width: 100%;
              // text-align: left;
            }
          }

          i{
            // display: block;
            width: 100%;
            font-style: normal;
            line-height: 1;
          }
          img {
            display: inline-block;
            width: auto;
            height: 26px;
            margin-top: -4px;
            //margin-bottom: -24px;
          }
        }

        .parking_p{
          background: #00A398;
          text-align: center;
          padding: 3px 15px;
          border-radius: 15px;
          max-width: 195px;
          p{
            color: #ECE250;
            font-size: clamp(10px,3vw,14px);
          }
        }

        .p3 {
          font-size: 14px;
          text-align: left !important;
          box-sizing: border-box;
          padding-left: 24%;
          margin-top: 15px;
          padding-top: 10px;
          position: relative;
          &::before {
            content: "";
            width: 90%;
            height: 1px;
            background: #fff;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }

        .p4 {
          font-size: clamp(10px, 10vw, 40px);
          line-height: 1.2em;

          span {
            font-size: clamp(10px, 6vw, 20px);
          }

          position: relative;
          //&::before{
          //  content: "";
          //  width: 90%;
          //  height: 1px;
          //  background: #fff;
          //  position: absolute;
          //  bottom: 0;
          //  left: 50%;
          //  transform: translate(-50%,0);
          //}
        }

        .p5 {
          font-size: clamp(10px, 4vw, 16px);
          margin: 15px 0;
        }

        .btn {
          background: #F5B614;
          width: 50%;
          // border-radius: 3px 20% 3px 3px / 3px 65% 3px 3px;
          border-radius: 20px 20px 0 20px;
          padding: 10px 0;
          margin-right: 4vw;
          position: relative;

          p {
            font-size: clamp(10px, 4vw, 16px);
            position: relative;
            text-align: left;
            padding-left: 20px;
          }

          .arrow {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 15px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        &:hover {
          opacity: .7;
        }

        .ill {
          position: absolute;
          max-width: 80px;
          width: 25%;
          right: 3%;
          bottom: -7%;

          &.ill_02 {
            max-width: 60px;
            width: 22%;
            bottom: 0;
            @media screen and (max-width: inc_set.$media_sp) {
              width: 4.5vh;
            }
          }

          &.ill_05 {
            max-width: 140px;
            width: 40%;
            right: -30px;
            bottom: -50px;
            @media screen and (max-width: inc_set.$media_sp) {
              width: 9vh;
              right: -10px;
            }
          }
          @media screen and (max-width: inc_set.$media_sp) {
            bottom: -12%;
            right: 0%;
          }
        }
      }
    }

    .premiumplan{
      .p_premiumplan{
        margin: 30px auto 25px;
        width: 100%;
        background: linear-gradient(90deg,#42210B,#FFD800,#42210B);
        padding: 5px 0;
        p{
          color: #fff;
          text-align: center;
          font-size: clamp(10px,4vw,20px);
          font-family: "apotf", sans-serif;
        }
      }
      .type{
        background: #007071;
      }
    }
  }

  .type_btn {
    padding: 0;
    margin: 100px 0 0;
  }

  .sec2 {
    padding: 80px 0;

    .t_pa {
      color: rgba(135, 98, 57, 0.2);
      font-size: clamp(10px, 14vw, 40px);
      letter-spacing: clamp(0em, 0vw, 0.03em);
      line-height: 1;
      font-family: "tsunagi_g", sans-serif;
      margin-bottom: 40px;
    }

    .t_p1 {
      font-size: clamp(10px, 5vw, 20px);
      font-family: 'k_goth6', sans-serif;
      margin-bottom: 25px;

      &.sty2 {
        font-size: clamp(10px, 5.5vw, 24px);
      }

      &.alp1 {
        color: #E6DED9;
        font-family: "apotf", sans-serif;
        font-size: clamp(10px, 6.5vw, 40px);
        margin-bottom: 10px;
      }

      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
      }
    }


    .h4_p {
      position: relative;
      color: #008CD6;
      padding-left: 45px;
      box-sizing: border-box;
      height: auto;
      font-size: clamp(14px, 5vw, 20px);
      line-height: 1.4em;
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-feature-settings: "palt";
      letter-spacing: 0.08em;

      &::after {
        width: calc(100% - 45px);
        height: 1px;
        content: "";
        background-color: #008CD6;
        position: absolute;
        right: 0;
        bottom: 0;

      }

      &::before {
        position: absolute;
        left: 0;
        top: 10px;
        width: 40px;
        height: 40px;
        content: "";
        background: url(../img/plan/ugoclo_04.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }


    }

    .t_p2 {
      font-size: clamp(10px, 4vw, 16px);
      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
      }
      &.t_p2_l {
        text-align: left;
      }
      @media screen and (max-width: inc_set.$media_sp) {
        text-align: center;
      }
    }



    .t_p3 {
      font-size: clamp(10px, 3.5vw, 14px);
      font-feature-settings: "palt";
      letter-spacing: 0.1em;
      line-height: 1.6;
    }

    sup {
      position: relative;

      i {
        font-size: clamp(10px, 2.5vw, 12px);
        font-feature-settings: "palt";
        letter-spacing: 0em;
        line-height: 1;
        position: relative;
        font-style: normal;
        position: absolute;
        position: absolute;
        right: -1.2em;
      }
    }

    .t_note {
      font-size: clamp(10px, 2.5vw, 12px);
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1.3;
    }

    .ttl_p {
      font-size: clamp(10px, 7vw, 26px);
      //font-family: "k_goth6",sans-serif;
      line-height: 2;
      position: relative;
    }

    .sec2a {
      display: block;
      box-sizing: border-box;
      position: relative;
      padding: 30px;
      border: 1px solid #000;
      margin-top: 60px;
      margin-bottom: 10px;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 6vw;
        padding: 6vw;
      }

      > dl {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;

        dt {
          display: inline-block;
          margin: 0 2%;

          .t_p1 {
            margin: 0;
            font-size: clamp(10px, 5vw, 24px);
            @media screen and (max-width: inc_set.$media_sp) {
              margin-bottom: 6vw;
            }
          }

        }

        dd {
          display: inline-block;
          margin: 0 2%;

          figure {
            width: 100%;
            max-width: 240px;
            @media screen and (max-width: inc_set.$media_sp) {
              width: 80%;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    .sec2b {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 40px;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 6vw;
      }

      dt {
        width: 32%;
        box-sizing: border-box;
        position: relative;
        figure {
          margin-top: 15px;
          // height: 100%;
          img.bg {
            height: 100%;
            object-fit: cover;
            width: 75%;
            margin: 0 0 0 auto;
            @media screen and (max-width: inc_set.$media_sp) {
              width: 65%;
            }
          }
          @media screen and (max-width: inc_set.$media_sp) {
            // width: 80%;
            margin-bottom: 6vw;
            margin-left: auto;
            margin-right: auto;
          }
          .cv {
            position: absolute;
            left: -20px;
            bottom: 25%;
            width: 40%;
            @media screen and (max-width: inc_set.$media_sp) {
              // width: 50%;
              width: 40%;
              bottom: 10%;
              left: 0px;
            }
          }

          // &.i_ugoclo_01 {
          //   @media screen and (max-width: inc_set.$media_sp) {
          //     margin-bottom: 6vw;
          //     margin-left: auto;
          //     margin-right: auto;
          //   }
          // }

        }
        @media screen and (max-width: inc_set.$media_sp) {
          width: 100%;
          margin-bottom: 6vw;
          p{
            text-align: center;
          }
        }
      }

      dd,dl {
        width: 30%;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // flex-wrap: wrap;
        // box-sizing: border-box;
        position: relative;
        // align-items: stretch;
        @media screen and (max-width: inc_set.$media_sp) {
          width: 100%;
          margin-top: 6vw;
        }

        figure {
          @media screen and (max-width: inc_set.$media_sp) {
            width: 80%;
            margin-bottom: 6vw;
            // margin-left: auto;
            width: 90%;
            margin-left: 0;
            margin-right: auto;
          }
          img.bg {
            height: 100%;
            object-fit: cover;
          }
          .cv {
            position: absolute;
            right: -20px;
            top: -20px;
            width: 32%;
            @media screen and (max-width: inc_set.$media_sp) {
              // width: 50%;
              width: 35%;
              right: -30px;
              top: -37px;
            }
          }
        }
      }
      dl{
        // width: 28%;
      }


    }

    figure {
      position: relative;

      img {
        display: block;
      }

      .note {
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 3;
        color: #fff;

        &.b {
          color: #000;
        }
        &.tl{
          right: auto;
          left: 5px;
        }
      }
    }

    .sec2c {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 40px;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 6vw;
      }
      dt {
        width: 30%;

        p.t_p2 {
          padding-left: 45px;
          box-sizing: border-box;
        }
        @media screen and (max-width: inc_set.$media_sp) {
          width: 100%;
          margin-bottom: 6vw;

          .h4_p{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap:wrap;


            height: auto;
            padding-left: 0;
            text-align: center;
            width: 100%;
            &:before {
              position: relative;
              display: inline-block;
              margin-right: 10px;
              top: auto;
              left: auto;
              width: 40px;
              height: 40px;
            }
          }
          p.t_p2 {
            padding-left: 0;
            text-align: center;
          }
        }
      }

      dd {
        width: 68%;
        @media screen and (max-width: inc_set.$media_sp) {
          width: 100%;
          figure{
            padding-bottom: 20px;
          }
        }
      }
    }

    .sec2d {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 40px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 6vw;
      }
      dt {
        box-sizing: border-box;
        width: 34%;
        @media screen and (max-width: inc_set.$media_sp) {
          width:80%;
          	margin-left: auto;
          		margin-right: auto;
          margin-bottom: 6vw;

        }
        p.t_p2 {
          padding-left: 45px;
          box-sizing: border-box;
        }

        p {
          text-align: center;

          &.p1 {
            font-size: clamp(10px, 3.5vw, 13px);
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #000;
            box-sizing: border-box;
          }

          &.p2 {
            font-size: clamp(10px, 3.5vw, 14px);
            box-sizing: border-box;
          }

          &.p3 {
            font-size: clamp(10px, 5vw, 22px);
            color: #B66986;
          }

          &.p4 {
            font-size: clamp(10px, 3.5vw, 13px);
          }
        }
      }

      dd {
        width: 31%;
        @media screen and (max-width: inc_set.$media_sp) {
          width: 49%;
        }
      }
    }

    .sec2e {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 40px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      align-items: stretch;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 6vw;
      }
      dt {
        box-sizing: border-box;
        width: 34%;
        background-color: #DBB4C3;
        display: flex;
        justify-content: center;
        align-items: center;

        flex-wrap: wrap;
        @media screen and (max-width: inc_set.$media_sp) {
          width:100%;
          height: 80px;
                    margin-bottom: 6vw;

        }
        p.t_p2 {
          padding-left: 45px;
          box-sizing: border-box;
        }

        p {
          text-align: center;

          &.p1 {
            font-size: clamp(10px, 4.5vw, 18px);
            color: #fff;
            box-sizing: border-box;

          }

        }
      }

      dd {
        width: 20%;
        @media screen and (max-width: inc_set.$media_sp) {
          width:32%;

        }
        p {
          font-feature-settings: "palt";
          letter-spacing: 0;
          font-size: clamp(10px, 3.2vw, 14px);
          sup {
            i {
              font-size: clamp(10px, 2.5vw, 10px);
            }
          }
        }
      }
    }

    .sec2f {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 80px;
      align-items: stretch;
      box-sizing: border-box;
      @media screen and (max-width: inc_set.$media_sp) {
        margin-top: 10vw;
      }
      li {
        box-sizing: border-box;
        width: 31%;
        @media screen and (max-width: inc_set.$media_sp) {
          width: 100%;
          margin-bottom: 6vw;
        }
        figure {
          margin-top: 20px;
        }

        &:nth-child(1) {
          width: 34%;
          @media screen and (max-width: inc_set.$media_sp) {
            width: 100%;
            // margin-bottom: 6vw;
            margin-bottom: 20vw;
          }
        }

        &:nth-child(2) {
          width: 30%;
          @media screen and (max-width: inc_set.$media_sp) {
            width: 100%;
            // margin-bottom: 6vw;
            margin-bottom: 20vw;
          }
        }

        &:nth-child(3) {
          width: 26.2%;
          @media screen and (max-width: inc_set.$media_sp) {
            width: 100%;
            margin-bottom: 6vw;
          }
        }

        p.t_p2 {
          padding-left: 45px;
          box-sizing: border-box;
        }

        p {
          text-align: center;
          &.p1 {
            font-size: clamp(10px, 3.5vw, 14px);
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #000;
            box-sizing: border-box;
          }

          &.p2 {
            font-size: clamp(10px, 3.7vw, 16px);
            box-sizing: border-box;
          }

          &.p3 {
            font-size: clamp(10px, 5vw, 22px);
            color: #B66986;
          }

          &.p4 {
            font-size: clamp(10px, 3.7vw, 16px);
          }
        }
      }

    }

    .i_logo_usplus {
      max-width: 100px !important;
    }

    .i_ill_06 {
      position: absolute;
      right: 0;
      top: -100px;
      z-index: 5;
      width: 20%;
      @media screen and (max-width: inc_set.$media_sp) {
        display: none;
      }
    }

    .p_logo_usplus {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dotted #ccc;
      line-height: 1.8em;
    }

  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
      padding: 40px 0;

      .typebtn_wrap {
        .type {
          width: 48%;
          margin-left: 0;
          margin-right: 0;
          // margin: 15px auto;
          border-radius: 5px 26% 5px 5px/5px 16% 5px 5px;
          padding: 20px 0 17px;
          .name {
            width: 90%;
            padding: 15px 0;
          }
          .p1 {
            padding-left: 8px;
          }
          .p3 {
            margin-right: 16%;
          }
          .p4 {
            letter-spacing: .05em;
            margin-bottom: 5px;
          }
          .btn {
            width: 85%;
            margin: 0 auto;
          }
          .ill {
            // width: 32%;
            width: 6.5vh;
          }
        }
      }
    }

    .type_btn {
      margin: 80px 0 0;
      padding: 0;
    }

    .sec2 {
      padding: 40px 0 0;

      .ttl_p {
        font-size: clamp(10px, 5.2vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        transform: translateY(-20px);
      }

      .h3_p {
        font-size: clamp(10px, 12vw, 120px);
      }

      .h4_p {
        font-size: clamp(14px, 5vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        white-space: nowrap;
        height: 28px;
        line-height: 28px;

        &::after {
          width: 100%;
        }

        sup {
          display: block;

          i {
            right: 0;
            top: -30px;
          }
        }

        &.sc9 {
          b {
            display: inline-block;
            transform: scaleX(0.8);
            transform-origin: 0% 0%;

          }


        }

        &::before {
          top: 2px;
          width: 22px;
          height: 22px;

        }

        padding-left: 26px;
      }

      .t_pa {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }

      .t_p1 {

        font-size: clamp(10px, 5.5vw, 22px);
        margin-bottom: 6vw;
        letter-spacing: 0;

        &.sty2 {
          //font-size: clamp(10px,4.2vw,20px);
          font-size: clamp(10px, 4.8vw, 20px);
        }

        text-align: center;

        &.alp1 {
          font-size: clamp(10px, 8.5vw, 40px);
          margin-bottom: 3vw;
        }
      }

      .t_p2 {


        font-size: clamp(10px, 4vw, 16px);
        letter-spacing: 0.01em;

        &.sty2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }

      .t_p3 {
        font-size: clamp(10px, 3.5vw, 14px);
        font-feature-settings: "palt";
        letter-spacing: 0.1em;
        line-height: 1.6;
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
}
