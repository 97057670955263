@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#commn_space {
  .main_wrap {
    .imgbox {
      background: url('../img/commn_space/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .commn_space_wrap {
    .max1000 {
      max-width: 1000px;
      width: 95%;
      margin: 0 auto;
    }

    .sec {
      .flex_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .commn_space_ttl {
        text-align: center;
        margin-bottom: 80px;

      }

      .sec-inner {
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
      }
    }
    .t_pa {
      color: rgba(135,98,57,0.2);
      font-size: clamp(10px,14vw,40px);
      letter-spacing: clamp(0em,0vw,0.03em);
      line-height: 1;
      font-family: "tsunagi_g",sans-serif;
      margin-bottom: 40px;
    }
    .t_p1 {
      font-size: clamp(10px, 5vw, 24px);
      font-family: 'k_goth6', sans-serif;
      margin-bottom: 25px;
    }

    .t_p2 {
      font-size: clamp(10px, 4vw, 16px);
    }

    .t_p3 {
      font-size: clamp(10px, 3.5vw, 14px);
      font-feature-settings: "palt";
      // letter-spacing: 0.1em;
      letter-spacing: 0.06em;
      line-height: 1.6;
      &.letter_s{
        letter-spacing: 0.006em;
      }
    }

    sup {
      position: relative;

      i {
        font-size: clamp(10px, 2.5vw, 12px);
        font-feature-settings: "palt";
        letter-spacing: 0em;
        line-height: 1;
        position: relative;
        font-style: normal;
        position: absolute;
        position: absolute;
        right: -1.2em;
      }
    }

    .t_note {
      font-size: clamp(10px, 2.5vw, 12px);
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1.3;
    }

    .ttl_p {
      font-size: clamp(10px, 7vw, 26px);
      //font-family: "k_goth6",sans-serif;
      line-height: 2;
      position: relative;
    }

    .ill1 {
      position: absolute;
      width: 24%;
      right: 0;
      bottom: -36px;
      z-index: 5;
      transform: translateX(-20px) translateY(0);

      &.scrollin {
        transform: translate(0, 0);
      }
    }


    // .h3_p {
    //   text-align: center;
    //   color: rgba(#876239, .2);
    //   font-size: clamp(10px, 16vw, 156px);
    //   letter-spacing: clamp(0em, 0vw, 0.03em);
    //   line-height: 1;
    //   font-family: 'tsunagi_g', sans-serif;
    //   margin-bottom: 50px;
    // }
    .h3_p{
      max-width: 1000px;
      margin: 0 auto 50px;
      width: 95%;
    }

    figure {
      position: relative;

      .note {
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 3;

        &.b {
          color: #000;
        }
        &.rrt{
          //position: relative;
          //right: auto;
          //bottom: auto;
          bottom: -12px;
          text-align: right;
        }
      }
    }

    .h4_p {
      position: relative;
      color: #867060;
      padding-left: 45px;
      box-sizing: border-box;
      height: 40px;
      // font-size: clamp(14px, 5vw, 20px);
      font-size: clamp(14px, 1.5vw, 20px);
      line-height: 40px;
      margin-bottom: 10px;
      font-feature-settings: "palt";
      letter-spacing: 0.08em;

      &::after {
        width: calc(100% - 45px);
        height: 1px;
        content: "";
        background-color: #867060;
        position: absolute;
        right: 0;
        bottom: 0;

      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        content: "";
        background: url(../img/commn_space/ico_01.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }

      &.i12 {
        &::before {
          background: url(../img/commn_space/ico_12.svg);
          background-size: 100%;
        }
      }

      &.i2 {
        &::before {
          background: url(../img/commn_space/ico_02.svg);
          background-size: 100%;
        }
      }

      &.i3 {
        &::before {
          background: url(../img/commn_space/ico_03.svg);
          background-size: 100%;
        }
      }

      &.i4 {
        &::before {
          background: url(../img/commn_space/ico_04.svg);
          background-size: 100%;
        }
      }

      &.i5 {
        &::before {
          background: url(../img/commn_space/ico_05.svg);
          background-size: 100%;
        }
      }

      &.i6 {
        &::before {
          background: url(../img/commn_space/ico_06.svg);
          background-size: 100%;
        }
      }

      &.i7 {
        &::before {
          background: url(../img/commn_space/ico_07.svg);
          background-size: 100%;
        }
      }

      &.i8 {
        &::before {
          background: url(../img/commn_space/ico_08.svg);
          background-size: 100%;
        }
      }

      &.i9 {
        &::before {
          background: url(../img/commn_space/ico_09.svg);
          background-size: 100%;
        }
      }

      &.i10 {
        &::before {
          background: url(../img/commn_space/ico_10.svg);
          background-size: 100%;
        }
      }

      &.i11 {
        &::before {
          background: url(../img/commn_space/ico_11.svg);
          background-size: 100%;
        }
      }
    }

    .txt_bg {
      position: relative;
      z-index: -1;

      .bg_p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(#876239, .2);
        font-size: clamp(10px, 24vw, 280px);
        letter-spacing: clamp(0em, 0vw, 0.03em);
        line-height: 1;
        font-family: 'tsunagi_g', sans-serif;


        &.info_p {

          font-size: clamp(10px, 15vw, 175px);
        }
      }

      .txt_bg_in {
        p {
          text-align: center;
          letter-spacing: .01em;
          font-family: 'k_goth6', sans-serif;
        }

        .bg_p1 {
          font-size: clamp(10px, 5.5vw, 28px);
          margin-bottom: 20px;
        }

        .bg_p2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }
    }
  }

// ______________________________________
  @media screen and (max-width: 887px) {
    .commn_space_wrap {
      .h4_p {
        font-size: clamp(10px,1.4vw,20px);
      }
    }
    /*@media screen end*/
  }
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .main_txt .p1{
      font-feature-settings : "palt";
      //letter-spacing: 0.1em;
      i{
        display: inline-block;
        margin-right: -1em;
      }
    }
    .commn_space_wrap{

      .ttl_p{
        font-size: clamp(10px,5.2vw,20px);
        font-feature-settings : "palt";
        letter-spacing: 0;
            transform:  translateY(-20px);
      }
      .h3_p{
        //font-size: clamp(10px,12vw,120px);
        // margin-bottom: 6vw;
        margin: 0 auto -32vw;
      }
      .h4_p{
        font-size: clamp(14px, 3.8vw, 20px);
        font-feature-settings : "palt";
        letter-spacing: 0;
        white-space: nowrap;
        height: 28px;
        line-height: 28px;
        &::after{
          width: 100%;
        }
        sup{
          display: block;
          i{
            right: 0;
            top: -30px;
          }
        }
        &.sc9{
          b{
            display: inline-block;
            transform: scaleX(0.8);
            transform-origin: 0% 0%;

          }



        }
        &::before{
          top: 2px;
          width: 22px;
          height: 22px;

        }
        padding-left: 26px;
      }
      .no_icon{
        .h4_p{
          padding-left: 0;
          &::before{
            display: none;
          }
        }
      }
      .t_pa {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }
      .t_p1 {
        font-size: clamp(10px,5.8vw,24px);
        margin-bottom: 6vw;
        letter-spacing: 0;
        &.sty2{
          font-size: clamp(10px,4.2vw,20px);
        }
      }

      .t_p2 {


        font-size: clamp(10px,4vw,16px);
        letter-spacing: 0.01em;
        &.sty2{
          font-size: clamp(10px,3.8vw,16px);
        }
      }

      .t_p3 {
        // font-size: clamp(10px, 3.5vw, 14px);
        // font-feature-settings: "palt";
        letter-spacing: 0.1em;
        // line-height: 1.6;
        &.letter_s{
          letter-spacing: 0.1em;
        }
      }
      .ill1 {

        width: 40%;
        right: 10px;
        bottom: -76px;

      }
    }


    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1 {
    padding: 80px 0;

    .sec1a {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .scroll-hint-icon-wrap{
        left: 55%;
        pointer-events: auto!important;
      }
      img {
        display: block;
        vertical-align: bottom;
        width: 100%;
      }

      .cv {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 4;
      }

      .note {
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 3;
      }
    }
  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
      padding: 6vw 0;


      .sec1a{
        overflow: auto;
        img{
          width: auto!important;
          height: 560px;

        }
      }

    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2 {
    padding: 80px 0;
    .sec2_ttl{
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      .ttl_p{
        font-size: clamp(10px,5vw,24px);
        font-family: "k_goth6", sans-serif;
        text-align: center;
        .ill1{
          width: 12%;
          right: -12%;
          bottom: -22px;
        }
      }
    }
    .sec2a {
      //background-color: #EBF2FF;
      background-color: #E5F3FB;

      padding: 40px;
      margin-bottom: 80px;
      .sec2a1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: stretch;
        flex-wrap: wrap;
        li {
          width: 33.3333333%;
          position: relative;
          padding: 20px 0;

          &.full_w{
            width: 100%;
            display: flex;
            &::after{
              background: none;
            }
            &::before{
              display: none;
            }
            .inner{
              &.no_icon{
                .h4_p{
                  padding-left: 0;
                  &::before{
                    display: none;
                  }
                  &::after{
                    width: 100%;
                  }
                }
                dt{
                  width: 100%;
                }
              }
              &.imgbox{
                margin-top: 30px;
                img{
                  width: 90%;
                  margin: 0 auto;
                  display: block;
                }
              }
            }
          }

          &.for_space{
            &::after{
              display: none;
            }
          }

          &::before {
            width: 100%;
            height: 3px;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background: url(../img/commn_space/img_03.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 100%;
          }

          &::after {
            width: 3px;
            height: 100%;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            background: url(../img/commn_space/img_04.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
          }

          &.nbln {
            &::before {
              display: none;
            }
          }

          &.tln{
            &::before{
              display: block;
              top: 0;
            }
          }

          &:nth-child(3n) {
            &::after {
              display: none;
            }
          }

          .inner {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            &.inl {
              margin-left: 0;
              margin-right: auto;
            }

            &.inc {
              margin-left: auto;
              margin-right: auto;
            }

            &.inr {
              margin-left: auto;
              margin-right: 0;
            }

            > dl {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-wrap: wrap;

              dt {
                width: 61%;
              }

              dd {
                width: 38%;
                .h4_p{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .sec2b{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap:wrap;
      dt{
        width: 48%;
      }
      dd{
        width: 48%;
        .t_p1{
          font-size: clamp(10px, 5vw, 22px);
        }
      }
      .cv{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;
        //opacity: 0.5;
      }
    }
    .sec2c{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap:wrap;
      margin-top: 80px;
      li{
        width: 48%;
        background: url(../img/commn_space/bg_01.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size:  100% 100%;
        position: relative;
        cursor: pointer;
        margin-bottom: 60px;
        .p1{
          width: 90%;
          	margin-left: auto;
          		margin-right: auto;
          position: relative;
          margin-top: 40px;
          margin-bottom: -70px;
          transition: 0.6s cubic-bezier(.52, .08, .18, 1);
        }
        i{
          position: absolute;
          left: 16px;
          top: 0;
          z-index: 3;
          width: 30px;

          img{
            	display: block;
            		vertical-align: bottom;
            		width: 100%;
          }

        }
        .ifig{
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          transform-origin: 50% 100%;
                                    transition: 0.6s cubic-bezier(.52, .08, .18, 1);
        }
        &:hover{
          .ifig{
            	//transform: scale(1.05);
            transform: scale(0.9);
            opacity: 0.7;
z-index: 5;
          }
          .p1{
            //margin-bottom: 10px;
            //z-index: 7;
            transform: scale(0.96);
            	//transform: scale(1.1);

          }
        }
      }
    }
    .i_05{
      .ino{
        position: absolute;
        //background-color: #ff0;
        opacity: 0.5;
        z-index: 5;
        width: 24px;
        height: 24px;
cursor: pointer;
        &.i2{
          left: 43.5%;
          top: 70%;
        }
        &.i3{
          left: 35.5%;
          top: 46.5%;
        }
        &.i1{
          left: 62%;
          top: 70%;
        }
        &.i4{
          left: 70%;
          top: 39.5%;
        }
        &.i6{
          left: 85.5%;
          top: 62%;
        }
        &.i5{
          left: 37.7%;
          top: 32.2%;
        }
        &.i7{
          left: 18.5%;
          top: 6.2%;
        }
        &.i7_2{
          left: 41.2%;
          top: 89%;
        }
        &.i8{
          left: 70%;
          top: 51%;
        }
      }
    }

  }

  #pop_movie {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.9);
    pointer-events: none;

    -moz-transition: 1s cubic-bezier(.52, .08, .18, 1);
    -o-transition: 1s cubic-bezier(.52, .08, .18, 1);
    -webkit-transition: 1s cubic-bezier(.52, .08, .18, 1);
    transition: 1s cubic-bezier(.52, .08, .18, 1);

    opacity: 0;
    //-webkit-transform:translateY(105%);
    //    -moz-transform:  translateY(105%);
    //    -ms-transform:  translateY(105%);
    //    transform:  translateY(105%);

    transform: scale(1.3) rotate(0.01deg);

    .secin {
      z-index: 15;
      width: 100%;
      height: 100%;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-wrap:wrap;

     .popcontent{
       position: relative;
       background: #fff;
       // background-color: #E5F4FB;
       width: 100%;
       height: auto;
       max-width: 800px;
       border: 1px solid #222;
       border-radius:10px;
       opacity: 0;
       li{
         display: none;
         // padding: 40px;
         box-sizing: border-box;
         position: relative;
         h4{
           text-align: center;
           background-color: #E5F4FB;
           border-radius: 10px 10px 0 0;
           padding: 45px 0;
           span{
             font-size: clamp(10px, 3.5vw, 14px);
             font-feature-settings: "palt";
             letter-spacing: 0.1em;
             line-height: 1.6;
             display: block;
             	margin-left: auto;
             		margin-right: auto;
           }

             font-size: clamp(10px, 7vw, 30px);
             font-family: 'k_goth6', sans-serif;
             // margin-bottom: 25px;

         }
         .popcontent1{
           display: flex;
           justify-content: space-between;
           align-items: center;
           flex-wrap:wrap;
           padding: 5px 30px 15px;
           .popcontent1_in{
             display: flex;
             justify-content: space-between;
             align-items: center;
             flex-wrap:wrap;
           }
           dt{
             width: 45%;
             .t_p3{
               line-height: 1.3em;
              //  letter-spacing: 0.15em;
              letter-spacing: 0.09em;
               font-size: clamp(10px,3.8vw,16px);
               &.letter_s{
                 letter-spacing: .05em;
               }
               small{
                 font-size: 10px;
                 margin-top: -5px;
                 display: block;
               }
             }

           }
           dd{
             width: 50%;
             figure{
               position: relative;
               p{
                 position: absolute;
                 right: 0;
                 bottom: 0;
                 font-size: 10px;
                 font-family: serif;
                 padding: 10px;
               }
             }
           }
           b{
             width: 100%;
             margin-top: -25px;
             .use_p{
               position: relative;
               display: inline-block;
               margin-bottom: 10px;
               z-index: 1;
               font-size: clamp(10px,4.2vw,18px);
               padding-left: 5px;
               &::before{
                 content: "";
                 width: 110%;
                 height: 50%;
                 background: #F5F2E9;
                 position: absolute;
                 bottom: 0;
                 left: 0;
                 z-index: -1;
               }
             }
             .use{
               display: flex;
               justify-content: space-between;
               align-items: flex-end;
               flex-wrap:wrap;
               .p_photo{
                 display: flex;
                 justify-content: space-between;
                 align-items: flex-end;
                 flex-wrap:wrap;
                 align-items: center;
                 // overflow: hidden;
                 width: calc(95%/2);
                 position: relative;
                 &::before{
                   content: "";
                   width: 85%;
                   height: 95%;
                   background: #F5F2E9;
                   position: absolute;
                   top: 58%;
                   left: 0;
                   transform: translate(0,-50%);
                 }
                 .photo_p1{
                   font-size: clamp(10px,3.3vw,16px);
                   position: relative;
                   padding-top: 12px;
                   padding-left: 12px;
                   // padding-bottom: 5px;
                   // z-index: 1;
                 }
                 .photo{
                   position: relative;
                   width: 55%;
                   // margin-bottom: 5px;
                   z-index: 1;
                   p{
                     position: absolute;
                     right: 0;
                     bottom: 0;
                     font-size: 10px;
                     font-family: serif;
                     color: #fff;
                     padding: 5px;
                     &.col_black{
                       color: #000;
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
    }

    .close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 20px;
      top: 20px;
      //border: 1px solid #000;
      cursor: pointer;
      z-index: 17;

      transition: 1s;

      img{
width: 40px;
        display: block;
      }
      //
      //&:before {
      //  width: 25px;
      //  height: 1px;
      //  background: #000;
      //  position: absolute;
      //  left: 50%;
      //  top: 50%;
      //  content: "";
      //  transform: translate(-50%, -50%) rotate(45deg);
      //}
      //
      //&:after {
      //  width: 25px;
      //  height: 1px;
      //  background: #000;
      //  position: absolute;
      //  left: 50%;
      //  top: 50%;
      //  content: "";
      //
      //  transform: translate(-50%, -50%) rotate(-45deg);
      //}

      //
      //
      //span {
      //  width: 25px;
      //  height: 1px;
      //  background: #fff;
      //  position: absolute;
      //  left: 50%;
      //  top: 50%;
      //
      //  &:nth-of-type(1) {
      //    transform: translate(-50%, -50%) rotate(45deg);
      //  }
      //
      //  &:nth-of-type(2) {
      //    transform: translate(-50%, -50%) rotate(-45deg);
      //  }
      //}
    }

    &.act {
      pointer-events: auto;
      opacity: 1;

      transform: scale(1) rotate(0.01deg);
      .secin {

      }

      .close {
        //right: 50px;
      }
    }
  }


  &.popupen{
    overflow: hidden;
    #pop_movie{
      pointer-events: auto;
      opacity: 1;

      transform: scale(1) rotate(0.01deg);

      .secin{
        .popcontent{
          opacity: 1;
        }
        .popcontent{
          width: 95%;
        }
      }

      .close {
        //right: 50px;

      }
    }
    &.pp8 #pop_movie .secin .popcontent li.pop8,
    &.pp7 #pop_movie .secin .popcontent li.pop7,
    &.pp6 #pop_movie .secin .popcontent li.pop6,
    &.pp5 #pop_movie .secin .popcontent li.pop5,
    &.pp4 #pop_movie .secin .popcontent li.pop4,
    &.pp3 #pop_movie .secin .popcontent li.pop3,
    &.pp2 #pop_movie .secin .popcontent li.pop2,
    &.pp1 #pop_movie .secin .popcontent li.pop1{
      display: block;
    }


  }


  @keyframes scroll-hint-appear {
    0% {
      transform: translateX(40px);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50%,
    100% {
      transform: translateX(-40px);
      opacity: 0;
    }
  }

  .scroll-hint.is-right-scrollable {
    background: linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-right-scrollable.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0)), linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint-icon {
    position: absolute;
    //top: calc(50% - 25px);
    top: calc(30% - 25px);
    left: calc(50% - 60px);
    box-sizing: border-box;
    width: 120px;
    height: 80px;
    border-radius: 5px;
    transition: opacity .3s;
    opacity: 0;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    padding: 20px 5px 10px 5px;
  }

  .scroll-hint-icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    pointer-events: none;
    z-index: 777;
    display: none;
    @media screen and (max-width: 584px) {
      display: block;
    }
  }

  .scroll-hint-text {
    font-size: 10px;
    color: #FFF;
    line-height: 1em;
    /*margin-top: 5px;*/
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon {
    opacity: .8;
  }

  .scroll-hint-icon:before {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #FFF;
    vertical-align: middle;
    text-align: center;
    content: "";
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDM8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIiBmaWxsPSIjZmZmIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon:after {
    content: "";
    width: 34px;
    height: 14px;
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDE8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIgZmlsbD0iI2ZmZiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiIGZpbGw9IiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==);
    opacity: 0;
    transition-delay: 2.4s;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:after {
    opacity: 1;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:before {
    animation: scroll-hint-appear 1.6s linear;
    animation-iteration-count: infinite;
  }

  .scroll-hint-icon-white {
    background-color: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .4);
  }

  .scroll-hint-icon-white:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDQ8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon-white:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDI8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiLz48L2c+PC9nPjwvc3ZnPg==);
  }

  .scroll-hint-icon-white .scroll-hint-text {
    color: #000;
    line-height: 1em;
  }
  .i_ppi_01,
  .i_ppi_02,
  .i_ppi_04,
  .i_ppi_06{
    position: absolute;
    right: 80px;
    top: 20px;
    width: 12%;
  }
  .i_ppi_02{
    width: 15%;
  }
  .i_ppi_04{
    width: 10%;
  }

  .i_ppi_photo{
    position: absolute;
    left: 65px;
    top: 10px;
    width: 22%;
  }
  @media screen and (max-width: inc_set.$media_sp) {
    .sec2 {
      padding: 6vw 0;
      .sec2_ttl{
        margin-bottom: 30px;
        .ttl_p{
          // font-size: clamp(10px,5vw,24px);
          .ill1{
            width: 30%;
            bottom: -80px;
          }
        }
      }
      .sec2a {
        padding: 3vw;
        width: 100vw;
        margin-left: calc(-1 * (100vw - 100%) / 2);
        margin-bottom: 6vw;
        box-sizing: border-box;
        .sec2a1 {
          li {
            width: 50%;
            padding: 3vw 0;
box-sizing: border-box;

            .inner{
              >.h4_p{
                display: none;
              }
            }
            &:first-child {
              display: flex;
              justify-content: center;
              align-items: center;

              flex-wrap:wrap;
            }

            &:nth-child(3n) {
              &::after {
                display: block;
              }
            }
            &:nth-child(2n) {
              padding-left: 3vw;
              &::after {
                display: none!important;
              }
            }

            &.full_w{
              width: 50%;
              padding-left: 0;
              .inner{
                &.no_icon{
                  padding-right: 0;
                  padding-left: 3vw;
                }
                &.imgbox{
                }
              }
            }

            &.tln{
              &::before{
                display: none;
              }
            }

            &:nth-child(13),
            &:nth-child(11),
            &:nth-child(9),
            &:nth-child(7),
            &:nth-child(5),
            &:nth-child(3),
            &:nth-child(1) {
              padding-right: 3vw;
            }
            &.spnbln{
              &::before{
                display: block!important;
              }
            }
            .inner{
              padding: 0 ;
              >dl{
                dt{
                  width: 100%;
                  order: 2;
                }
                dd{
                  width: 100%;
                  order: 1;
                  figure{
                    width: 80%;
                    	margin-left: auto;
                    		margin-right: auto;
                    margin-bottom: 3vw;
                    margin-top: 8vw;
                  }
                  >.h4_p{
                    display: block;
                  }
                }
              }
            }

            &:nth-child(12){
              .inner{
                >dl{
                  dd{
                    figure{
                      width: 100%;
                      margin-left: auto;
                      margin-right: auto;
                      margin-bottom: 12vw;
                      margin-top: 16vw;
                    }
                    >.h4_p{
                      display: block;
                    }
                  }
                }
              }
            }

          }
        }
      }
      .sec2b{

        dt{
          width: 100%;
        }
        dd{
          width: 100%;
          .t_p1{
            font-size: clamp(10px, 4.7vw, 22px);
          }
        }

      }
      .sec2c{
        margin-top: 8vw;
        li{
          width: 50%;
          margin-bottom: 6vw;
          .p1{
            width: 90%;
            margin-top: 7vw;
            margin-bottom: -30px;
          }
          i{
            left: 4px;
            width: 20px;


          }
          .ifig{
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            transform-origin: 50% 100%;
            transition: 0.6s cubic-bezier(.52, .08, .18, 1);
          }

        }
      }
    }
    #pop_movie {
      height: 100vh;
      height: 100vh;

      .secin {
        .popcontent{
          li{
            h4{
              padding: 20px 0;
            }
            .popcontent1{
              padding: 5px 20px 15px;
              dt{
                width: 100%;
                order: 2;
                .t_p3{
                  &.letter_s{
                    letter-spacing: .09em;
                  }
                }
              }
              dd{
                width: 100%;
                order: 1;
                margin-bottom: 3vw;

                figure{
                  width: 80%;
                  margin: 5px auto;
                }
              }

              b{
                margin-top: 10px;
                .use_p{
                  margin-bottom: 5px;
                }
                .use{
                  align-items: flex-start;
                  .p_photo{
                    width: calc(95%/2);
                    flex-direction: column-reverse;
                    align-items: flex-start;
                    &::before{
                      width: 95%;
                      height: 80%;
                      top: auto;
                      left: auto;
                      bottom: 0;
                      right: 2%;
                      transform: translate(0,0);
                    }
                    .photo_p1{
                      // padding-left: 20px;
                      // padding-bottom: 0;
                      // width: 100%;
                      font-size: clamp(12px,2.5vw,16px);
                      height: clamp(40px,7vw,60px);
                      padding-top: 0;
                      // &::before{
                      //   width: 80%;
                      //   height: 80px;
                      //   left: 10px;
                      // }
                    }
                    .photo{
                      width: 90%;
                      margin-bottom: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .close {
        width: 34px;
        height: 34px;
        right: 12px;
        top: 12px;
        img{
          width: 100%;
        }

      }

    }

    .i_ppi_01,
    .i_ppi_02,
    .i_ppi_04,
    .i_ppi_06{
      left: 10px;
      top: 20px;
      width: 20%;
      z-index: 1;
    }
    .i_ppi_02{
      // width: 22%;
      left: 10px;
      top: 30px;
    }
    .i_ppi_04{
      width: 13%;

    }

    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
