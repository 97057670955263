@use "setting" as inc_set;

footer{
  padding: 0 0 20px;
  .footer_bnr{
    max-width: 530px;
    width: 90%;
    margin: 10px auto 30px;
    &:hover{
      opacity: .7;
    }
  }
  .top_footer{
    background: #426219;
    padding: 15px 0;
    nav{
      width: 100%;
      // max-width: 1700px;
      max-width: 1380px;
      margin: 0 auto;
      padding: 5px 0;
      ul{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        li{
          border-right: 1px solid #fff;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.none{
            pointer-events: none;
            p{
              opacity: .3;
            }
          }
          p{
            color: #fff;
            font-size: clamp(10px,1.8vw,14px);
            text-align: center;
            padding: 5px 7px;
            // font-family: 'apotf',sans-serif;
          }
          &:last-of-type{
            border-right: none;
          }
        }
      }
    }
  }
  .btn_wrap{
    max-width: 650px;
    width: 80%;
    margin: 50px auto 0;
    justify-content: space-between !important;
    .footer_btn{
      width: 46%;
      // max-width: 300px;
      padding: 12px 0;
      color: var(--col-white);
      transition: .5s;
      position: relative;
      p{
        font-size: clamp(10px,4vw,16px);
        text-align: center;
      }
      a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      span{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0,-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 17px solid var(--col-white);
        border-bottom: 10px solid transparent;
      }
      &.req{
        &:hover{
          span{
            border-left: 17px solid var(--col-red);
          }
        }
      }
      &.res{
        &:hover{
          span{
            border-left: 17px solid var(--col-blue);
          }
        }
      }
    }
  }
  .bottom_footer{
    max-width: 1100px;
    width: 90%;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p{
      font-size: 13px;
      .logo{
        img{
          width: auto;
          height: 12px;
          margin: 0 3px;
        }
      }
    }
    .tel_img{
      width: 37%;
      margin: 0 20px;
    }
  }

  .apology_wrap{
    display: none;
  }

  .holiday_wrap{
    width: 85%;
    max-width: 650px;
    margin: 50px auto;
    border: 1px solid #960e16;
    padding: 10px;
    text-align: center;
    p{
      font-size: clamp(10px,3.5vw,15px);
      color: #960e16;
      margin: 10px 0;
      line-height: 1.2em;
      a{
        color: #960e16;
        border-bottom: solid 1px #960e16;
      }
    }
  }

  .comapny_wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // max-width: 1100px;
    max-width: 1150px;
    width: 95%;
    margin: 40px auto 0;
    .com{
      display: flex;
      align-items: center;
      // justify-content: center;
      // width: calc(92%/5);
      p{
        white-space: nowrap;
        font-size: clamp(10px,3.2vw,12px);
      }
      a{
        width: 90%;
        max-width: clamp(100px,12vw,175px);
        &.wide_04{
          max-width: clamp(170px,14.5vw,220px);
        }
      }
      &:last-of-type{
        width: 100%;
        justify-content: center;
      }
    }
  }
  .site_txt{
    font-size: 13px;
    text-align: center;
    margin: 60px 0 40px;
    a{
      position: relative;
      padding-right: 17px;
      &::before{
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border: solid black;
        border-width: 0 1px 1px 0;
        padding: 2px;
        transform: rotate(-45deg) translateY(-50%);
      }
    }
  }
  .copy{
    text-align: center;
    font-size: 12px;
  }
}
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){
  footer{
    padding: 0 0 75px;
    .btn_wrap{
      margin:  20px auto 0;
      .footer_btn{
        width: 100%;
        margin: 5px 0;
      }
    }
    .bottom_footer{
      justify-content: center;
      p{
        text-align: center;
        .logo{
          img{
            height: 3.5vw;
            max-height: 15px;
          }
        }
      }
      .tel_img{
        width: 100%;
        max-width: 450px;
        margin: 10px 0 15px;
      }
    }

    .comapny_wrap{
      margin: 70px auto 0;
      max-width: 400px;
      .com{
        flex-wrap: wrap;
        width: 52%;
        margin: 0 auto 15px;
        justify-content: center;
        a{
          max-width: inherit;
        }
        &:last-of-type{
          width: 52%;
        }
      }
    }
    .site_txt{
      margin: 40px 0;
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
