/*-----------------font---------------------*/
@font-face {
	font-family: '';
	src: url(../fonts/) format('opentype');
}

@font-face {
	font-family: 'arial';
	src: url(../fonts/arial.ttf) format('truetype');
}

@font-face {
	font-family: 'din';
	src: url(../fonts/din.ttf) format('truetype');
}

@font-face {
	font-family: 'k_goth';
	src: url(../fonts/k_goth.otf) format('opentype');
}

@font-face {
	font-family: 'k_goth6';
	src: url(../fonts/k_goth6.otf) format('opentype');
}

@font-face {
	font-family: 'apotf';
	src: url(../fonts/apotf.otf) format('opentype');
}

@font-face {
	font-family: 'tsunagi_g';
	src: url(../fonts/tsunagi_g.ttf) format('truetype');
}


body{
	font-family: 'k_goth',sans-serif;
}
.arial{
	font-family: 'arial',sans-serif;
}
.din{
	font-family: 'din',sans-serif;
}
.k_goth{
	font-family: 'k_goth',sans-serif;
}
.k_goth6{
	font-family: 'k_goth6',sans-serif;
}
.apotf{
	font-family: 'apotf',sans-serif;
}
.tsunagi_g{
	font-family: 'tsunagi_g',sans-serif;
}
