@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#smooth {
  .lp_mv{
    .mv_bg{
        margin-top: 72px;
        position: relative;
        width: 100%;
        height: 100%;
      .mv_tex{
        color: #fff;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .p1{
          font-size: clamp(18px, 2vw, 24px);      
          padding-bottom: 10px;
          border-bottom: solid 1px #fff;
          font-weight: 500;
        }
        .p2{
          font-size: clamp(12px, 1.7vw, 18px);      
          letter-spacing: 1px;
          padding-top: 18px;
          font-weight: 500;
        }
      }
    }
  }

  .lp_cntnt{
    .sec{
      .smooth-e{
        position: relative;
        color: #568a34;
        font-size: clamp(25px, 3vw, 35px);
        letter-spacing: 0;
        font-weight: bolder;
        padding-bottom: 30px;
        &::after{
          content: "スムージー";
          position: absolute;
          top: -33%;
          left: 50%;
          width: 100%;
          letter-spacing: 0;
          transform: translateX(-50%);
          font-size: clamp(15px, 2vw, 20px);
        }
      }
      .heading{
        font-size: clamp(20px, 3vw, 30px);
        font-weight: 500;
        letter-spacing: 0;
      }
    }
    .sec1{
      padding: 50px 0 80px;
      .sec1_smooth-e{
        font-size: clamp(15px, 2.9vw, 24px);    
        padding-bottom: 0;
        &::after{
          top: -70%;
          font-size: clamp(10px, 3vw, 12px);
        }
      }
      .sec1_tex_area1{
        text-align: center;
        .p1{
          font-size: clamp(15px, 3vw, 18px);
          font-weight: 500;
          padding-bottom: 30px;
          letter-spacing: 0;
        }
        .sec1_smooth-e{
          padding: 0;
          margin-bottom: 20px;
        }
        .sec1_heading{
          width: 100%;
          border-top: solid 1px #000;
          font-size: clamp(20px, 3vw, 25px);
          padding: 30px 0 60px;
          font-weight: 500;
        }
      }
      .sec1_img_area{
        width: 95%;
        margin: 0 auto;
        .anime1{
          opacity: 0;
          &.scrollin {
            animation: kani1 1s .3s forwards;
            // &:last-child{
            //   animation-delay: 1s;
            // }
          }
          @keyframes kani1 {
            0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
              -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
              animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            }
            0% {
              opacity: 0;
              -webkit-transform: scale3d(.3, .3, .3);
              transform: scale3d(.3, .3, .3);
            }
            60% {
              opacity: 1;
              -webkit-transform: scale3d(1.1, 1.1, 1.1);
              transform: scale3d(1.1, 1.1, 1.1);
            }
            80% {
              -webkit-transform: scale3d(1);
              transform: scale3d(1);
            }
            to {
              opacity: 1;
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
          }

        }
        .sec1_deco{
          position: relative;
          width: 75%;
          p{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: clamp(13px, 2vw, 19px);
            letter-spacing: 0;       
            text-align: center;
            font-weight: bolder;
            
          }
        }
        .people{
          box-sizing: border-box;
        }
        .sec1_img1{
          justify-content: right;
          align-items: center;
          padding-bottom: 20px;
          .sec1_1{
            padding-left: 20px;
            width: 10%;
          }
        }
        .sec1_img2{
          justify-content: left;
          .sec1_2{
            padding-right: 20px;
            margin-top: -8%;
            width: 15%;
          }
          .sec1_deco2{
            img{
              transform: scaleX(-1);
            }
          }

        }
      }
      .sec1_tex_area2{
        text-align: center;
        padding: 100px 0 0;
        .p1{
          font-size: clamp(13px, 1.9vw, 17px);
          line-height: 1.8rem;
        }
        .sec1_heading2{
          padding-bottom: 40px;
        }
      }
      .sec1_tex_area3{
        padding-bottom: 50px;
        .sec1_texbox{
          text-align: center;
          width: 100%;
          .p{
            color: #568a34;
            background-color: #d7e6d6;
            padding: 14px 0 10px;
            letter-spacing: 0;
            font-size: clamp(17px, 3vw, 19px);
            font-weight: bolder;
          }
          .green_bg{
            background-color: #568a34;
            padding: 25px 0 30px;
            color: #fff;
            .green_in{
              width: 95%;
              margin: 0 auto;
              .green_top, .green_btm{
                width: 95%;
                margin: 0 auto;
              }
              .green_top{
                padding: 0 0 20px;
                text-align: left;
                justify-content: center;
                .p1{
                  font-size: clamp(16px, 3vw, 19px);
                  padding-right: 10px;
                }
                .p2{
                  font-size: clamp(14px, 2.9vw, 15px);
                  width: 60%;
                }
              }
              .green_btm{
                padding: 20px 0 0;
                border-top: solid 1px #fff;
                .p1{
                  padding-bottom: 20px;
                  font-size: clamp(24px, 3vw, 26px);          
                  line-height: normal;
                  letter-spacing: 0;
                  .sec1_smooth-e2{
                    color: #fff;
                  }
                }
                .p2{
                  font-size: clamp(14px, 2.9vw, 15px);
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }

    .sec2{
      .sec2_deco{
        position: relative;
        width: 100%;
        p{
          position: absolute;
          top: 38%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #568a34;
          font-size: clamp(20px, 2vw, 27px);      
          font-weight: 500;
          letter-spacing: -1px;
        }
      }
      .sec2_merit_content{
        width: 90%;
        margin: 0 auto;
        padding: 0 0 50px;
        .sec2_merit_box{
          align-items: center;
          justify-content: space-between;
          .dashed{
            width: 100%;
            background-size: 8px 2px;
            background-image: linear-gradient(to right, #89becb 6px, transparent 6px);
            background-repeat: repeat-x;
            background-position: left bottom;
            padding-bottom: 5px;
            margin: 30px 0;
          }
          .tex_box{
            width: 65%;
            .p1{
              color: #d7e6d6;
              font-size: clamp(80px, 7vw, 90px);
              letter-spacing: -4px;
              font-weight: bold;
              line-height: inherit;
            }
            .p2_wrap{
              height: auto;
              justify-content: left;
              align-items: center;
              .green_border{
                width: 5px;
                height: 90px;
                background-color: #568a34;
                margin-right: 10px;
                border-radius: 30px;
              }
              .p2{
                font-size: clamp(20px, 3vw, 27px);
                letter-spacing: 0;
                font-weight: 400;
              }
            }
            .p3{
              font-size: clamp(11px, 3vw, 14px);
              padding: 10px 0 0;
            }
          }
          .anime2{
            width: 30%;
            opacity: 0;
            &.scrollin {
              animation: kani1 1s .3s forwards;
            }
            @keyframes kani1 {
              0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
                -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                animation-timing-function: cubic-bezier(.215, .61, .355, 1);
              }
              0% {
                opacity: 0;
                -webkit-transform: scale3d(.3, .3, .3);
                transform: scale3d(.3, .3, .3);
              }
              60% {
                opacity: 1;
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
              }
              80% {
                -webkit-transform: scale3d(1);
                transform: scale3d(1);
              }
              to {
                opacity: 1;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }
          }
        }
        .no02{
          .no2_img{
            width: 36%;
          }
          .no2_tex{
            width: 59%;

          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .lp_mv{
      .mv_bg{
        margin-top: 56px;
        .mv_tex{
          min-width: 310px;
          .p1{
            padding-bottom: 5px;
            font-size: clamp(18px, 4vw, 24px);
          }
          .p2{
            letter-spacing: 0;
            padding-top: 10px;
            font-size: clamp(14px, 2.9vw, 15px);
          }
        }
      }
    }
    .lp_cntnt{
      .sec{
        .smooth-e{
          padding-bottom: 20px;
          font-size: clamp(25px, 4vw, 35px);
        }
      }
      .sec1{
        padding: 40px 0 0;
        .sec1_tex_area3{
          .sec1_texbox{
            .green_bg{
              padding: 30px 0 40px;
              .green_in{
                .green_top{
                  padding: 0 0 30px;
                  flex-direction: column;
                  justify-content: center;
                  .p1{
                    padding: 0 0 20px;
                  }
                  .p2{
                    width: 100%;
                  }
                }
              }
            .green_btm{
              padding: 30px 0 0;
              .p1{
                line-height: 2.2em;
                .sec1_smooth-e2{
                  font-size: clamp(25px, 3vw, 35px);
                  font-weight: 400;
                  &::after{
                    font-size: clamp(15px, 2vw, 20px);
                  }
                }
              }
              .p2{
                font-size: clamp(12px, 2.9vw, 15px);
              }

            }
  
            }
          }
        }
        .sec1_smooth-e{
          font-size: clamp(15px, 3.9vw, 24px);
          padding-bottom: 0;
          &::after{
            top: -70%;
          }
        }
        .sec1_tex_area1{
          .sec1_smooth-e{
            padding: 0;
            margin-bottom: 20px;
          }
          .sec1_heading{
            padding: 20px 0 40px;
          }
        }
        .sec1_img_area{
          .sec1_deco{
            p{
              line-height: 1.2em;
              font-size: clamp(13px, 3vw, 19px);
            }
          }
          .sec1_img1{
            .sec1_1{
              padding-left: 6px;
              width: 15%;
            }
          }
          .sec1_img2{
            justify-content: left;
            .sec1_2{
              padding-right: 6px;
              margin-top: 1%;
              width: 22%;
            }
            .sec1_deco2{
              img{
                transform: scaleX(-1);
              }
            }
  
          }
        }
        .sec1_tex_area2{
          padding: 50px 0 70px;
          .p1{
            line-height: normal;
            letter-spacing: 0;
            font-size: clamp(13px, 2.9vw, 17px);
          }
          .sec1_heading2{
            letter-spacing: 0;
            font-size: clamp(20px, 4vw, 30px);
          }
        }
      }
  
      .sec2{
        .sec2_deco{
          p{
            font-size: clamp(17px, 4vw, 27px);
            text-align: center;
            width: 100%;
          }
        }
        .sec2_merit_content{
          .sec2_merit_box{
            flex-direction: column;
            align-items: flex-start;
            .dashed{
              margin: 20px 0;
            }
            .tex_box{
              width: 100%;
              .p1{
                font-size: clamp(90px, 9vw, 110px);
              }
              .p3{
                font-size: clamp(11px, 4vw, 17px);
              }
              .p2_wrap{
                .green_border{
                  height: 70px;
                  .p2{
                    font-size: clamp(23px, 4vw, 27px);
                  }
                }
              }
            }
            .anime2{
              width: 30%;
            }
          }
          .no01{
            .no1_img{
              width: 60%;
              margin-left: auto;

            }
            .no1_tex{
            order: 2;
            margin: -88px 0 0;
              
            }

          }
          .no02{
            .no2_img{
              width: 72%;
              margin-left: auto;
            }
            .no2_tex{
              width: 100%;
              margin: -65px 0 0;
            }
          }
          .no03{
            .no3_img{
              order: 1;
              width: 57%;
              margin-left: auto;
            }
            .no3_tex{
              order: 2;
              margin: -96px 0 0;
            }

            .dashed{
              &:last-child{
                order: 3;
              }
            }
          }
        }
      }
    }
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
}
