@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#modelroom {
  .main_wrap {
    .imgbox {
      background: url("../img/modelroom/main.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }


  .modelroom__wrap {
    padding: 0 0 50px;
    .img_area{
      position: relative;
      p{
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 10px;
        padding: 10px;
        color: #fff;
        text-shadow: 0px 0px 5px rgba(0,0,0,0.8),0px 0px 5px rgba(0,0,0,0.8);
        &.col_black{
          color: #000;
          text-shadow: 0px 0px 5px rgba(255,255,255,0.8);
        }
      }
    }
    .main_text{
      text-align: center;
      padding: 50px 0 100px;
      .p1{
        font-size: clamp(10px,6vw,30px);
        margin-bottom: 30px;
      }
      .p2{
        font-size: clamp(10px,4vw,16px);
      }
    }

    .sec1{
      .moderl_box{
        max-width: 1500px;
        width: 95%;
        align-items: flex-start;
        .img_area{
          width: 55%;
        }
        .text_area{
          width: 40%;
          margin-top: 4vw;
          position: relative;
          p{
            letter-spacing: .1em;
          }
          .m_p1{
            font-size: clamp(10px,3.8vw,40px);
            color: #876239;
            font-family: "apotf", sans-serif;
          }
          .m_p2{
            font-size: clamp(10px,1.9vw,28px);
            font-family: "apotf", sans-serif;
            margin: 2vw 0 1.5vw;
          }
          .m_p3{
            font-size: clamp(10px,1.3vw,16px);
          }
          .ill{
            max-width: 75px;
            width: 18%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(20px,0);
            &.scrollin{
              transform: translate(0,0);
            }
          }
        }
        &.box_r{
          flex-direction: row-reverse;
          margin: 80px 0 80px auto;
        }
      }
    }

    .sec2{
      max-width: 1100px;
      width: 95%;
      margin: 0 auto;
      .s2_p1{
        font-family: "tsunagi_g", sans-serif;
        font-size: clamp(10px,15vw,170px);
        letter-spacing: .01em;
        color: rgba(#856239,.2);
        text-align: center;
      }
      .slide_wrap{
        max-width: 1000px;
        widows: 100%;
        margin: 0 auto;
        p{
          font-family: "apotf", sans-serif;
        }
        .slider{
          margin-bottom: 20px;
          .img_area{
            p{
              font-size: clamp(10px,5vw,30px);
            }
          }
        }

        .thumbnail{
          width: 100%;
          .slick-track{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            transform: translate3d(0,0,0) !important;
            .img_area{
              width: calc(95%/4) !important;
              margin-left: .7%;
              margin-right: calc(5%/10);
              margin-top: 10px;
              cursor: pointer;
              &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: rgba(#000,.6);
                position: absolute;
                top: 0;
                left: 0;
              }
              p{
                font-size: clamp(10px,3.5vw,14px);
                letter-spacing: .1em;
                right: auto;
                bottom: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                text-align: center;
                padding: 0;
              }
              &.slick-current{
                &::before{
                  display: none;
                }
                p{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .modelroom__wrap {
      padding: 0 0 50px;
      .main_text{
        padding: 35px 0;
        .p1{
          margin-bottom: 20px;
        }
      }

      .sec1{
        .moderl_box{
          width: 100%;
          .img_area{
            width: 100%;
          }
          .text_area{
            width: 95%;
            margin: 10px auto 0;
            .m_p1{
              font-size: clamp(10px,8.5vw,40px);
            }
            .m_p2{
              font-size: clamp(10px,5vw,28px);
              margin: 10px 0 10px;
            }
            .m_p3{
              font-size: clamp(10px,3.5vw,16px);
            }
            // p{
            //   letter-spacing: .1em;
            // }
          }
          &.box_r{
            margin: 40px 0 40px auto;
          }
        }
      }

      .sec2{
        .slide_wrap{
          .slider{
            margin-bottom: 10px;
          }

          .thumbnail{
            .slick-track{
              .img_area{
                width: calc(95%/2) !important;
                margin-left: .2%;
                margin-right: 2.2%;
              }
            }
          }
        }
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .company__wrap {
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1 {
  }
  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
