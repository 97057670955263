@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#plan.type{
  .sec{
    padding: 80px 0;
    .max1000{
      max-width: 1100px;
    }
    .type_wrap{
      max-width: 850px;
      margin: 0 auto;
      p{
        font-family: 'apotf',sans-serif;
      }
      .type_p1{
        text-align: center;
        font-size: clamp(10px,15vw,75px);
        border-bottom: solid 1px #000;
        line-height: 1em;
        // width: 80%;
        width: 100%;
        margin: 0 auto 30px;
        span{
          font-size: clamp(10px,6vw,20px);
        }
      }
      .type_p2{
        font-size: clamp(10px,10vw,45px);
        font-feature-settings : "palt";
        letter-spacing: 0;
        line-height: 1.2em;
        span{
          font-size: clamp(10px,7vw,30px);
          img{
            display: inline-block;
            width: auto;
            height: 30px;
          }
        }
      }
      .floor{
        font-size: clamp(10px,3.5vw,15px);
        margin-top: -10px;
      }
      .type_p3{
        font-size: clamp(10px,3.2vw,13px);
      }
      .type_p4{
        font-size: clamp(10px,11vw,45px);
        font-feature-settings : "palt";
        letter-spacing: 0;
        span{
          font-size: clamp(10px,6vw,20px);
        }
      }
      .type_p5{
        font-size: clamp(10px,3.2vw,13px);
      }
    }

    .tab_area{
      max-width: 800px;
      width: 90%;
      margin: 30px auto 0;
      .tab{
        width: 48%;
        text-align: center;
        color: var(--col-brown);
        border-radius: 15px;
        border: solid 1px var(--col-brown);
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .soldout{
          background: #BA272D;
          color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          padding: 0 8px;
          border-radius: 15px 0 5px 0;
        }
        p{
          font-size: clamp(10px,4vw,18px);
          letter-spacing: .05em;
          font-family: "apotf", sans-serif;
        }
        span{
          font-size: clamp(10px,3.5vw,14px);
          letter-spacing: .01em;
        }
        &.active{
          background: var(--col-brown);
          color: #fff;
        }
        .premiere{
          background: linear-gradient(90deg,#42210B,#FFD800,#42210B);
          width: 100%;
          p{
            color: #fff;
            font-size: clamp(10px,3.5vw,15px);
          }
        }
      }
    }

    .type_st_wrap{
      background: #FFFFE5;
      max-width: 950px;
      width: 95%;
      margin: 40px auto 0;
      padding: 40px 0;
      &.type_st_wrap_tab{
        display: none;
      }
      &.active{
        display: block;
      }
      .in{
        width: 92%;
        margin: 0 auto;
        .type_st{
          // letter-spacing: 0em;
          letter-spacing: -0.1em;
          font-size: clamp(10px,1.8vw,16px);
          width: 60%;
        }
        div{
          width: 38%;
          position: relative;
          p{
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 5px;
            font-size: 10px;
            color: #fff;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8),0px 0px 5px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .wappen_wrap{
      max-width: 900px;
      margin: 50px auto -100px;
      position: relative;
      z-index: 1;
      .wappen{
        width: 28%;
        max-width: 135px;
      }
    }

    .type_img_p{
      background: #568A34;
      text-align: center;
      color: #fff;
      max-width: 400px;
      width: 80%;
      margin: 20px auto 0;
      border-radius: 5px;
      padding: 12px 0;
      font-size: clamp(10px,3.8vw,20px);
      letter-spacing: .1em;
      &.dummy_p{
        background: transparent;
        color: transparent;
      }
      &.type_img_p_tab{
        display: none;
        &.active{
          display: block;
        }
      }
    }
    .type_img{
      // max-width: 750px;
      margin: 50px auto 0;
      padding-top: 30px;
      position: relative;
      &.type_img_tab{
        display: none;
      }
      &.active{
        display: block;
      }
      // .wappen{
      //   position: absolute;
      //   top: 0;
      //   left: 10%;
      //   width: 28%;
      //   max-width: 135px;
      // }
    }
    .type_note{
      // width: 95%;
      max-width: 550px;
      margin: 5px auto 50px;
      text-align: center;
      p{
        margin-top: 10px;
      }
      img{
        max-width: 220px;
      }
    }
    .note_h{
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
      justify-content: start;
      .note_h_p1, .note_h_p2{
        font-size: clamp(10px,3.8vw,16px);
        margin-right: 20px;
      }
      .note_h_p1{
        background: #568a34;
        padding: 9px 15px 10px 15px;
        color: #fff;
        letter-spacing: 0;
      }
      .note_h_p2{
        letter-spacing: normal;
        .h_p2_p1{
          font-size: clamp(20px, 4.8vw, 40px);
          color: #568a34;
        }
        .h_p2_p2{
          font-size: clamp(16px, 3.8vw, 23px);
        }
      }
      .note_h_p4{
        width: 100%;
        border-top: solid 2px #568a34;
        padding: 15px 0 0 0;
        margin: 10px 0 0 0;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec{
      padding: 40px 0;
      .type_wrap{
        .type_p1{
          width: 90%;
          margin-bottom: 15px;
        }
        .type_in{
          text-align: center;
          div{
            width: 100%;
            justify-content: center;
          }
        }
      }

      .tab_area{
        // max-width: 800px;
        // margin: 30px auto 0;
        .tab{
          width: 100%;
          margin: 5px auto;
          // p{
          //   font-size: clamp(10px,4vw,18px);
          //   letter-spacing: .05em;
          //   font-family: "apotf", sans-serif;
          // }
          // span{
          //   font-size: clamp(10px,3.5vw,14px);
          //   letter-spacing: .01em;
          // }
          // .premiere{
          //   background: linear-gradient(90deg,#42210B,#FFD800,#42210B);
          //   width: 100%;
          //   p{
          //     color: #fff;
          //     font-size: clamp(10px,3.5vw,15px);
          //   }
          // }
        }
      }

      .type_st_wrap{
        margin: 40px auto 0;
        padding: 30px 0;
        .in{
          width: 95%;
          flex-direction: column-reverse;
          .type_st{
            width: 100%;
            text-align: center;
            line-height: 1.8em;
            margin-top: 15px;
            font-size: clamp(10px, 3.7vw, 16px);
          }
          div{
            width: 95%;
          }
        }
      }

      .wappen_wrap{
        // max-width: 900px;
        margin: 20px auto -10px;
        // position: relative;
        // z-index: 1;
        .wappen{
          margin: 0 auto;
          width: 30%;
        }
      }

      .scroll_p{
        text-align: center;
        font-size: clamp(10px,3.2vw,14px);
        margin: 50px 0 0;
        font-family: "apotf", sans-serif;
      }
      .swipe{
        width: 20%;
        max-width: 120px;
        margin: 5px auto 0;
      }

      .type_img{
        overflow: auto;
        margin: 5px auto 25px;
        position: relative;
        img{
          width: auto!important;
          height: 560px;
          display: block;
          vertical-align: bottom;
        }
        .wappen{
          img{
            width: 100%!important;
            height: auto;
          }
        }
      }

      // .type_img::-webkit-scrollbar{
      //   position: absolute;
      //   top: 0;
      // }
      // .type_note{
      //   width: 95%;
      //   max-width: 250px;
      //   margin: 5px auto 50px;
      // }
      .note_h{
        width: 95%;
        .note_h_p1, .note_h_p2{
          margin-right: 0;
        }
        .note_h_p1{
          font-size: clamp(12px, 4.8vw, 16px);
          margin: 0 auto 20px auto;
          width: 64%;
        }
        .note_h_p2{
          width: 100%;
          margin-bottom: 15px;
          font-size: clamp(10px, 4vw, 16px);
          .h_p2_p1{
            font-size: clamp(20px, 12vw, 40px);
          }
          .h_p2_p2{
            font-size: clamp(16px, 7vw, 23px);
          }
        }
        .note_h_p3{
          width: 100%;
          text-align: center;
        }
        .note_h_p4{
          padding: 20px 0 0 0;
          margin: 20px 0 0 0;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

}
