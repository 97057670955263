@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#access{
  .main_wrap{
    .imgbox{
      background: url('../img/access/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .access_wrap{
    .sec{
      .max1000{
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
      }
      .flex_area{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
      .img{
        position: relative;
        p{
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 10px;
          color: #fff;
          padding: 10px;
          text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
          &.col_block{
            color: #000;
            text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
          }
        }
      }
      .s1_txt{
        text-align: center;
        .s1_p1{
          font-size: clamp(10px,4.2vw,20px);
          letter-spacing: .01em;
          font-family: 'k_goth6';
          margin-bottom: 30px;
        }
        .s1_p2{
          font-size: clamp(10px,3.8vw,16px);
        }
      }
      .small{
        font-size: 10px;
        color: #fff;
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .access_wrap{
      .sec{
        .s1_txt{
          .s1_p1{
            margin-bottom: 30px;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec1{
    padding: 80px 0;
    .sec_in{
      .s1_map{
        max-width: 770px;
        width: 95%;
        margin: 60px auto;
        p{
          padding: 0;
        }
      }
      .icon{
        justify-content: flex-start;
        img{
          height: 30px;
          width: auto;
          margin-right: 10px;
        }
        p{
          font-size: clmap(10px,4.5vw,18px);
          color: #568534;
          font-family: 'k_goth6';
        }
      }

      .s1_box{
        .box{
          width: calc(97%/3);
          margin: 10px 0;
          .box_img{
            img{
              vertical-align: bottom;
            }
          }
          .box_text{
            background: #F2EEE7;
            text-align: center;
            padding: 20px 0 10px;
            border-bottom: solid 5px #568534;
            p{
              font-family: 'k_goth6';
            }
            .s1_p3{
              font-size: clamp(10px,3.7vw,20px);
              padding-bottom: 10px;
              margin-bottom: 10px;
              letter-spacing: .01em;
              position: relative;
              &::before{
                content: "";
                width: 90%;
                height: 1px;
                background: #000;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%,0);
              }
              span{
                font-size: clamp(10px,8vw,55px);
                color: #568534;
                letter-spacing: -0.02em;
                padding: 0 5px;
              }
            }
            .s1_p4{
              font-size: 12px;
              letter-spacing: .01em;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      padding: 50px 0;
      .sec_in{
        .s1_map{
          margin: 30px auto;
        }

        .s1_box{
          .box{
            width: calc(97%/2);
            margin: 10px 0;
            .box_text{
              padding: 15px 0 5px;
              height: clamp(85px,23vw,90px);
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________

  .sec2{
    background: #568534;
    padding: 40px 0 40px;
    .s2_p1{
      color: #F7E921;
      text-align: center;
      font-size: clamp(10px,4.5vw,22px);
      letter-spacing: .01em;
      font-family: 'k_goth6';
      border-bottom: solid 3px #F7E921;
      padding-bottom: 20px;
      margin-bottom: 40px;
    }
    .sec_in{
      p{
        color: #fff;
        font-family: 'k_goth6';
      }
      .s2_p2{
        font-size: clamp(10px,4vw,16px);
        text-align: center;
        font-family: "k_goth",sans-serif;
      }
      .s2_box{
        margin: 30px 0 10px;
        position: relative;
        .box{
          width: 30%;
          position: relative;
          p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: clamp(10px,3vw,35px);
          }
          .img_p{
            font-size: 10px;
            top: auto;
            bottom: 7%;
          }
        }
        .arrow{
          width: 3%;
          position: relative;
          &.a_right{
            transform: rotate(180deg);
          }
        }
        .s2_p3{
          font-size: clamp(10px,6vw,16px);
          span{
            color: #F7E921;
            font-size: clamp(10px,18vw,50px);
          }
        }
        .s2_p4{
          font-size: clamp(10px,1.5vw,16px);
          text-align: center;
        }
        .ab{
          position: absolute;
        }
        .s2_time{
          top: 25%;
          left: 29%;
        }
        .s2_place{
          bottom: 20%;
          left: 26.5%;
        }
        .s2_time2{
          top: 25%;
          right: 29%;
        }
        .s2_place2{
          bottom: 20%;
          // right: 26.5%;
          right: 25%;
        }
      }
    }
  }

  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      padding: 40px 0 40px;
      .sec_in{
        .s2_box{
          max-width: 500px;
          margin: 30px auto 10px;
          flex-direction: column;
          .box{
            width: 85%;
            p{
              font-size: clamp(10px,8vw,35px);
            }
          }
          .arrow{
            width: 10%;
            transform: rotate(90deg);
            margin: 15px 0;
            &.a_right{
              transform: rotate(270deg);
            }
          }
          .s2_p3{
            span{
              font-size: clamp(10px,18vw,65px);
            }
          }
          .s2_p4{
            // font-size: clamp(10px,4vw,16px);
            font-size: clamp(10px,3.5vw,16px);
          }
          .s2_time{
            top: 31%;
            left: 15%;
          }
          .s2_place{
            bottom: auto;
            left: auto;
            top: 30%;
            right: 3%;
          }
          .s2_time2{
            top: 66.5%;
            right: auto;
            left: 15%;
          }
          .s2_place2{
            bottom: auto;
            top: 65.5%;
            right: 2%;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec3{
    padding: 80px 0;
    .sec_in{
      .s3_map{
        width: 85%;
        margin: 30px auto 70px;
        .s3m_l{
          width: 70%;
        }
        .s3m_r{
          width: 30%;
          .r_t{
            background: #568534;
            border-radius: 50%;
            width: clamp(170px,45vw,220px);
            height: clamp(170px,45vw,220px);
            margin: 0 auto;
            .r_t_text{
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              p{
                text-align: center;
                color: #fff;
                font-family: "k_goth6";
                letter-spacing: .01em;
              }
              .r_t_p1{
                font-size: clamp(10px,3.5vw,18px);
              }
              .r_t_p2{
                font-size: clamp(10px,5.5vw,28px);
                border-bottom: solid 1px #fff;
                margin: 0 0 18px;
              }
              .r_t_p3{
                font-size: clamp(10px,3vw,15px);
                span{
                  font-size: clamp(10px,14vw,55px);
                  color: #F7E921;
                  padding: 0 3px;
                }
              }
            }
          }
          .r_b{
            width: 66%;
            margin: 10px auto 0;
          }
        }
      }

      .s3_box{
        max-width: 650px;
        width: 100%;
        margin: 0 auto;
        .box{
          width: calc(96%/3);
          .box_text{
            p{
              font-family: 'k_goth6';
              text-align: center;
              letter-spacing: .01em;
            }
            .s3_p3{
              color: #568534;
              font-size: clamp(10px,4vw,18px);
              margin: 5px 0 15px;
            }
            .s3_p4{
              font-size: clamp(10px,4.5vw,22px);
              span{
                color: #568534;
                font-size: clamp(10px,12vw,55px);
              }
            }
            .s3_p5{
              font-size: clamp(10px,3.2vw,14px);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
      padding: 50px 0;
      .sec_in{
        .s3_map{
          width: 90%;
          // margin: 30px auto 70px;
          .s3m_l{
            width: 100%;
          }
          .s3m_r{
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 15px 0 0;
            .r_b{
              width: 42%;
              max-width: 200px;
              // margin: 0;
            }
          }
        }

        .s3_box{
          .box{
            width: calc(96%/2);
            margin: 5px 0;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________

}
