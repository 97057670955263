@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#quality{

  .main_wrap{
    .imgbox{
      background: url('../img/quality/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .tab_wrap{
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    padding: 120px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .tabbtn{
      width: 24%;
      margin: 0 30px 40px 0;
      padding: 15px 0;
      background-color: #568A34;
      border-radius: 40px 40px 0 40px;
      text-align: center;
      opacity: 0.5;
      cursor: pointer;
      &.active{
        opacity: 1;
      }
      p{
        color: #fff;
        font-size: clamp(10px, 2.2vw, 20px);
      }
    }
    .tabbtn_sp{
      margin-right: 0;
    }
    .tabbtn_ee{
      margin-bottom: 0;
    }
    .tabbtn_us{
      margin: 0;
    }
  }

  // ______________________________________
  @media screen and (max-width: 1100px){
  .tab_wrap{
    padding: 15vw 0;
    flex-direction: column;
    align-items: center;
    .tabbtn{
      width: 100%;
      margin: 0 0 5vw 0;
      p{
        font-size: clamp(10px, 5vw, 28px);
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  // .tab_wrap{
  //   padding: 15vw 0;
  //   flex-direction: column;
  //   align-items: center;
  //   .tabbtn{
  //     width: 100%;
  //     margin-bottom: 5vw;
  //     p{
  //       font-size: clamp(10px, 5vw, 28px);
  //     }
  //   }
  // }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec{
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    padding-bottom: 30px;
    display: none;
    &.active{
      display: block;
    }
    .flex{
      display: flex;
      justify-content: space-between;
    }
    .ul_sprit{
      width: 49%;
    }
    li{
      margin: 30px 0 70px;
    }
    h2{
      color: #009D73;
      font-size: clamp(10px, 4vw, 35px);
    }
    h3{
      margin-bottom: 15px;
      padding: 0.5em;
      background-color: #000;
      color: #fff;
      font-size: clamp(10px, 2vw, 18px);
      line-height: 1.2em;
    }
    h4{
      margin-bottom: 0.5em;
      color: #867060;
      font-size: clamp(10px, 2vw, 16px);
      font-family: sans-serif;
      font-weight: bold;
      line-height: 1.2em;
    }
    h5{
      margin-bottom: 0.5em;
      font-size: clamp(10px, 5vw, 16px);
    }
    p{
      font-size: clamp(10px, 1.5vw, 14px);
      line-height: 1.2em;
    }
    .annotxt{
      color: #000;
      font-size: 10px;
      line-height: 1em;
      letter-spacing: 0.01em;
    }
    .anno_mt{
      margin-top: 80px;
    }
    .flex_t{
      margin-bottom: 15px;
    }
    .square{
      width: 0.8em;
      display: inline-block;
    }
    .ind{
      margin-left: 0.8em;
      display: inline-block;
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .sec{
    padding-bottom: 5vw;
    .flex{
      flex-direction: column;
    }
    .ul_sprit{
      width: 100%;
    }
    li{
      margin: 0 0 18vw;
    }
    h2{
      width: fit-content;
      margin: 0 auto 15vw;
      font-size: clamp(10px, 10vw, 50px);
    }
    h3{
      margin-bottom: 5vw;
      font-size: clamp(10px, 5vw, 35px);
    }
    h4{
      font-size: clamp(10px, 4vw, 30px);
    }
    h5{
      margin: 5vw 0;
      font-size: clamp(10px, 5vw, 28px);
    }
    p{
      font-size: clamp(10px, 3.5vw, 20px);
    }
    .anno_mt{
      margin-top: 10vw;
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec_st{
    .dir{
      flex-direction: column;
    }
    .li01{
      .flex_t{
        .txtbox{
          width: 70%;
        }
        .imgbox{
          margin-left: 5px;
        }
      }
      .flex_b{
        .imgbox{
          width: 49%;
        }
      }
    }
    .li02{
      p{
        margin-bottom: 15px;
      }
      .imgbox{
        width: 70%;
        margin: 0 auto;
      }
    }
    .li03{
      .item01{
        width: 45%;
        display: flex;
        .txtbox{
          width: 60%;
          margin-right: 5px;
        }
      }
      .item02{
        width: 54%;
        display: flex;
        .txtbox{
          width: 40%;
          margin-right: 5px;
        }
      }
    }
    .li04{
      width: 49%;
      .txtbox{
        width: 49%;
        .annotxt{
          margin-top: 30px;
        }
      }
      .imgbox{
        width: 49%;
        padding-left: 35px;
      }
    }
    .li05{
      width: 49%;
      .item{
        margin-bottom: 30px;
      }
      .txtbox{
        width: 49%;
      }
      .imgbox{
        width: 49%;
        padding-left: 35px;
      }
    }
    ul{
      &.flex{
        li{
          width: 48%;
        }
        .li01{
          width: 49%;
        }
        .li02{
          width: 49%;
        }
        .li03{
          width: 100%;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .sec_st{
    .dir{
      flex-direction: row;
    }
    .annotxt{
      display: inline-block;
      line-height: 1.2em;
    }
    li{
      margin-bottom: 8vw;
    }
    .li01{
      .flex_t{
        width: 48%;
        .txtbox{
          width: 100%;
          margin-bottom: 5vw;
        }
      }
      .flex_b{
        width: 48%;
        justify-content: start;
        .imgbox{
          width: 100%;
          margin-bottom: 5vw;
        }
      }
    }
    .li02{
      p{
        margin-bottom: 5vw;
      }
      .imgbox{
        width: 95%;
      }
    }
    .li03{
      margin-bottom: 15vw;
      .item01{
        width: 100%;
        margin-bottom: 5vw;
        flex-direction: column;
        .txtbox{
          width: 100%;
          margin-right: 0;
          margin-bottom: 8vw;
        }
        .imgbox{
          max-width: 550px;
          width: 100%;
          margin: 0 auto;
        }
      }
      .item02{
        width: 100%;
        flex-direction: column;
        .txtbox{
          width: 100%;
          margin-right: 0;
          margin-bottom: 3vw;
        }
        .imgbox{
          max-width: 500px;
          width: 85%;
          margin: 0 auto;
        }
      }
    }
    .li04{
      h3{
        padding: 1.1em 0.5em;
      }
      .txtbox{
        width: 100%;
        .annotxt{
          margin-top: 5vw;
        }
      }
      .imgbox{
        max-width: 500px;
        width: 100%;
        margin: 8vw auto 0;
        padding-left: 0;
      }
    }
    .li05{
      width: 100%;
      margin-bottom: 15vw;
      .item{
        margin-bottom: 8vw;
      }
      .txtbox{
        width: 100%;
        .annotxt{
          margin-top: 5vw;
        }
      }
      .imgbox{
        max-width: 500px;
        width: 80%;
        margin: 5vw auto 0;
        padding-left: 0;
      }
      .mr{
        margin-right: 2vw;
      }
    }
    ul{
      &.flex{
        li{
          width: 100%;
        }
        .li01{
          width: 100%;
        }
        .li02{
          width: 48%;
        }
        .li03{
          width: 48%;
        }
        .li04{
          width: 48%;
        }
        .li05{
          width: 48%;
        }
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec_fs{
    .flex{
      flex-wrap: wrap;
    }
    li{
      width: 31%;
      margin-bottom: 40px;
      .txtbox{
        width: 100%;
      }
      .imgbox{
        width: 55%;
        margin: 0 auto 20px;
      }
    }
    .li01{
      width: 66%;
      .txtbox{
        width: 49%;
        .imgbox{
          width: 80%;
          margin: 15px auto 0;
        }
      }
      .imgbox{
        width: 49%;
      }
    }
    .li02{
      .imgbox{
        width: 45%;
      }
    }
    .li03{
      .imgbox{
        width: 50%;
        margin: 30px auto 53px;
      }
    }
    .li04{
      width: 32%;
      .imgbox{
        width: 80%;
        margin: 50px auto;
      }
    }
    .li10{
      width: 66%;
      padding: 2vw;
      box-sizing: border-box;
      background-color: #FBF9F8;
      border: 1px solid #867060;
      .txtbox{
        width: 40%;
      }
      .imgbox{
        width: 45%;
        margin-right: 3vw;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .sec_fs{
    .flex{
      flex-direction: row;
      flex-wrap: wrap;
    }
    h3{
      font-size: clamp(10px, 4vw, 35px);
    }
    p{
      font-size: clamp(10px, 3vw, 20px);
    }
    li{
      width: 49%;
      margin-bottom: 12vw;
      .txtbox{
        width: 100%;
      }
      .imgbox{
        max-width: 400px;
        width: 85%;
        margin: 5vw auto;
      }
    }
    .li01{
      width: 100%;
      .txtbox{
        width: 100%;
        .imgbox{
          width: 90%;
          margin: 8vw auto 0;
        }
      }
      .imgbox{
        max-width: 100%;
        width: 90%;
        margin: 3vw auto 0;
      }
    }
    .li02{
      .imgbox{
        width: 70%;
      }
    }
    .li03{
      .imgbox{
        width: 80%;
        margin: 6.6vw auto;
      }
    }
    .li04{
      width: 49%;
      .txtbox{
        width: 100%;
      }
      .imgbox{
        max-width: 100%;
        width: 90%;
        margin: 9.5vw auto;
      }
    }
    .li06{
      h3{
        padding: 1.1em 0.5em;
      }
    }
    .li10{
      width: 100%;
      padding: 10vw 8vw;
      .flex{
        flex-direction: column-reverse;
      }
      .txtbox{
        width: 100%;
      }
      .imgbox{
        width: 85%;
        margin: 0 auto 5vw;
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .tps_t{
    // margin-top: 150px;
    padding: 15px 0 20px;
    background-color: #FFF6E9;
    &.flex{
      justify-content: center;
      align-items: center;
    }
    .imgbox{
      max-width: 190px;
      width: 20%;
    }
    .txtbox{
      width: 31%;
      margin-left: 50px;
      text-align: center;
      .imgbox01{
        width: 60%;
        margin: 0 auto;
      }
      .imgbox02{
        width: 100%;
        margin: 25px auto;
      }
      p{
        font-family: sans-serif;
        font-weight: bold;
        line-height: 1.5em;
      }
    }
  }
  .tps_b{
    li{
      width: calc(100% / 3);
      // margin-bottom: 5vw;
      box-sizing: border-box;
      border-right: 1px solid #231815;
      .li_t{
        margin: 2vw 0 1vw;
        &.flex{
          align-items: center;
        }
        .imgbox{
          width: 20%;
        }
        .txtbox{
          width: 70%;
        }
        .p01{
          margin-bottom: 0.5em;
          font-size: clamp(10px, 2vw, 22px);
        }
      }
      .imgbox{
        width: 85%;
        margin: 0 auto 1.5vw;
      }
    }
    .tps01{
      padding-right: 15px;
      // .li_t{
      //   margin-bottom: 2vw;
      // }
    }
    .tps02{
      padding: 0 15px;
      // .li_t{
      //   margin-bottom: 3vw;
      // }
      h3{
        margin-top: 30px;
      }
    }
    .tps03{
      padding-left: 15px;
      border-right: 0;
      // .li_t{
      //   margin-bottom: 4vw;
      // }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .tps_t{
    // margin-top: 15vw;
    padding: 4vw 0 6vw;
    .imgbox{
      max-width: 100%;
      width: 30%;
      margin-bottom: 3vw;
    }
    .txtbox{
      width: 100%;
      margin-left: 0;
      .imgbox01{
        max-width: 290px;
        width: 60%;
      }
      .imgbox02{
        max-width: 450px;
        width: 80%;
      }
      p{
        font-size: clamp(10px, 3.5vw, 20px);
      }
    }
  }
  .tps_b{
    li{
      width: 100%;
      margin-bottom: 5vw;
      padding-bottom: 10vw;
      border-right: 0;
      border-bottom: 1px solid #231815;
      .li_t{
        flex-direction: row;
        .imgbox{
          width: 25%;
          margin: 2vw auto 0;
        }
        .txtbox{
          width: 70%;
        }
        .p01{
          margin-bottom: 0.5em;
          font-size: clamp(10px, 5vw, 35px);
        }
        .p02{
          font-size: clamp(10px, 4vw, 20px);
        }
      }
      .imgbox{
        max-width: 100%;
      }
    }
    .tps01{
      margin-top: 10vw;
      padding-right: 0;
      .li_t{
        margin-bottom: 8vw;
      }
    }
    .tps02{
      padding: 0;
      padding-bottom: 10vw;
      .li_t{
        margin-bottom: 8vw;
      }
      h3{
        margin-top: 5vw;
      }
    }
    .tps03{
      padding-left: 0;
      border-bottom: 0;
      .li_t{
        margin-bottom: 8vw;
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .pas_t{
    // margin-top: 150px;
    margin: 20px auto 50px;
    &.flex{
      justify-content: left;
    }
    .flex{
      flex-direction: column;
      justify-content: center;
    }
    li{
      margin-bottom: 50px;
    }
    .imgbox{
      max-width: 150px;
      width: 18%;
      margin: 50px auto;
    }
    .txtbox{
      margin: 0 auto;
      // padding-left: 3vw;
      box-sizing: border-box;
      text-align: center;
      .p01{
        // margin-bottom: 15px;
        display: inline-block;
        font-size: clamp(10px, 4vw, 40px);
        letter-spacing: 0.01em;
      }
      .p02{
        margin-bottom: 30px;
        font-size: clamp(10px, 3.2vw, 28px);
        font-family: sans-serif;
        font-weight: bold;
        line-height: 1.5em;
        letter-spacing: 0.01em;
        img{
          width: 4em;
          margin-bottom: -10px;
        }
        ._pc{
          display: inline-block;
        }
      }
      .p03{
        letter-spacing: 0.01em;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .pas_t{
    margin: 5vw 0 12vw;
    &.flex{
      justify-content: center;
    }
    .flex{
      flex-direction: column-reverse;
    }
    .imgbox{
      max-width: 100%;
      width: 30%;
      margin: 0 auto 8vw;
    }
    .txtbox{
      width: 100%;
      padding-left: 0;
      text-align: center;
      .p01{
        margin-bottom: 8vw;
        font-size: clamp(10px, 4.8vw, 35px);
        letter-spacing: 0.2em;
      }
      .p02{
        margin: 5vw auto;
        font-size: clamp(10px, 4.5vw, 28px);
        line-height: 1.8em;
        letter-spacing: 0.2em;
        img{
          width: 7em;
        }
        ._sp{
          margin-bottom: -2vw;
          display: inline-block;
        }
        ._pc{
          display: none;
        }
      }
      .p03{
        font-size: clamp(10px, 3.8vw, 20px);
      }
      .p03box{
        text-align: left;
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .pas_b{
    &.flex{
      display: flex;
      flex-wrap: wrap;
    }
    li{
      width: 48%;
      // margin-top: 5vw;
      margin-bottom: 50px;
      .pas_ttl{
        margin-bottom: 25px;
        padding-left: 10px;
        border-left: 5px solid #933A47;
      }
      .p01{
        margin-bottom: 5px;
        color: #933A47;
        font-size: clamp(10px, 2.5vw, 25px);
        font-family: sans-serif;
        font-weight: bold;
      }
      .p02{
        color: #595757;
        font-size: clamp(10px, 1.5vw, 14px);
      }
      .p03{
        // margin-bottom: 40px;
      }
      .imgbox{
        width: 100%;
      }
    }
    .pas01{
      position: relative;
      .p03{
        // margin-bottom: 20px;
      }
      .patch{
        width: 10%;
        position: absolute;
        right: 0;
        top: 70px;
      }
      .imgbox{
        width: 95%;
      }
    }
    .pas02{
      .item01{
        width: 51%;
      }
      .item02{
        width: 45%;
      }
    }
    .pas03{
      margin-top: 0;
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .pas_b{
    li{
      width: 100%;
      // margin-top: 15vw;
      margin-bottom: 10vw;
      .pas_ttl{
        margin-bottom: 5vw;
      }
      .p01{
        font-size: clamp(10px, 5vw, 30px);
      }
      .p02{
        color: #595757;
        font-size: clamp(10px, 1.5vw, 14px);
      }
      .p02{
        font-size: clamp(10px, 3.5vw, 20px);
      }
      .p03{
        font-size: clamp(10px, 3.8vw, 20px);
      }
      h3{
        margin-bottom: 0;
        font-size: clamp(10px, 4vw, 35px);
      }
      .imgbox{
        max-width: 100%;
        width: 100%;
      }
    }
    .pas01{
      .patch{
        width: 35%;
        margin-right: 1em;
        position: static;
        clear: both;
        float: right;
      }
      .float{
        position: absolute;
        .space{
          float: right;
          height: 2.5em;
        }
      }
      .imgbox{
        max-width: 100%;
        margin-top: 10em;
        position: relative;
        z-index: -1;
      }
    }
    .pas02{
      .item01{
        width: 100%;
      }
      .item02{
        width: 100%;
      }
      .imgbox{
        margin: 3vw auto 8vw;
      }
    }
    .pas03{
      // margin-top: 8vw;
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .hsk{
    margin-top: 150px;
    .imgbox{
      width: 100%;
      margin-top: 80px;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .hsk{
    margin-top: 25vw;
    .imgbox{
      width: 100%;
      margin-top: 18vw;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .annobox{
    margin-top: 80px;
    p{
      font-size: 10px;
      letter-spacing: 0.01em;
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .annobox{
    margin-top: 15vw;
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec_ee{
    .beige{
      color: #867060;
    }
    .ind{
      margin-left: 1em;
    }
    .flex{
      flex-wrap: wrap;
    }
    li{
      width: 48%;
      margin-bottom: 50px;
    }
    .sec_header{
      max-width: 1000px;
      margin: 40px 0 20px;
      &.flex{
        flex-wrap: nowrap;
      }
      .imgbox{
        width: 20%;
        max-width: 150px;
      }
      .txtbox{
        width: 90%;
        padding-left: 10px;
        .beige{
          margin-bottom: 0.8em;
          font-size: clamp(10px, 1.8vw, 16px);
          font-family: sans-serif;
          font-weight: bold;
        }
      }
    }
    .li01{
      .flex02{
        flex-wrap: wrap;
        .txtbox{
          width: 34%;
        }
        .imgbox{
          width: 65%;
          text-align: center;
          .logo_np{
            width: 40%;
            margin: 0 auto;
          }
        }
        .annotxt{
          display: block;
        }
        p{
          .annotxt{
            margin-top: 0;
            display: inline-block;
          }
        }
      }
    }
    .imgbox{
      &.mt{
        margin-top: 20px;
      }
    }
    .annotxt{
      &.mt{
        margin-top: 10px;
      }
    }
    .li02{
      .txtbox{
        width: 100%;
      }
      .imgbox{
        width: 30%;
        margin: 40px auto;
      }
    }
    .annotxt{
      margin-top: 20px;
    }
    .li03{
      .item_l{
        width: 54%;
        .imgbox{
          width: 80%;
          margin: 0 auto 35px;
        }
        .txtbox{
          width: 100%;
        }
      }
      .item_r{
        width: 41%;
        .imgbox{
          margin-bottom: 35px;
        }
        .txtbox{
          margin-bottom: 15px;
        }
      }
      .flex02{
        .txtbox{
          width: 32%;
          margin-bottom: 10px;
        }
        .txtbox{
          margin-bottom: 10px;
        }
        .imgbox{
          width: 67%;
        }
      }
    }
    .li04{
      width: 48%;
      .imgbox{
        width: 30%;
        margin: 40px auto;
      }
      p{
        margin-bottom: 15px;
      }
    }
    .li05{
      .imgbox{
        width: 70%;
        margin: 0 auto 20px;
      }
    }
    .li06{
      .item_l{
        width: 48%;
        .imgbox{
          width: 80%;
          margin: 0 auto 20px;
        }
      }
      .item_r{
        width: 48%;
        .imgbox{
          width: 75%;
          margin: 0 auto 20px;
        }
      }
    }
    .li07{
      .imgbox{
        width: 50%;
        margin: 20px auto 30px;
      }
    }
    .li08{
      .imgbox{
        width: 75%;
        margin: 20px auto 40px;
      }
    }
    // .li05{
    //   .item_l{
    //     width: 63%;
    //     p{
    //       margin-bottom: 15px;
    //     }
    //     .imgbox{
    //       width: 105%;
    //       margin: -20px auto 0;
    //     }
    //   }
    //   .item_r{
    //     width: 35%;
    //     position: relative;
    //     z-index: -1;
    //     .annotxt{
    //       margin-top: 15px;
    //     }
    //     .billbox{
    //       margin-top: 15px;
    //     }
    //   }
    // }
    // .li06{
    //   p{
    //     margin-bottom: 20px;
    //   }
    //   .annotxt{
    //     margin: 20px auto 0;
    //   }
    // }
    // .li07{
    //   position: relative;
    //   p{
    //     width: 80%;
    //     margin-bottom: 20px;
    //   }
    //   .annotxt{
    //     margin: 20px auto 0;
    //   }
    //   .patch{
    //     width: clamp(10px, 21%, 110px);
    //     position: absolute;
    //     top: 8%;
    //     right: -15px;
    //   }
    // }
    // .li08{
    //   .item{
    //     width: 84%;
    //     .flex{
    //       margin-top: 15px;
    //     }
    //     .imgbox{
    //       width: 55%;
    //     }
    //     .annotxt{
    //       width: 42%;
    //     }
    //   }
    //   .imgbox{
    //     width: 15%;
    //   }
    // }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .sec_ee{
    .sec_header{
      &.flex{
        flex-wrap: wrap;
      }
      .imgbox{
        width: 100%;
        max-width: 100%;
        margin-bottom: 5vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
          width: 35%;
          display: block;
        }
        p{
          margin-left: 1em;
          display: block;
          font-family: sans-serif;
          font-weight: bold;
        }
      }
      .txtbox{
        width: 100%;
        padding-left: 0;
      }
    }
    p{
      font-size: clamp(10px, 4vw, 20px);
    }
    .flex{
      flex-direction: row;
    }
    li{
      width: 100%;
      margin-bottom: 10vw;
    }
    .li01{
      .flex01{
        margin-bottom: 8vw;
        .imgbox{
          width: 100%;
          margin-bottom: 5vw;
          display: flex;
          align-items: center;
          img{
            width: 30%;
            display: inline-block;
          }
          .beige{
            margin-left: 1em;
            font-size: clamp(10px, 4.8vw, 30px);
            font-family: sans-serif;
            font-weight: bold;
            letter-spacing: 0.1em;
          }
        }
        .txtbox{
          width: 100%;
        }
      }
      h3{
        margin-bottom: 8vw;
      }
      .flex02{
        .logo_npbox{
          width: 40%;
          margin: 0 auto 8vw;
          order: 1;
        }
        .txtbox{
          width: 100%;
          order: 2;
        }
        .imgbox{
          width: 100%;
          margin-top: 3vw;
          order: 4;
        }
        .annotxt{
          margin-top: 3vw;
          order: 3;
        }
      }
    }
    .imgbox{
      &.mt{
        margin-top: 3vw;
      }
    }
    .annotxt{
      &.mt{
        margin-top: 3vw;
      }
    }
    .li02{
      width: 48%;
      h3{
        margin-bottom: 8vw;
      }
      .imgbox{
        width: 70%;
        margin: 15.2vw auto;
      }
    }
    .li03{
      width: 48%;
      margin-bottom: 5vw;
      .flex{
        flex-wrap: wrap;
      }
      .item_l{
        width: 100%;
        margin-bottom: 10vw;
        .imgbox{
          width: 80%;
          margin: 0 auto 20px;
        }
        .txtbox{
          width: 100%;
          margin-bottom: 5vw;
        }
      }
    }
    .li_03_sp{
      width: 48%;
      margin-bottom: 10vw;
      .imgbox{
        width: 80%;
        margin: 0 auto 20px;
      }
      .txtbox{
        margin-bottom: 5vw;
      }
    }
    .li04{
      h3{
        margin-bottom: 8vw;
      }
      .imgbox{
        width: 70%;
        margin: 12.2vw auto;
      }
    }
    .li05{
      width: 48%;
      h3{
        padding: 1.1em 0.5em;
      }
      .imgbox{
        width: 80%;
        margin: 9vw auto;
      }
    }
    .li06{
      width: 48%;
      .flex{
        flex-wrap: wrap;
      }
      .item_l{
        width: 100%;
      }
    }
    .li_06_sp{
      width: 100%;
      .body{
        width: 48%;
        .imgbox{
          width: 80%;
          margin: 0 auto 20px;
        }
      }
    }
    .li07{
      width: 48%;
      h3{
        padding: 1.1em 0.5em;
      }
      p{
        width: 100%;
        margin-bottom: 5vw;
      }
      .imgbox{
        width: 80%;
        margin: 3.2vw auto 10vw;
      }
    }
    .li08{
      width: 48%;
      .imgbox_t{
        width: 38%;
        margin: 0 auto 5vw;
      }
      .imgbox_b{
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec_us{
    .sec_us_t{
      max-width: 1050px;
      width: 100%;
      margin-bottom: 70px;
      &.flex{
        justify-content: left;
        align-items: center;
      }
      .imgbox{
        width: 15%;
      }
      .txtbox{
        width: 82%;
        margin-left: 30px;
        .title{
          font-size: clamp(10px, 10vw, 22px);
          line-height: 1.5em;
          letter-spacing: 0.1em;
          span{
            font-size: clamp(10px, 10vw, 35px);
          }
        }
        .desc{
          margin-top: 20px;
          font-size: clamp(10px, 10vw, 16px);
        }
      }
    }
    ul{
      li{
        margin-bottom: 100px;
        position: relative;
      }
      .li_l{
        width: 38%;
        margin-top: 0;
      }
      .li_r{
        width: 59%;
        margin-top: 0;
      }
      h3{
        margin-bottom: 30px;
      }
      .imgbox{
        &.abs{
          width: 43%;
          position: absolute;
          left: 0;
          top: -5px;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
  .sec_us{
    .sec_us_t{
      max-width: 100%;
      width: 100%;
      margin-bottom: 25vw;
      &.flex{
        justify-content: space-between;
      }
      .imgbox{
        width: 30%;
      }
      .txtbox{
        width: 100%;
        margin: 5vw auto 0;
        text-align: center;
        .title{
          font-size: clamp(10px, 4.5vw, 25px);
          line-height: 2.5em;
          span{
            font-size: clamp(10px, 8vw, 48px);
          }
        }
        .descbox{
          margin-top: 8vw;
          text-align: left;
        }
        .desc{
          font-size: clamp(10px, 4vw, 22px);
        }
      }
    }
    ul{
      li{
        margin-bottom: 20vw;
        position: relative;
      }
      .li_l{
        width: 100%;
      }
      .li_r{
        width: 100%;
      }
      h3{
        margin-bottom: 8vw;
      }
      .imgbox{
        &.mt{
          margin-top: -18vw;
        }
      }
    }
  }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
}