@use "setting" as inc_set;

header{
  background: var(--col-green);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  transition: .5s;
  &.display_b{
    opacity: 1;
  }
// common
  .head_btn{
    background: var(--col-green2);
    border: solid 1px var(--col-green2);
    position: relative;
    opacity: 1;
    transition: .5s;
    p{
      color: #fff;
      font-family: 'apotf',sans-serif;
    }
    a{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    span{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0,-50%);
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-left: 12px solid #fff;
      border-bottom: 8px solid transparent;
    }
    &:hover{
      background: #fff;
      p{
        color: var(--col-green2);
      }
      span{
        border-left: 12px solid var(--col-green2);
      }
    }
    &.none{
      pointer-events: none;
      position: relative;
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.7);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  //

  .top_header{
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    width: 100%;
    position: relative;
    .logo{
      max-width: 220px;
      width: 50%;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translate(0,-50%);
      a{
        color: #fff;
        font-size: clamp(10px,4vw,18px);
        font-weight: 600;
      }
    }
    .link_box{
      display: flex;
      justify-content: flex-end;
      .head_btn{
        width: 140px;
        padding: 14px 0 12px;
        margin-right: 20px;
        box-sizing: border-box;
        p{
          padding-left: 30px;
          font-size: clamp(10px,3vw,14px);
        }
      }
    }
    .g_menu_btn{
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;
      .menu_p{
        color: #fff;
        margin-right: 10px;
        font-size: clamp(10px,4vw,18px);
      }
      .g_menu_btn_img{
        width: clamp(35px,5vw,40px);
        &.close{
          display: none;
        }
      }
      &.active{
        position: fixed;
        z-index: 99;
        .open{
          display: none;
        }
        .close{
          display: block;
        }
      }
    }
  }

  .g_nav{
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    right: -100%;
    transition: .5s;
    z-index: 98;
    background: #fff;
    &.active{
      opacity: 1;
      right: 0;
      .g_nav_in{
        .ill_anime{
          // transition: .5s;
          opacity: 0;
          &.ill_anime_l{
            animation: anime_l ease-out forwards 1s .5s;
          }
          @keyframes anime_l {
            0% {
              transform: translateX(-15px) rotate(-10deg);
              opacity: .3;
            }
            50% {
              transform: translateX(0) rotate(10deg);
              opacity: .5;
            }
            100% {
              transform: translateX(0) rotate(0);
              opacity: 1;
            }
          }

          &.ill_anime_r{
            animation: anime_r ease-out forwards 1s .5s;
          }
          @keyframes anime_r {
            0% {
              transform: translateX(15px) rotate(10deg);
              opacity: .3;
            }
            50% {
              transform: translateX(0) rotate(-10deg);
              opacity: .5;
            }
            100% {
              transform: translateX(0) rotate(0);
              opacity: 1;
            }
          }
        }
      }
    }
    .g_nav_in{
      max-width: 1200px;
      width: 95%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // overflow-y: auto;
      .menu_ill{
        max-width: 65px;
      }
      .bh_p_wrap{
        justify-content: center;
        .menu_ill{
          margin: 0 50px;
          width: 15%;
        }
      }
      .bh_p{
        font-size: clamp(10px,8vw,32px);
        text-align: center;
        color: var(--col-green2);
      }
      nav{
        width: 100%;
        margin: 15px auto 30px;
        position: relative;
        .menu_illab{
          position: absolute;
          &.menu_ill03{
            top: 46%;
            left: 30%;
          }
          &.menu_ill04{
            bottom: 30%;
            left: 0%;
          }
          &.menu_ill05{
            bottom: 16%;
            right: 2%;
          }
        }
        ul{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li{
            width: calc(100%/3);
            // padding: 35px 0;
            // height: 105px;
            height: clamp(90px,10vw,105px);
            border-bottom: solid 1px var(--col-green2);
            text-align: center;
            position: relative;
            .new{
              color: #fff;
              display: inline-block;
              height: auto;
              top: 5px;
              right: 2%;
              padding: 5px 7px 5px;
              font-size: clamp(10px, 2.5vw, 10px);
              text-align: center;
              background: #9e0000;
              position: absolute;
            }
            &.none{
              pointer-events: none;
              p{
                opacity: .3;
              }
            }
            &:hover{
              transition: 1s;
              background: var(--col-green2);
              p{
                color: #fff;
              }
            }
            a{
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            p{
              color: var(--col-green2);
              font-family: 'apotf',sans-serif;
              font-size: clamp(10px,1.7vw,18px);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
            &:nth-last-of-type(-n+3){
              border-bottom: none;
            }
            &:nth-of-type(3n - 1){
              &::before,&::after{
                content: "";
                position: absolute;
                bottom: 0;
                width: 1px;
                height: 100%;
                background: var(--col-green2);
              }
              &::before{
                left: 0;
              }
              &::after{
                right: 0;
              }
            }
            &.active{
              background: var(--col-green2);
              p{
                color: #fff;
              }
            }
            &.smooth{
              border-bottom: none;
            }

          }
        }
      }
      .menu_in{
        display: flex;
        justify-content: space-between;
        max-width: 540px;
        width: 100%;
        margin: 0 auto;
        .head_btn{
          width: 240px;
          padding: 14px 0;
          p{
            width: 97%;
            text-align: center;
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .top_header{
      margin: 0 10px 0 auto;
      padding: 0;
      width: 100%;
      .logo{
        left: 10px;
      }
      .link_box{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        background: #fff;
        .head_btn{
          width: calc(100%/4);
          height: 50px;
          padding: 0;
          margin-right: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border: none;
          p{
            padding: 0;
          }
          .icon{
            width: auto;
            height: 25px;
            margin: 0 auto 5px;
          }
          &:nth-of-type(1){
            border-right: 1px solid #fff;
          }
          &:nth-of-type(3){
            border-right: 1px solid #fff;
            border-left: 1px solid #fff;
          }
          &:hover{
            background: var(--col-green2);
            p{
              color: #fff;
            }
            span{
              border-left: 12px solid #fff;
            }
          }

          // &.req,.res{
          //   &:hover{
          //     p{
          //       color: #fff;
          //     }
          //     span{
          //       border-left: 12px solid #fff;
          //     }
          //   }
          // }
          &.req{
            // background: #b20005;
            &:hover{
              background: var(--col-red);
            }
          }
          &.res{
            // background: #0062b3;
            &:hover{
              background: var(--col-blue);
            }
          }
        }
      }

      .g_menu_btn{
        padding: 10px;
        margin-right: 0;
        &.active{
          .menu_p{
            display: none;
          }
        }
      }
    }

    .g_nav{
      .g_nav_in{
        // height: clamp(400px,75vh,550px);
        // height: calc(100vh - 20vh);
        max-height: 620px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%,-50%);
        overflow-y: initial;
        .bh_p_wrap{
          .menu_ill{
            margin: 0 6vw;
            max-width: 50px;
          }
        }
        nav{
          width: 100%;
          // margin: 2vw 0;
          ul{
            li{
              width: calc(100%/2);
              padding: 0;
              height: 10vw;
              min-height: 70px;
              .new{;
                top: 2px;
                right: 2%;
                padding: 3px 6px 3px;
              }
              p{
                letter-spacing: -0.08em;
                font-size: clamp(10px,3.7vw,18px);
                &.l_s{
                  letter-spacing: -0.15em;
                }
              }
              &:nth-of-type(3n - 1){
                &::before,&::after{
                  content: none;
                }
                &::before{
                  left: auto;
                }
                &::after{
                  right: auto;
                }
              }
              &:nth-of-type(odd){
                &::before{
                  content: "";
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 1px;
                  height: 100%;
                  background: var(--col-green2);
                }
              }

              &:nth-last-of-type(-n+3){
                border-bottom: solid 1px var(--col-green2);
              }
              // menu追加時、ここの数字変えればいい
              &:nth-last-of-type(-n+2){
                border-bottom: none;
              }

              // &:nth-last-of-type(-n+3){
              //   border-bottom: solid 1px var(--col-green2);
              // }

              // &:nth-last-of-type(-n+1){
              //   border-bottom: none;
              // }
              
            &.smooth{
              border-bottom: none;
            }
            }
          }
        }
        .menu_in{
          margin: 0 auto;
          .head_btn{
            width: 48%;
            padding: 10px 0;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
