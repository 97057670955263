@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#concept {
  .main_wrap {
    .imgbox {
      background: url('../img/concept/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .concept_wrap {
    .max1000 {
      max-width: 1000px;
      width: 95%;
      margin: 0 auto;
    }

    .t_pa {
      color: rgba(135, 98, 57, 0.2);
      font-size: clamp(10px, 14vw, 40px);
      letter-spacing: clamp(0em, 0vw, 0.03em);
      line-height: 1;
      font-family: "tsunagi_g", sans-serif;
      margin-bottom: 40px;
    }

    .t_p1 {
      font-size: clamp(10px, 5vw, 20px);
      font-family: 'k_goth6', sans-serif;
      margin-bottom: 25px;

      &.sty2 {
        font-size: clamp(10px, 6.5vw, 40px);

        b {
          color: #008CD6;
        }
      }

      &.alp1 {
        color: #E6DED9;
        font-family: "apotf", sans-serif;
        font-size: clamp(10px, 6.5vw, 40px);
        margin-bottom: 10px;
      }

      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
      }
    }

    .t_p2 {
      font-size: clamp(10px, 4vw, 16px);
      font-feature-settings: "palt";

      &.df {
        letter-spacing: 0;
      }
    }

    .t_p3 {
      font-size: clamp(10px, 3.5vw, 14px);
      font-feature-settings: "palt";
      letter-spacing: 0.1em;
      line-height: 1.6;
    }

    sup {
      position: relative;

      i {
        font-size: clamp(10px, 2.5vw, 12px);
        font-feature-settings: "palt";
        letter-spacing: 0em;
        line-height: 1;
        position: relative;
        font-style: normal;
        position: absolute;
        position: absolute;
        right: -1.2em;
      }
    }

    .t_note {
      font-size: clamp(10px, 2.5vw, 12px);
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1.3;
    }

    .ttl_p {
      font-size: clamp(10px, 7vw, 26px);
      //font-family: "k_goth6",sans-serif;
      line-height: 2;
      position: relative;
    }

    .sec {
      .flex_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .concept_ttl {
        text-align: center;
        position: relative;
        //margin-bottom: 80px;
        box-sizing: border-box;
        //padding-right: 120px;
        //padding-left: 120px;
        .t_p1 {
          font-size: clamp(10px, 6.2vw, 38px);

          b {
            color: #008CD6;
          }
        }
        .t_p1_2{
          font-size: clamp(10px,3.6vw,16px);
          // letter-spacing: .1em;
        }

      }

      .sec-inner {
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
      }
    }


    .i_h2_01 {
      position: relative;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
      margin-bottom: 20px;
    }

    .i_img_01 {
      position: absolute;
      width: 24%;
      right: -60px;
      max-width: 140px;
      top: -56px;
      z-index: 5;
      transform: translateX(-20px) translateY(0);

      &.scrollin {
        transform: translate(0, 0);
      }
    }


    .h3_p {
      text-align: center;
      color: rgba(#876239, .2);
      font-size: clamp(10px, 14vw, 120px);
      letter-spacing: clamp(0em, 0vw, 0.03em);
      line-height: 1;
      font-family: 'tsunagi_g', sans-serif;
      margin-bottom: 50px;
    }

    figure {
      position: relative;

      .note {
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 3;

        &.b {
          color: #000;
        }

        &.rrt {
          //position: relative;
          //right: auto;
          //bottom: auto;
          bottom: -12px;
          text-align: right;
        }
      }
    }


    .txt_bg {
      position: relative;
      z-index: -1;
      padding-top: 30px;
      margin-bottom: 80px;

      .bg_p {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(#876239, .2);
        font-size: clamp(10px, 24vw, 280px);
        letter-spacing: clamp(0em, 0vw, 0.03em);
        line-height: 1;
        font-family: 'tsunagi_g', sans-serif;

        &.info_p {
          font-size: clamp(10px, 15vw, 175px);
        }
      }

      .txt_bg_in {
        p {
          text-align: center;
          letter-spacing: .01em;
          font-family: 'k_goth6', sans-serif;
        }

        .bg_p1 {
          font-size: clamp(10px, 5.5vw, 28px);
          margin-bottom: 20px;
        }

        .bg_p2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .main_wrap {
      .imgbox {
        background: url('../img/concept/main_sp.jpg');
        background-position: center top;
        background-size: cover;
      }
    }
    .main_txt .p1 {
      font-feature-settings: "palt";
      //letter-spacing: 0.1em;
      i {
        display: inline-block;
        margin-right: -1em;
      }
    }
    .concept_wrap {
      .sec {
        .concept_ttl {

          //margin-bottom: 10vw;

        }
      }

      .ttl_p {
        font-size: clamp(10px, 5.2vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        transform: translateY(-20px);
      }

      .h3_p {
        font-size: clamp(10px, 12vw, 120px);
      }

      .h4_p {
        font-size: clamp(14px, 3.8vw, 20px);
        font-feature-settings: "palt";
        letter-spacing: 0;
        white-space: nowrap;
        height: 28px;
        line-height: 28px;

        &::after {
          width: 100%;
        }

        sup {
          display: block;

          i {
            right: 0;
            top: -30px;
          }
        }

        &.sc9 {
          b {
            display: inline-block;
            transform: scaleX(0.8);
            transform-origin: 0% 0%;

          }


        }

        &::before {
          top: 2px;
          width: 22px;
          height: 22px;

        }

        padding-left: 26px;
      }

      .t_pa {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }

      .t_p1 {

        font-size: clamp(10px, 5.5vw, 22px);
        margin-bottom: 6vw;
        letter-spacing: 0;

        &.sty2 {
          //font-size: clamp(10px,4.2vw,20px);
          font-size: clamp(10px, 4.8vw, 20px);
        }
      }

      .t_p2 {


        font-size: clamp(10px, 4vw, 16px);
        letter-spacing: 0.01em;

        &.sty2 {
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }

      .t_p3 {
        font-size: clamp(10px, 3.5vw, 14px);
        font-feature-settings: "palt";
        letter-spacing: 0.1em;
        line-height: 1.6;
      }

      .txt_bg {
        padding-top: 0px;
        margin-bottom: 6vw;


      }

      .i_img_01 {
        right: -10px;
      }

      .i_h2_01 {
        width: 60%;
        max-width: 600px;
        margin-bottom: 20px;
        margin-top: 3vw;
      }

      .i_img_04 {
        width: 30%;
        max-width: 140px;
        margin-right: 0px;
        margin-top: -6vw;
      }
    }


    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  @keyframes kani1 {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
    //20% {
    //  -webkit-transform: scale3d(1.1,1.1,1.1);
    //  transform: scale3d(1.1,1.1,1.1);
    //}
    //40% {
    //  -webkit-transform: scale3d(.9,.9,.9);
    //  transform: scale3d(.9,.9,.9);
    //}
    60% {
      opacity: 1;
      //-webkit-transform: scale3d(1.03,1.03,1.03);
      //transform: scale3d(1.03,1.03,1.03)
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    80% {
      -webkit-transform: scale3d(1);
      transform: scale3d(1);
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @keyframes kani1b {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @keyframes kani1c {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      //transform: translateY(100px) ;
    }

    to {
      opacity: 1;

      //transform: translateY(0);
    }
  }
  @keyframes kani1d {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }

    to {
      opacity: 1;

      transform: translateX(0);
    }
  }

  .sec1 {
    padding: 80px 0;

    .sec1a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;

      li {
        width: 33.3333%;
        cursor: pointer;

        &:nth-child(2) {
          margin-top: 200px;
          z-index: 12;
        }

        &:nth-child(4),
        &:nth-child(6) {
          margin-top: -90px;
        }

        &:nth-child(5) {
          margin-top: 70px;
          z-index: 13;
        }

        .bg {
          display: block;
          vertical-align: bottom;
          width: 100%;
          opacity: 0;
        }

        .pnlone {
          position: relative;
          transform: scale(1.2);


          &::before {

            transition: 1s;
            position: absolute;
            top: 140px;
            opacity: 0;
            z-index: 11;
            left: 50%;

            transform: translateX(-50%);
            width: 24px;
            height: 24px;
            background: url('../img/concept/ci_01_05.png');
            background-position: center top;
            background-size: 100%;
            background-repeat: no-repeat;
            content: "";
          }

          .cv {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 3;

            &.cv1 {
              z-index: 3;
              opacity: 0;
              transform: scale(0.5);

              &.scrollin {
                animation: kani1 2s .4s forwards;
              }
            }

            &.cv2 {
              z-index: 4;
              transition: 0.6s 0s cubic-bezier(.52, .08, .18, 1);
            }

            &.cv3 {
              z-index: 5;
              opacity: 0;
              transform: scale(0.5);

              &.scrollin {
                animation: kani1 2s .8s forwards;
              }
            }
          }
        }

        &:nth-child(1) {
          .pnlone {
            &::before {

            }
          }
        }

        &:nth-child(2) {
          .pnlone {
            &::before {
              background: url('../img/concept/ci_02_05.png');
              background-position: center top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(3) {
          .pnlone {
            &::before {
              left: 54%;
              background: url('../img/concept/ci_03_05.png');
              background-position: center top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(4) {
          .pnlone {
            &::before {
              left: 54%;
              background: url('../img/concept/ci_04_05.png');
              background-position: center top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(5) {
          .pnlone {
            &::before {
              background: url('../img/concept/ci_05_05.png');
              background-position: center top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(6) {
          .pnlone {
            &::before {
              background: url('../img/concept/ci_06_05.png');
              background-position: center top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &.scrollin {
          animation: kani1 2s forwards;

          .pnlone {
            &::before {
              top: 40px;
              opacity: 1;
              transform: translateX(-50%) rotateY(0);
            }

          }
        }

        &:hover {
          z-index: 15;
          //animation:none;
          .pnlone {
            &::before {
              top: 50px;
              transform: translateX(-50%) rotateY(180deg);
              transition: 0.6s 0s cubic-bezier(.52, .08, .18, 1);
            }

            .cv {
              &.cv2 {
                transform: scale(1.1);


                opacity: 0.7;
              }

            }
          }

        }
      }
    }

  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec1 {
      padding: 6vw 0;
      padding-bottom: 12vw;

      .sec1a {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -50px;

        li {
          width: 50%;
          cursor: pointer;

          &:nth-child(2) {
            margin-top: 200px;
            z-index: 12;
          }

          &:nth-child(3) {
            margin-top: -140px;
          }

          &:nth-child(4),
          &:nth-child(6) {
            margin-top: 70px;
          }

          &:nth-child(5) {
            margin-top: -140px;
            z-index: 13;
          }

          .pnlone {
            &::before {

              width: 14px;
              height: 14px;

            }
          }

          &.scrollin {
            .pnlone {
              &::before {
                top: 17px;


              }
            }
          }

        }
      }

    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2 {
    padding: 80px 0;

    .sec2a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      flex-wrap: wrap;
      margin-bottom: 80px;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;

      dt {
        width: 40%;
      }

      dd {
        width: 55%;
      }
    }


  }


  #pop_movie {
    position: fixed;
    // width: 100%;
    // height: 100vh;
    // left: 0;
    // top: 0;
    width: 95%;
    height: 97vh;
    left: 50%;
    top: 50%;
    z-index: 9999;

    pointer-events: none;

    -moz-transition: 1s cubic-bezier(.52, .08, .18, 1);
    -o-transition: 1s cubic-bezier(.52, .08, .18, 1);
    -webkit-transition: 1s cubic-bezier(.52, .08, .18, 1);
    transition: 1s cubic-bezier(.52, .08, .18, 1);

    opacity: 0;
    //-webkit-transform:translateY(105%);
    //    -moz-transform:  translateY(105%);
    //    -ms-transform:  translateY(105%);
    //    transform:  translateY(105%);

    // transform: scale(1.3) rotate(0.01deg) translate(-50%,-50%);
    transform: translate(-50%,-50%);

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: "";
      // transform-origin: 0% 0%;
      // transform: scaleX(0);
    }

    .pop_note{
      margin-top: 10px;
      @media screen and (max-width: inc_set.$media_sp) {
        width: 90vw;
        margin-left: calc(-1 * (90vw - 100%) / 2);
      }
    }

    .secin {
      z-index: 15;
      width: 100%;
      height: 100%;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;

      figure {
        position: relative;

        .note {
          z-index: 5;
          position: absolute;
          right: 5px;
          bottom: 5px;
          color: #fff;

          &.b {
            color: #000;
          }
        }
      }

      .popcontent {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 1200px;

        opacity: 0;


        @media screen and (max-width: inc_set.$media_sp) {
          overflow: hidden;
        }

        > li {
          display: none;
          padding: 60px 60px 70px;
          box-sizing: border-box;
          position: relative;
          @media screen and (max-width: inc_set.$media_sp) {
            padding-top: 40px;
          }
          h4 {
            text-align: center;

            span {
              font-size: clamp(10px, 3.5vw, 14px);
              font-feature-settings: "palt";
              letter-spacing: 0.1em;
              line-height: 1.6;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }

            font-size: clamp(10px, 9vw, 30px);
            font-family: 'k_goth6', sans-serif;
            margin-bottom: 25px;

          }

          .i_pf_01_01 {
            p{
              position: absolute;
              right: 0;
              bottom: 0;
              font-size: 10px;
            }
          }
          .morebtn{
            background-color: #CBBF32;
            width: 90%;
            max-width: 200px;
            height: 50px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap:wrap;
            position: absolute;
            left: 48%;
            top: 44%;
            z-index: 7;
            @media screen and (max-width: inc_set.$media_sp) {
              position: relative!important;
              	margin-left: auto!important;
              		margin-right: auto!important;
              left: auto!important;
              top: auto!important;
              margin-top: 3vw;
            }
            &::before{
              content: "";
              right: 10px;
              top: 50%;

              transform: translate(0, -50%);
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-left: 12px solid #fff;
              border-bottom: 8px solid transparent;
              position: absolute;
            }
            p{
              text-align: center;
              font-family: "apotf", sans-serif;
              line-height: 1;
              color: #fff;
            }
            a{
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              z-index: 5;
            }
            transition: 0.3s;
            &:hover{
              opacity: 0.7;
            }
          }
          &.pop1 {
            .morebtn {
              left: 47%;
              top: 44%;
              background-color: #CBBF32!important;
            }
          }
          &.pop2 {
            .morebtn {
              left: 38%;
              top: 82%;
              background-color: #CF737A!important;
            }
          }
          &.pop3 {
            .morebtn {
              left: 40%;
              top: 82%;
              background-color: #7BAA17!important;
            }

          }
          &.pop4 {
            .morebtn {
              left: 38%;
              top: 82%;
              background-color: #259F94!important;
            }

          }
          &.pop5 {
            .morebtn {
              left: 44%;
              top: 82%;
              background-color: #CF712E!important;
            }
          }
          &.pop6 {
            .morebtn {
              left: 38%;
              top: 80%;
              background-color: #A1558E!important;
            }
          }
          .popcontent1 {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            box-sizing: border-box;
            dt {
              width: 50%;
              box-sizing: border-box;
              h4 {
                font-size: clamp(10px, 6vw, 18px);
                text-align: left;
                position: relative;
                i {
                  font-size: clamp(10px, 12vw, 120px);
                }
                .h4pi {
                  position: absolute;
                }

                @media screen and (max-width: inc_set.$media_sp) {
                  margin-top: 6vw;
                  font-size: 3.5vw;
                  width: 100vw;
                  margin-left: calc(-1 * (100vw - 100%) / 2);
                  i {
                    display: block;
                    position: relative;
                    font-size: 16vw;
                    margin-top: 3vw;
                    font-feature-settings: "palt";
                    letter-spacing: 0;


                  }
                }
              }

              .t_p3 {
                line-height: 1.5em;
                letter-spacing: 0.15em;
                font-size: clamp(10px, 4vw, 16px);
              }

            }

            dd {
              box-sizing: border-box;
              width: 50%;
              opacity: 0;

            }

            .pdfig {
              position: relative;
              opacity: 0;
              transition: 1s;

              .bg {
                display: block;
                vertical-align: bottom;
                width: 100%;
              }

              .cv {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                transition: 1s;
                transform: translateY(50px);
              }

              @media screen and (max-width: inc_set.$media_sp) {
                margin-bottom: 3vw;
              }
            }



            &.pop1 {


              .pdfig {


              }

              dt {
                width: 46%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                }

                h4 {
                  color: #CBBF32;

                  .h4pi {

                    right: -120px;
                    bottom: 0;
                    width: 30%;
                    max-width: 150px;
                    right: -120px;
                    bottom: -20px;
                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      width: 28%;
                      max-width: 140px;
                      right: 16px;
                      bottom: -40px;
                    }
                  }
                }
              }

              dd {
                width: 54%;
                padding-top: 140px;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  padding-top: 0px;
                }
              }
            }

            &.pop2 {
              //background-color: #FCE9E8;
              //color: #CF737A   ;


              dt {
                order: 2;
                width: 60%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 1;
                }

                h4 {
                  text-align: right;
                  color: #CF737A;

                  .h4pi {
                    left: 6%;
                    //left: -40px;
                    bottom: -40px;
                    width: 30%;
                    max-width: 80px;

                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      left: 3%;
                      bottom: -20px;
                      width: 30%;
                      max-width: 46px;
                    }
                  }
                }
              }

              dd {
                width: 32%;
                order: 1;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 2;
                }
              }
            }

            &.pop3 {
              //background-color: #F0F4CC  ;
              //color: #7BAA17   ;

              .pdfig {
                margin-top: -60px;
                @media screen and (max-width: inc_set.$media_sp) {
                  margin-top: -40px;
                }
              }

              dt {
                width: 60%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                }

                h4 {
                  color: #7BAA17;

                  .h4pi {

                    right: 200px;
                    bottom: 0px;
                    width: 32%;
                    max-width: 120px;

                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      right: 50px;
                      bottom: 0px;
                      width: 32%;
                      max-width: 60px;
                    }
                  }
                }
              }

              dd {
                width: 32%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                }
              }
            }

            &.pop4 {
              //background-color: #DAEFEE    ;
              //color: #259F94   ;

              dt {
                width: 60%;
                order: 2;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 1;
                }

                h4 {
                  text-align: right;
                  color: #259F94;

                  .h4pi {
                    right: -33px;
                    bottom: -40px;
                    width: 24%;

                    max-width: 120px;
                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      right: 14px;
                      bottom: -40px;
                      width: 22%;
                      max-width: 100px;
                    }
                  }
                }
              }

              dd {
                width: 32%;
                order: 1;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 2;
                }
              }
            }

            &.pop5 {
              //background-color: #FCE7CE      ;
              //color: #CF712E   ;


              .pdfig {
                margin-top: -50px;
                @media screen and (max-width: inc_set.$media_sp) {
                  margin-top: -40px;
                }
              }

              dt {
                width: 60%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                }

                h4 {
                  color: #CF712E;

                  .h4pi {

                    right: 72px;
                    bottom: 10px;
                    width: 32%;
                    max-width: 70px;

                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      right: 13%;
                      bottom: 0px;
                      width: 22%;
                      max-width: 50px;
                    }
                  }
                }
              }

              dd {
                width: 32%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                }
              }
            }

            &.pop6 {
              //background-color: #F0E1EE         ;
              //color: #A1558E   ;


              dt {
                order: 2;
                width: 60%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 1;
                }

                h4 {
                  text-align: right;
                  color: #A1558E;

                  .h4pi {

                    left: 9%;
                    bottom: -20px;
                    width: 32%;
                    max-width: 90px;

                  }

                  @media screen and (max-width: inc_set.$media_sp) {
                    text-align: center;
                    .h4pi {
                      left: 6.6%;
                      bottom: -10px;
                      width: 24%;
                      max-width: 60px;
                    }
                  }
                }
              }

              dd {
                width: 32%;
                order: 1;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  order: 2;
                }
              }
            }
          }

          .i_pf_01_01 {
            @media screen and (max-width: inc_set.$media_sp) {
              // width: 100vw;
              // margin-left: calc(-1 * (100vw - 110%) / 2);
              width: 90vw;
              margin-left: calc(-1 * (90vw - 100%) / 2);
            }
          }

          .popcontent2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            align-items: stretch;
            margin-top: 40px;
            @media screen and (max-width: inc_set.$media_sp) {
              margin-top: 3vw;
            }

            li {
              figure {
                height: 100%;

                img.bg {
                  height: 100%;
                  object-fit: cover;
                }

                .cv1 {
                  position: absolute;
                  width: 150px;
                  left: -20px;
                  top: -20px;
                  z-index: 5;
                  @media screen and (max-width: inc_set.$media_sp) {
                    width: 90px;
                    left: -10px;
                    top: -10px;
                  }
                }
              }

              &:nth-child(1) {
                width: 50%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 100%;
                  margin-bottom: 3vw;
                }

              }

              &:nth-child(2) {
                width: 24%;
                @media screen and (max-width: inc_set.$media_sp) {
                  width: 49%;
                }
              }

              &:nth-child(3) {
                width: 24%;

                .cv1 {
                  left: auto;
                  right: -20px;
                }

                @media screen and (max-width: inc_set.$media_sp) {
                  width: 49%;
                }
              }
            }
          }

          .popcontent4 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            align-items: stretch;

            margin-top: 10px;
            @media screen and (max-width: inc_set.$media_sp) {
              margin-top: 3vw;
            }

            li {
              width: 32.5%;

              figure {
                height: 100%;

                img.bg {
                  height: 100%;
                  object-fit: cover;
                }

                .cv1 {
                  position: absolute;
                  width: 120px;
                  left: -10px;
                  top: 5px;
                  z-index: 5;
                  @media screen and (max-width: inc_set.$media_sp) {
                    width: 90px;
                    left: -10px;
                    top: 10px;
                  }
                }
              }

              @media screen and (max-width: inc_set.$media_sp) {
                width: 100%;

              }

            }
          }

          .popcontent3 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            li {
              margin-bottom: 10px;
              width: 100%;
              figure{
                .note{
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8),0px 0px 5px rgba(0, 0, 0, 0.8),0px 0px 5px rgba(0, 0, 0, 0.8);
                  letter-spacing: .1em;
                }
              }
            }
          }
        }

      }
    }

    .close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 10px;
      top: 20px;
      //border: 1px solid #000;
      cursor: pointer;
      z-index: 17;
      transition: 1s;
      &.close_2{
        top: auto;
        right: auto;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%,0);
      }
      img {
        width: 40px;
        display: block;
      }

      @media screen and (max-width: inc_set.$media_sp) {
        width: 35px;
        height: 35px;
        right: 10px;
        top: 10px;
        img {
          width: 35px;
        }
      }
    }

    &.act {
      pointer-events: auto;
      opacity: 1;

      transform: scale(1) rotate(0.01deg);

      .secin {

      }

      .close {
        //right: 50px;
      }
    }
  }


  &.popupen {
    overflow: hidden;

    #pop_movie {
      pointer-events: auto;
      opacity: 1;

      // transform: scale(1) rotate(0.01deg) translate(-50%,-50%);
      transform: ranslate(-50%,-50%);

      &::before {

        transition: 1s cubic-bezier(.52, .08, .18, 1);
        transform: scale(1);
      }

      .secin {
        overflow: auto;

        .popcontent {
          transition: 1s 1s;
          opacity: 1;
        }
      }

      .close {
        //right: 50px;

      }
    }

    &.pp8 #pop_movie .secin .popcontent li.pop8,
    &.pp7 #pop_movie .secin .popcontent li.pop7,
    &.pp6 #pop_movie .secin .popcontent li.pop6,
    &.pp5 #pop_movie .secin .popcontent li.pop5,
    &.pp4 #pop_movie .secin .popcontent li.pop4,
    &.pp3 #pop_movie .secin .popcontent li.pop3,
    &.pp2 #pop_movie .secin .popcontent li.pop2,
    &.pp1 #pop_movie .secin .popcontent li.pop1 {
      display: block;

      .popcontent1 {
        h4 {
          animation: kani1d 1s 1s forwards;
        }

        dd {
          animation: kani1c 1s 1.4s forwards;

        }

        .pdfig {
          animation: kani1 1s 1s forwards;

          .cv1 {
            animation: kani1 1s 1.3s forwards;
          }

          .cv2 {
            animation: kani1 1s 1.5s forwards;
          }

          @media screen and (max-width: inc_set.$media_sp) {
            animation: kani1b 1s 1s forwards;

            .cv1 {
              animation: kani1b 1s 1.3s forwards;
            }
            .cv2 {
              animation: kani1b 1s 1.5s forwards;
            }
          }
        }
      }

      .popcontent3 {
        animation: kani1c 1s 1.4s forwards;
      }
    }

    &.pp1 {
      #pop_movie::before {
        background-color: #FFFBCC;
      }

      #pop_movie .secin .popcontent li.pop1 {
        .popcontent1 {
          .pdfig {
            animation: kani1b 1s 1s forwards;

            .cv1 {
              animation: kani1b 1s 1.3s forwards;
            }

            .cv2 {
              animation: kani1b 1s 1.5s forwards;
            }
          }
        }
      }
    }

    &.pp2 {
      #pop_movie::before {
        background-color: #FCE9E8;
      }
    }

    &.pp3 {
      #pop_movie::before {
        background-color: #F0F4CC;
      }
    }

    &.pp4 {
      #pop_movie::before {
        background-color: #DAEFEE;
      }
    }

    &.pp5 {
      #pop_movie::before {
        background-color: #FCE7CE;
      }
    }

    &.pp6 {
      #pop_movie::before {
        background-color: #F0E1EE;
      }
    }

  }

  @keyframes scroll-hint-appear {
    0% {
      transform: translateX(40px);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50%,
    100% {
      transform: translateX(-40px);
      opacity: 0;
    }
  }

  .scroll-hint.is-right-scrollable {
    background: linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-right-scrollable.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0)), linear-gradient(270deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint.is-left-scrollable {
    background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
  }

  .scroll-hint-icon {
    position: absolute;
    //top: calc(50% - 25px);
    top: calc(30% - 25px);
    left: calc(50% - 60px);
    box-sizing: border-box;
    width: 120px;
    height: 80px;
    border-radius: 5px;
    transition: opacity .3s;
    opacity: 0;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    padding: 20px 5px 10px 5px;
  }

  .scroll-hint-icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    pointer-events: none;
    z-index: 777;
    display: none;
    @media screen and (max-width: 584px) {
      display: block;
    }
  }

  .scroll-hint-text {
    font-size: 10px;
    color: #FFF;
    line-height: 1em;
    /*margin-top: 5px;*/
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon {
    opacity: .8;
  }

  .scroll-hint-icon:before {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #FFF;
    vertical-align: middle;
    text-align: center;
    content: "";
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDM8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIiBmaWxsPSIjZmZmIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon:after {
    content: "";
    width: 34px;
    height: 14px;
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDE8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIgZmlsbD0iI2ZmZiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiIGZpbGw9IiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==);
    opacity: 0;
    transition-delay: 2.4s;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:after {
    opacity: 1;
  }

  .scroll-hint-icon-wrap.is-active .scroll-hint-icon:before {
    animation: scroll-hint-appear 1.6s linear;
    animation-iteration-count: infinite;
  }

  .scroll-hint-icon-white {
    background-color: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .4);
  }

  .scroll-hint-icon-white:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDQ8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIi8+PC9nPjwvZz48L3N2Zz4=);
  }

  .scroll-hint-icon-white:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDI8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiLz48L2c+PC9nPjwvc3ZnPg==);
  }

  .scroll-hint-icon-white .scroll-hint-text {
    color: #000;
    line-height: 1em;
  }

  @media screen and (max-width: inc_set.$media_sp) {
    .sec2 {
      padding: 6vw 0;

      .sec2a {
        margin-bottom: 6vw;
        width: 95%;
        margin-left: auto;
        margin-right: auto;

        dt {
          width: 100%;
          margin-bottom: 6vw;
        }

        dd {
          width: 100%;
        }
      }
    }


    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
