@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#location{
  .main_wrap{
    .imgbox{
      background: url('../img/location/main.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .location_wrap{
    .sec{
      padding: 40px 0;
      .max1000{
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
      }
      .flex_area{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
      .small{
        font-size: 10px;
      }
      .ab{
        position: absolute;
      }

      .btn{
        padding: 15px 0;
        border-radius: 10px;
        margin: 0 auto;
        background: #fff;
        border: solid 1px #fff;
        transition: .5s;
        position: relative;
        p{
          color: #568534;
          text-align: center;
          font-size: clamp(10px,4vw,16px);
        }
        span{
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-left: 12px solid #568534;
          border-bottom: 8px solid transparent;
          position: absolute;
          right: 3%;
          top: 50%;
          transform: translate(0,-50%);
        }
      }

      .box_area{
        align-items: flex-start;
        margin-top: 50px;
        .box{
          margin: 5px 0;
          &.box_1{
            width: 100%;
          }
          &.box_2{
            width: calc(98%/2);
          }
          &.box_4{
            width: calc(96%/4);
          }
          p{
            font-size: 12px;
            letter-spacing: .01em;
            line-height: 1.2em;
          }
        }
      }

      .txt_bg{
        position: relative;
        z-index: -1;
        .bg_p{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: rgba(#876239,.2);
          font-size: clamp(10px,24vw,280px);
          letter-spacing: clamp(0em,0vw,0.03em);
          line-height: 1;
          font-family: 'tsunagi_g',sans-serif;
          &.info_p{
            font-size: clamp(10px,15vw,175px);
          }
        }
        .txt_bg_in{
          p{
            text-align: center;
            letter-spacing: .01em;
            font-family: 'k_goth6',sans-serif;
          }
          .bg_p1{
            font-size: clamp(10px,5.5vw,28px);
            margin-bottom: 20px;
          }
          .bg_p2{
            font-size: clamp(10px,3.8vw,16px);
          }
        }
      }

      .bnr{
        max-width: 900px;
        width: 95%;
        margin: 20px auto 0;
        position: relative;
        &:hover{
          opacity: .7;
        }
        a{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .sec{
        .box_area{
          margin-top: 20px;
          .box{
            margin: 5px 0;
            &.box_2{
              width: calc(96%/1);
            }
            &.box_4{
              width: calc(98%/2);
            }
          }
        }

        .txt_bg{
          margin-bottom: 0;
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________

    .sec1{
      .s1_txt{
        text-align: center;
        padding-bottom: 50px;
        .s1_p1{
          font-size: clamp(10px,5.5vw,22px);
          margin-bottom: 20px;
        }
        .s1_p2{
          font-size: clamp(10px,4vw,16px);
          letter-spacing: .01em;
        }
      }

      .s1_topimg{
        position: relative;
        img{
          vertical-align: bottom;
        }
        p{
          color: #fff;
        }
        .wappen{
          background: #534741;
          top: 2vw;
          right: 13vw;
          width: clamp(135px,15vw,230px);
          height: clamp(135px,15vw,230px);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &::before{
            content: "";
            width: 85%;
            height: 85%;
            border-radius: 50%;
            background: rgba(0,0,0,.4);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          p{
            position: relative;
            z-index: 1;
            text-align: center;
            font-family: 'apotf',sans-serif;
          }
          .w_p1{
            font-size: clamp(10px,2.1vw,28px);
            line-height: 1;
            margin-bottom: 5px;
            span{
              font-size: clamp(10px,3.5vw,60px);
              color: #FFFF00;
            }
          }
        }

        .icon{
          bottom: -5px;
          right: 10vw;
          width: clamp(165px,20vw,370px);
          overflow: hidden;
          .icon_txt{
            position: absolute;
            top: 18%;
            left: 50%;
            transform: translate(-50%,0);
            width: 100%;
            text-align: center;
            .i_p1{
              font-size: clamp(10px,1.2vw,16px);
            }
            .i_p2{
              font-size: clamp(10px,1.7vw,28px);
              margin: 0.5vw 0 0.1vw;
            }
          }
        }
      }

      .s1_bottom{
        background: #568534;
        padding: 40px 0 60px;
        p{
          color: #fff;
        }
        .s1_p3{
          text-align: center;
          font-size: clamp(10px,4vw,16px);
          margin: 50px 0;
        }
        .btn{
          max-width: 450px;
          width: 80%;
          a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
          }
          p{
            color: #568534;
          }
          // &::before{
          //   content: "";
          //   width: 100%;
          //   height: 100%;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   background: #568534;
          //   border-radius: 10px;
          //   transform: translateX(-102%);
          //   -webkit-transition: .5s;
          //   transition: .5s;
          // }
          &:hover{
            opacity: .8;
            // p{
            //   color: #fff;
            //   position: relative;
            //   z-index: 1;
            // }
            // span{
            //   border-left: 12px solid #fff;
            // }
            // &::before{
            //   transform: translateX(0);
            // }
          }
        }
      }
    }
    @media screen and (max-width: inc_set.$media_sp){
      .sec1{
        padding: 40px 0 50px;
        .s1_topimg{
          .wappen{
            width: clamp(160px,40vw,230px);
            height: clamp(160px,40vw,230px);
            top: 4vw;
            right: 6vw;
            .w_p1{
              font-size: clamp(10px,6vw,28px);
              span{
                font-size: clamp(10px,11vw,60px);
              }
            }
          }

          .icon{
            right: 3%;
            width: clamp(175px,50vw,350px);
            .icon_txt{
              // top: 18%;
              .i_p1{
                font-size: clamp(10px,3vw,16px);
              }
              .i_p2{
                font-size: clamp(10px,4vw,28px);
                margin: 0.5vw 0 0.1vw;
              }
            }
          }
        }

        .s1_bottom{
          .s1_p3{
            margin: 35px 0;
          }
          .btn{
            padding: 10px 0;
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________

    // ______________________________________

    .sec6{
      .bnr{
        position: relative;
        overflow: hidden;
        img{
          transform: scale(1);
          transition: 2s;
        }
        &::before{
          content: "";
          width: 95%;
          height: 90%;
          border: solid 3px #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 1;
        }
        &::after{
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          transition: 2s;
        }
        .btn{
          max-width: 500px;
          width: 70%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 1;
        }
        a{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        &:hover{
          img{
            transform: scale(1.1);
          }
          &::after{
            background: rgba(0,0,0,0);
          }
        }
        .img_p{
          position: absolute;
          font-size: 10px;
          left: 3.5%;
          bottom: 8%;
          color: #fff;
          z-index: 1;
        }
      }
    }

    @media screen and (max-width: inc_set.$media_sp){
      .sec6{
        .box_area{
          justify-content: center;
        }
        .bnr{
          .btn{
            padding: 2vw 0;
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________

    // ______________________________________

    .sec7{
      padding-top: 100px;
      .tab_map_area{
        margin-top: 50px;
        .tab_btn{
          .btn{
            width: calc(97%/6);
            border-radius: 5px;
            margin: 0;
            padding: 12px 0;
            cursor: pointer;
            p{
              color: #fff;
              text-align: center;
              font-family: "apotf",sans-serif;
              letter-spacing: .1em;
              font-size: clamp(10px,4vw,18px);
            }
            // &.active{
            //   background: #fff;
            //   p{
            //     color: #4B6D1A;
            //   }
            // }
            &.btn_01{
              background: #BC272D;
              border: solid 1px #BC272D;
            }
            &.btn_02{
              background: #F6AB3B;
              border: solid 1px #F6AB3B;
            }
            &.btn_03{
              background: #0071B7;
              border: solid 1px #0071B7;
            }
            &.btn_04{
              background: #662D8C;
              border: solid 1px #662D8C;
            }
            &.btn_05{
              background: #603813;
              border: solid 1px #603813;
            }
            &.btn_06{
              background: #006837;
              border: solid 1px #006837;
            }
          }
        }

        .map_area{
          margin-top: 30px;
          .map_in{
            .show_wrap{
              align-items: flex-start;
              .iframe{
                width: 50%;
                iframe{
                  width: 100%;
                  height: 480px;
                }
              }
              .list_wrap{
                width: 48%;
                .list{
                  margin-bottom: 10px;
                  p{
                    font-size: clamp(10px,3.6vw,16px);
                    letter-spacing: .1em;
                    small{
                      font-size: clamp(10px,3.2vw,12px);
                    }
                  }
                  span{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    color: #fff;
                    text-align: center;
                    margin-right: 5px;
                    letter-spacing: .1em;
                  }


                  .list_in{
                    align-items: flex-start;
                    span{
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
                &.li_01{
                  .list{
                    p{
                      color: #BC272D;
                    }
                    span{
                      background: #BC272D;
                    }
                  }
                }
                &.li_02{
                  .list{
                    p{
                      color: #F6AB3B;
                    }
                    span{
                      background: #F6AB3B;
                    }
                  }
                }
                &.li_03{
                  .list{
                    p{
                      color: #0071B7;
                    }
                    span{
                      background: #0071B7;
                    }
                  }
                }
                &.li_04{
                  .list{
                    p{
                      color: #662D8C;
                    }
                    span{
                      background: #662D8C;
                    }
                  }
                }
                &.li_05{
                  .list{
                    p{
                      color: #603813;
                    }
                    span{
                      background: #603813;
                    }
                  }
                }
                &.li_06{
                  .list{
                    p{
                      color: #006837;
                    }
                    span{
                      background: #006837;
                    }
                  }
                }
              }
            }
            display: none;
            &.active{
              display: block;
            }
          }
        }
      }
    }

    @media screen and (max-width: inc_set.$media_sp){
      .sec7{
        padding-top: 50px;
        .tab_map_area{
          margin-top: 20px;
          .tab_btn{
            justify-content: flex-start;
            .btn{
              width: calc(96%/2);
              margin: 3px 2px;
            }
          }

          .map_area{
            margin-top: 20px;
            .map_in{
              .show_wrap{
                // align-items: flex-start;
                .iframe{
                  width: 100%;
                  iframe{
                    height: 380px;
                    // float: none;
                  }
                }
                .list_wrap{
                  width: 100%;
                  margin-top: 20px;
                  .list{
                    border-bottom: 1px #333 dotted;
                    padding-bottom: 10px;
                    p{
                      width: 100%;
                      &:last-of-type{
                        text-align: right;
                      }
                    }
                    span{
                      padding: 2px 0 0;
                    }

                    .list_in{
                      align-items: center;
                      p{
                        width: auto;
                        text-align: left;
                        line-height: 1.2em;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________

    // ______________________________________
  }
}
