@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#outline{
  .outline_wrap{
    .sec{
      max-width: 1000px;
      width: 95%;
      margin: 50px auto 100px;
      .lead_box{
        margin-top: 150px;
      }
      .lead{
        font-size: clamp(10px,4.5vw,20px);
      }
      table{
        width: 100%;
        margin: 10px auto 60px;
        th{
          width: 25%;
          background: #dff0dd;
          border: solid 1px #000;
        }
        td{
          width: 75%;
          border: solid 1px #000;
        }
        th,td{
          text-align: left;
          letter-spacing: .1em;
          line-height: 1.5em;
          font-size: clamp(10px,3.3vw,15px);
          padding: 15px;
          box-sizing: border-box;
        }
      }

      .notice_box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .p1{
          width: 20%;
          font-size: clamp(10px,3.2vw,16px);
          border: solid 1px #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .p2{
          width: 79%;
          text-align: left;
          font-size: clamp(10px,2.7vw,14px);
          a{
            border-bottom: solid 1px #000;
          }
          &.p3{
            padding-top: 50px;
          }
        }
      }

      .update{
        margin: 50px 0;
        p{
          font-size: clamp(10px,3.3vw,15px);
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .outline_wrap{
      .sec{
        margin: 25px auto;
        .lead_box{
          margin-top: 80px;
        }
        table{
          margin: 10px auto 30px;
          border-top: 1px solid #000;
          th{
            width: 100%;
            border-bottom: none;
            border-top: none;
          }
          td{
            width: 100%;
          }
          th,td{
            display: block;
          }
        }

        .notice_box{
          .p1{
            width: 100%;
            padding: 10px 0;
            margin-bottom: 10px;
          }
          .p2{
            width: 100%;
            &.p3{
              padding-top: 30px;
            }
          }
        }

        .update{
          margin: 30px auto;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
