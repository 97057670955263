@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#child {

  .flex_area{
    align-items: flex-start;
  }

  .w1300{
    max-width: 1300px;
    margin: 0 auto;
    width: 95%;
  }

  .col_wh{
    color: #ffff;
  }
  
  .fs10{
    font-size: 10px;
  }

  .lp_mv{
    position: relative;
    background-color: #e5edce;
    &::before{
      position: absolute;
      content: "";
      background-image: url('../img/child/mv_bg1.png');
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 92%;
      z-index: 1;
    }
    .lp_mv_bg{
      position: relative;
      background-image: url('../img/child/mv_bg2.png');
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: linear-gradient(180deg, #97d66e 0%, rgba(215, 230, 214, 0) 100%);
        width: 100%;
        height: 100%;
        opacity: 0.6;
      }
      .mv_title{
        position: relative;
        width: 90%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 112px 0 0;
        z-index: 1;
        &::before, &::after{
          position: absolute;
          top: 0;
          content: "";
          width: 4px;
          height: 72%;
          background-color: #ffff;
          z-index: -1;
        }
        &::before{
          left: 18%;
        }
        &::after{
          right: 18%;
        }
        img{
          filter: drop-shadow(0px 0px 20px rgba(91, 114, 76, 0.35));
        }
        &.fall {
          animation: fall 2s ease-in-out forwards;
          position: relative;        
          transform-origin: top center; /* タイトルの支点を指定 */
        }
        @keyframes fall {
          0% {
            transform: translateY(-100%);
            opacity: 0; /* 最初は見えない */
          }
          50% {
            opacity: 1; /* 少しずつ現れる */
          }
          80% {
            transform: translateY(5.5%);
          }
          100% {
            transform: translateY(7%);
            // opacity: 1;
          }
        }

      }
      .lp_mv_img{
        position: relative;
        z-index: 1;
        width: 80%;
        padding: 65px 0 0;
        margin: 0 auto;
        max-width: 1132px;
        img{
          display: block;
        }
        .img_1{
          width: 65%;
          margin: 0 auto;
        }
        .img_2{
          width: 33%;
          margin: -5% auto 0;
        }
      }
      .mv_deco1{
        position: relative;
        z-index: 1;
        width: 98%;
        margin: 0 auto;
      }
    }
  }
  .lp_cntnt{
    .sec{
      .dashed{
        background-size: 29px 3px;
        background-image: linear-gradient(to right, #408eb2 13px, transparent 13px);
        background-repeat: repeat-x;
        background-position: left bottom;
        padding-bottom: 5px;
      }
      .img_area{
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        .img{
          width: calc(90%/2);
          padding: 8px;

          p{
            letter-spacing: 0;
          }
          &:last-child{
            position: relative;
            &::after{
              content: "";
              background-image: url('../img/child/sec2_deco1.png');
              background-size: 100% 100%;
              position: absolute;
              top: 15%;
              right: -47%;
              width: 60%;
              height: 80%;
            }
          }
          img{
            position: relative;
            z-index: 1;
          }
          p{
            padding-top: 10px;
            font-size: clamp(13px, 1.7vw, 20px);
            
          }
        }
        
      }
      .sec3_img, .sec5_img{
        .img{
          &:last-child{
            &::after{
              display: none;
            }
          }
        }
      }
      .sec_title{
        color: #568a34;
        font-size: clamp(90px, 11vw, 160px);
        letter-spacing: 0;
        font-weight: 500;
        width: 100%;
      }
      .sec_tex_area{
        .heading{
          justify-content: start;
          align-items: center;
          padding-bottom: 15px;
          .p1_wrap{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(90deg, #4ccdbb 0%, #5dd83a 100%);
            position: relative;
            &::before{
              content: '';
              position: absolute;
              top: 3px;
              left: 3px;
              right: 3px;
              bottom: 3px;
              background-color: white;
              border-radius: 50%;
            }
            .p1{
              position: absolute;
              top: 52%;
              left: 54%;
              transform: translate(-50%, -50%);
              color: #2abc99;
              font-size: clamp(23px, 3vw, 25px);
            }
          }
          .p2{
            font-size: clamp(18px, 1.5vw, 25px);
            letter-spacing: -1px;
            padding-left: 20px;
          }
        }
        .btm_p1, .btm_p2{
          padding: 15px 0;
        }
        .btm_p1{
          font-size: clamp(14px, 1.7vw, 17px);
        }
        .btm_p2{
          .p{
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            padding-bottom: 10px;
            p{
              font-size: clamp(13px, 1.7vw, 15px);
              letter-spacing: 0;
            }
          }
          .p1{
          }
          .p2{
          }
          .p3{
            p{
              &:first-child{
                white-space: nowrap;
              }
            }
          }
          .p4{
            padding-bottom: 0;
            p{
              &:first-child{
                white-space: nowrap;
              }
            }
          }
        }
        &.e_area{
          width: 74%;
          margin: -10% 0 auto 0;
        }
        &.d_area{
          width: 75%;
          margin: 10px 0 0;
        }
        &.a_area{
          width: 93%;
        }
      }
      .lef_area, .rig_area{
        width: calc(95%/2);
      }

      .anime2{
        opacity: 0;
        transition: all 0.5s;
        will-change: transform;

        &.scrollin {
          animation: kani1 1s .4s forwards;
        }
        @keyframes kani1 {

          0% {
            opacity: 0;
            transform: scale(0.3);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          5% {
              opacity: 1;
              transform: scale(1.1);
          }
          60% {
              opacity: 1;
              transform: scale(1.1);
          }
          100% {
              opacity: 1;
              transform: scale(1);
          }
        }
      }



      .area{

      }
      .area1{
        position: relative;
        &::after{
          content: "";
          background-image: url('../img/child/wh_tex_bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          width: clamp(76%, 5vw, 86%);
          height: clamp(76%, 5vw, 80%);
          z-index: -1;
        }
        .area1_img1{
          width: 47%;
        }
        .area1_img3{
          width: 45%;
        }
        
      }
      .area2{
        margin: 0 auto;
        max-width: 1300px;
        width: 95%;
        .area2_img1{
          width: 45%;
          margin: -43% auto 0;
          max-width: 1300px;
        }
        .area2_img2{
          width: 45%;
          margin: 10% 5% 0 0;
      
        }
        .area2_img3{
          width: 45%;      
        }
        
        .area2_img4{
          width: 45%;
          .sec4_4{
            // animation-delay: 1.6s;
          }
        }
        .area2_img5{
          width: 45%;
          margin: -13% 0 0;
          .sec4_5{
            margin: -5% 0 0 45%;
            width: 65%;
            // animation-delay: 1.5s;
          }
          .sec4_6{
            width: 45%;
            margin: -8% 0 0 17%;
            // animation-delay: 1.4s;
          }
        }
      }

      .wh_tex_wrap{
        width: 47%;
        margin-left: auto;
      }
      .sec_wh_tex{
        font-size: clamp(14px, 1.5vw, 25px);
        line-height: 2em;
      }



    }
    .sec1{
      background-color: #e5edce;
      padding: 80px 0 50px;
      .sec1_bg{
        background-image: url('../img/child/sec1_bg1.png');
        background-position: center;
        background-size: 100% 100%;
        width: 98%;
        margin: 0 auto;
        .p1{
          font-size: clamp(20px, 3vw, 33px);
          text-align: center;
          letter-spacing: 0.4rem;
          line-height: 5rem;
          color: #408eb2;
          font-weight: 400;
          padding: 80px 0 30px;      
          .fs1{
            font-size: clamp(30px, 3vw, 44px);
          }
          .fs2{
            font-size: clamp(35px, 4vw, 53px);
            font-weight: 900;
            letter-spacing: 2px;
            text-shadow:1px 1px 0 #408eb2,-1px 1px 0 #408eb2,1px -1px 0 #408eb2,-1px -1px 0 #408eb2;
            .fs3{
              font-size: clamp(30px, 3vw, 44px);
            }
          }
        }
        .dashed_wrap{
          width: 90%;
          margin: 0 auto;
          .blue{
            background-image: linear-gradient(to right, #408eb2 13px, transparent 13px);
          }
          .no1_list{
            padding: 50px 0;
            align-items: flex-start;
            .anime2{
              width: 30%;
              margin: 0 auto;
              transform: scale(1);
              opacity: 0;
              transition: all 0.5s;
              &.scrollin {
                animation: kani1 1s forwards;
                &:nth-of-type(1){
                  animation-delay: .3s;
                }
                &:nth-of-type(2){
                  animation-delay: .5s;
                }
                &:nth-of-type(3){
                  animation-delay: .7s;
                }
                &:nth-of-type(4){
                  animation-delay: .9s;
                }
                &:nth-of-type(5){
                  animation-delay: 1.1s;
                }
              }
              @keyframes kani1 {
                0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
                  -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                  animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                }
                0% {
                  opacity: 0;
                  -webkit-transform: scale3d(.3, .3, .3);
                  transform: scale3d(.3, .3, .3);
                }
                60% {
                  opacity: 1;
                  -webkit-transform: scale3d(1.1, 1.1, 1.1);
                  transform: scale3d(1.1, 1.1, 1.1);
                }
                80% {
                  -webkit-transform: scale3d(1);
                  transform: scale3d(1);
                }
                to {
                  opacity: 1;
                  -webkit-transform: scaleX(1);
                  transform: scaleX(1);
                }
              }
              img{
                width: 120px;
              }
              .title{
                position: relative;
                z-index: -1;
                color: #408eb2;
                text-align: center;
                font-size: clamp(12px, 1.5vw, 18px);
                letter-spacing: 0;
                width: 80%;
                margin: 0 auto;
                &::before, &::after{
                  content: "";
                  position: absolute;
                  top: 0;
                  width: 30px;
                  height: 53px;
                  background-size: 100% 100%;
                  background-position: center;
                }
                &::before{
                  background-image: url('../img/child/sec1_deco1.png');
                  left: -8%;
                }
                &::after{
                  background-image: url('../img/child/sec1_deco2.png');
                  right: -8%;            
                }
              }
              .item_btm{
                align-items: flex-start;
                justify-content: center;
                .tex_box{
                  padding: 18px 0 0;
                  p{
                    color: #231815;
                  }
                  .tex_box_p1{
                    font-size: clamp(20px, 1.7vw, 25px);
                    letter-spacing: -1px;
                    .small{
                      font-size: clamp(13px, 1.9vw, 18px);
                    }
                  }
                  .tex_box_p2{
                    font-size: clamp(16px, 3vw, 19px);
                    span{
                      color: #ad7322;
                      font-size: clamp(30px, 2.5vw, 37px);
                      line-height: 1em;
                    }
                  }
                  .tex_box_p3{
                    font-size: clamp(11px, 3vw, 13px);
                    letter-spacing: 0;
                    line-height: initial;
                  }
                }
              }
              
            }
            .item1{
              .tex_box{
              }
            }
            .item2{
              width: 39%;
              padding: 60px 0 0;
              .tex_box{
                width: 267px;
              }
            }
            .item3{
              width: 49%;
              padding: 60px 0 0;
            }
          }
        }
      }
    }
    .sec2{
      .sec2_bg{
        background-image: url('../img/child/sec2_bg1.png');
        background-position: center;
        background-size: 100% 100%;
        background-color: #e5edce;
        width: 100%;
        padding: 170px 0 270px;
        .img_wrap{
          position: relative;
          width: 100%;
          height: 93vw;
          .title_area{
            text-align: right;
            padding: 13px 0 0;
            .title_p1{
              font-size: clamp(60px, 8vw, 130px);
              letter-spacing: 0;
              font-weight: 500;
            }
            .title_p2{
              font-size: clamp(16px, 3vw, 20px);
              padding: 10px 0;
              line-height: 1.9em;
              .ft1{
                font-size: clamp(20px, 3vw, 37px);

              }
              .ft2{
                font-size: clamp(20px, 5vw, 50px);
              }
            }
          }
          .tex{
            position: absolute;
            top: 44%;
            right: 24%;
            z-index: 1;
            width: 30%;
            font-size: clamp(10px, 1.7vw, 25px);
          }
          .img_wrap{
            position: absolute;
            top: 4%;
            width: 100%;
            object-fit: cover;
            opacity: 0;
          }
          .sec2_1{
            z-index: 1;
            .circle{
              position: absolute;
              top: 4%;
              left: 3%;
              width: 19%;
              z-index: -1;
              .p_wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align:center;
                background-color: #4ccdbb;
                border-radius: 100%;
                p{
                  font-size: clamp(16px, 2vw, 20px);
                  letter-spacing: 0;
                  span{
                    font-size: clamp(21px, 3.7vw, 40px);
                    letter-spacing: -1px;
                  }
                }
                &::before{
                  content: '';
                  display: block;
                  padding-top: 100%;
                }
              }

            }
          }
          .sec2_2{
            z-index: 1;
            animation-delay: .8s;
          }
          .sec2_3{
            animation-delay: 1.2s;
          }
          .sec2_4{
            z-index: 1;
            animation-delay: 1.6s;
            p{
              font-size: clamp(10px, 1.7vw, 15px);
              letter-spacing: 0;
              &.img_btm1{
                width: auto;
                top: 86%;
                right: 57%;
              }
            }
            
          }
          .sec2_5{
            z-index: 0;
            animation-delay: 2.0s;
            p{
              font-size: clamp(10px, 1.7vw, 15px);
              letter-spacing: 0;
              &.img_btm2{
                width: 34%;
                top: 91%;
                right: -4%;
              }
            }
            
          }
          .sec2_6{
            animation-delay: 2.4s;
            
          }
        }
        .sec2_btm_area{
          .wh{
            background-image: linear-gradient(to right, #ffff 13px, transparent 13px);
            margin: 70px 0;
          }
          .list{
            justify-content: center;
            .item{
              width: 33%;
              margin: 25px 0;
              box-sizing: border-box;
              justify-content: center;
              flex-wrap: nowrap;
              .tex{
                align-items: center;
                margin-bottom: 10px;
                .p1_1{
                  font-size: clamp(13px, 1.7vw, 18px);
                  letter-spacing: 0;
                  text-align: left;
                  margin-left: 19%;
                  width: 100%;
                  letter-spacing: -1px;
                }
                .p1{
                  font-size: clamp(13px, 1.7vw, 18px);
                  border-bottom: solid 1px #ffff;
                  padding-bottom: 10px;
                  width: 100%;
                  letter-spacing: -1px;
                  span{
                    font-size: clamp(10px, 1.3vw, 16px);
                    background-color: #f19200;
                    padding: 5px 4px 3px 3px;
                    margin-right: 3px;
                    &.pink{
                      background-color: #e9638e;
                    }
                    &.purple{
                      background-color: #ba7bb1;
                    }
                    &.green{
                      background-color: #60b530;
                    }
                  }
                }
              .p2{
                font-size: clamp(12px, 1.7vw, 18px);
                padding-top: 15px;
                letter-spacing: -1px;
              }
              }
                .circle{
                  position: relative;
                  width: clamp(67px, 9vw, 100px);
                  height: clamp(67px, 9vw, 100px);
                  background-color: #f19200;
                  border-radius: 50%;
                  margin: -3% 0 0;
                  p{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: clamp(13px, 1.7vw, 15px);
                    letter-spacing: 0;
                    text-align: center;
                    width: 100%;
                    line-height: 1em;
                    .fs1{
                      font-size: clamp(20px, 1.7vw, 30px);
                      
                    }
                    .fs2{
                      font-size: clamp(12px, 1.7vw, 14px);
                      
                    }
                  }
                  &.pink{
                    background-color: #e9638e;
                  }
                  &.purple{
                    background-color: #ba7bb1;
                  }
                  &.green{
                    background-color: #60b530;
                  }
                }
            }
          }
        }
      }
    }
    .sec3{
      .sec3_bg{
        background-image: url('../img/child/sec3_bg1.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        .sec3_bg_in{
          justify-content: flex-end;
          .sec3_area{
            justify-content: center;

            .sec3_title{
              text-align: right;
              margin: 0 auto -41px;
            }
            .area1{

            }
            .area2{
              .area2_img3{
                margin: -30% 0 0;

              }
            }
          }
          .sec3_wh_tex_wrap{
            padding: 15% 0 0;
          }
          .sec3_2{
            // animation-delay: .8s;
          }
          .sec3_4{
            // animation-delay: 1.6s;
          }
          .sec3_img{
            padding-top: 40px;
          }

        }
      }
    }
    .sec4_5_bg{
      background-image: url('../img/child/sec4_5_bg.png');
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      .sec4{
        margin: 100px 0;
        .sec4_in{
          .sec4_area{
            justify-content: center;
            .area1{
              .area1_img1{
                margin: 0;
              }
              .sec4_wh_tex_wrap{
                padding: 16% 0 0;
            
              }
              &::after{
                right: 0;
                left: auto;
                transform: scaleX(-1);
              }
            }
            .area2{
              margin: 60px auto 0;
              .area2_img1{
                margin: 0;
              }
              .area2_img2{
                width: 45%;
                margin: -400px 0 0 0;
              }
              .area2_img4{
                margin: 0;
              }
              .area2_img5{
                margin: -9% auto 0;
              }
              
            }
          }
          .sec_title_sec4{
            margin: 0 auto -41px;
            text-align: left;
          }
          margin: 0 0 -11% auto;
          justify-content: flex-start;
          .sec_tex_area{
            &.f_area{
              width: 61%;
              margin: -10% 0 0 auto;
            }
            &.c_area{
              width: 93%;
              padding: 70px 0 0;
              margin: -10% 0 0 auto;
            }
            &.b_area{
              margin: 0 0 0 auto;
            }
          }
          .sec4_3{
            padding: 70px 0;
            // animation-delay: 1.2s;
          }
          .b_area{
            // animation-delay: 1.4s;
          }
        }
      }
      .sec5{
        padding: 100px 0 0;
        .sec5_in{
          justify-content: flex-end;
          .sec5_title{
            text-align: right;
            margin: 0 auto -41px;
          }
          .area1{
            .sec5_wh_tex_wrap{
              width: 45%;
              padding: 11% 0 0;
            }
            .area1_img1{
            }
            .sec5_img{
              padding-top: 80px;
              .img{
                width: calc(90%/3);
              }
            }
          }
        }
      }

    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

      
    .w1300{
      max-width: none;
    }

    .lp_mv{
      &::before{
        background-image: url('../img/child/mv_bg1_sp.png');
      }
      .lp_mv_bg{
        background-image: url('../img/child/mv_bg2_sp.png');
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        &::before{
        }
        .mv_title{
          padding: 75px 0 0;
          width: 95%;
          &::before, &::after{
          }
          &::before{
          }
          &::after{
          }
          img{
          }
          &.fall {
          }
        }
        .lp_mv_img{
          width: 100%;
          padding: 65px 0;
          img{
          }
          .img_1{
            width: 95%;
          }
          .img_2{
            width: 45%;
          }
        }
        .mv_deco1{
        }
      }
    }
    .lp_cntnt{
      .sec{
        .dashed{
        }
        .img_area{
          .img{
            width: 100%;
            padding: 0 0 40px;

            p{
              letter-spacing: 0;
            }
            &:last-child{
              &::after{
                background-image: url('../img/child/sec2_deco1_sp.png');
              }
            }
            img{
              position: relative;
              z-index: 1;
            }
            p{
              padding-top: 0;
              font-size: clamp(15px, 2.7vw, 20px);
              margin-left: 4%;
              
            }
          }
          
        }
        .sec3_img, .sec5_img{
          .img{
            &:last-child{
              &::after{
                display: none;
              }
            }
          }
        }
        .sec_title{
          font-size: clamp(48px, 11vw, 160px);
          width: 95%;
        }
        .sec_tex_area{
          .heading{
            .p1_wrap{
              width: 40px;
              height: 40px;
              &::before{
              }
              .p1{
                font-size: clamp(21px, 3vw, 25px);
              }
            }
            .p2{
            }
          }
          .btm_p1, .btm_p2{
          }
          .btm_p1{
          }
          .btm_p2{
            .p{
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: nowrap;
              padding-bottom: 10px;
              p{
              }
            }
            .p1{
            }
            .p2{
            }
            .p3{
              p{
                &:first-child{
                  white-space: nowrap;
                }
              }
            }
            .p4{
              padding-bottom: 0;
              p{
                &:first-child{
                  white-space: nowrap;
                }
              }
            }
          }
          &.e_area{
            width: 100%;
            margin: 0 auto;
          }
          &.d_area{
            width: 100%;
          }
          &.a_area{
            width: 100%;
            padding: 20px 0 0;
            margin: 0;
          }
        }
        .sec_wh_tex{
        }
        .lef_area, .rig_area{
          width: 95%;
          margin: 0 auto;
        }

        .anime2{
        }

        .area{
          width: 95%;
          margin: 0 auto;
          max-width: none;
        }
        .area1{
          &::after{
            background-image: url('../img/child/wh_tex_bg.png');
            width: clamp(100%, 5vw, 100%);
            height: clamp(43%, 5vw, 100%);
          }
          .area1_img1{
            width: 100%;
            margin: 0 auto;
          }
          .area1_img3{
            width: 45%;
          }
          
        }
        .area2{
          .area2_img1{
            width: 100%;
            margin: 20px auto 50px;
          }
          .area2_img2{
            width: 100%;
            margin: auto;
          }
          .area2_img3{
            width: 100%;
          }
          .area2_img4{
            width: 45%;
            .sec4_4{
            }
          }
          .area2_img5{
            width: 100%;
            margin: -13% 0 0;
            .sec4_5{
              margin: -5% 0 0 auto;
            }
            .sec4_6{
              margin: -3% 0 0 12%;
            }
          }
          
        }

        .wh_tex_wrap{
        }
        .sec_wh_tex{
          font-size: clamp(10px, 3.3vw, 25px);
          letter-spacing: 1px;
        }

      }

      .sec1{
        .sec1_bg{
          background-image: url('../img/child/sec1_bg1_sp.png');
          .p1{
            font-size: clamp(22px, 3vw, 33px);
            line-height: 2em;
            padding: 35px 0 20px;
            .fs1{
              font-size: clamp(28px, 3vw, 44px);
            }
            .fs2{
              font-size: clamp(35px, 4vw, 53px);
              .fs3{
              }
            }
          }
          .dashed_wrap{
            width: 80%;
            .blue{
              background-image: linear-gradient(to right, #408eb2 13px, transparent 13px);
            }
            .no1_list{
              .anime2{
                width: 100%;
                &.scrollin {
                  &:nth-of-type(1){
                    animation-delay: .0s;
                  }
                  &:nth-of-type(2){
                    animation-delay: .0s;
                  }
                  &:nth-of-type(3){
                    animation-delay: .0s;
                  }
                  &:nth-of-type(4){
                    animation-delay: .0s;
                  }
                  &:nth-of-type(5){
                    animation-delay: .0s;
                  }
                }
                img{
                  width: 120px;
                }
                .title{
                  font-size: clamp(12px, 3.5vw, 18px);
                  width: 70%;
                  &::before, &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 30px;
                    height: 53px;
                    background-size: 100% 100%;
                    background-position: center;
                  }
                  &::before{
                    left: -8%;
                  }
                  &::after{
                    right: -8%;
                  }
                }
                .item_btm{
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .tex_box{
                    padding: 18px 0 0;
                    p{
                    }
                    .tex_box_p1{
                      font-size: clamp(20px, 3.7vw, 25px);
                      .small{
                        font-size: clamp(13px, 1.9vw, 18px);
                      }
                    }
                    .tex_box_p2{
                      font-size: clamp(16px, 3vw, 19px);
                      span{
                        font-size: clamp(30px, 5.5vw, 37px);
                      }
                    }
                    .tex_box_p3{
                      font-size: clamp(11px, 3vw, 13px);
                      letter-spacing: 0;
                      line-height: initial;
                    }
                  }
                }
                
              }
              .item{
                padding-bottom: 50px;
              }
              .item1{
                .tex_box{
                }
              }
              .item2{
                width: 100%;
                padding: 0;
                .tex_box{
                  width: 267px;
                }
              }
              .item3{
                width: 100%;
                padding: 60px 0 0;
              }
            }
          }
        }
      }
      .sec2{
        .sec2_bg{
          background-image: url('../img/child/sec2_bg1_sp.png');
          padding: 80px 0 20px;
          .img_wrap{
            position: static;
            height: auto;
            .title_area{
              .title_p1{
                font-size: clamp(55px, 8vw, 130px);
              }
              .title_p2{
                .ft1{
                }
                .ft2{
                }
              }
            }
            .tex{
            }
            .img_wrap{
              position: static;
              width: 95%;
              margin: 0 auto;
              top: auto;
              left: auto;
            }
            .sec2_1{
              margin: 100px auto 0;
              animation-delay: 0s;
              .circle{
                top: -26%;
                left: 0;
                width: 40%;
                .p_wrap{
                  p{
                    font-size: clamp(16px, 3vw, 20px);
                    letter-spacing: 0;
                    span{
                      font-size: clamp(21px, 6.7vw, 40px);
                      letter-spacing: -1px;
                    }
                  }
                  &::before{
                  }
                }

              }
            }
            .sec2_2{
              z-index: 1;
            }
            .sec2_3{
              position: relative;
              animation-delay: 0s;
              img{
              }
              .sp_sec2_3_1{
              }
              .sp_sec2_3_2, .sp_sec2_3_3{
                position: absolute;
                top: 0;
                left: 0;
                
              }

              .tex{
                top: 33%;
                left: 9%;
                z-index: 1;
                width: 100%;
                font-size: clamp(11px, 3vw, 25px);
              }
            }
            .sec2_4{
              padding: 30px 0 70px;
              animation-delay: 0s;
              p{
                font-size: clamp(15px, 2.7vw, 20px);
                &.img_btm1{
                  position: static;
                  top:auto;
                  right: auto;
                }
              }
              
            }
            .sec2_5{
              z-index: 0;
              padding: 0 0 70px;
              animation-delay: 0s;
              p{
                font-size: clamp(15px, 2.7vw, 20px);
                &.img_btm2{
                  position: static;
                  width: auto;
                  top:auto;
                  right: auto;
                }
              }
              
            }
            .sec2_6{
              width: 85%;
            }
          }
          .sec2_btm_area{
            padding: 0 0 110px;
            .wh{
            }
            .list{
              justify-content: space-between;
              .item{
                width: 49%;
                margin: 15px 0;
                flex-wrap: wrap;
        
                .tex{
                  margin: 0 -6% 0 0;
                  width: 95%;
                  .p1_1{
                    font-size: clamp(12px, 1.7vw, 18px);
                    text-align: left;
                    margin-left: 34px;
                  }
                  .p1{
                    font-size: clamp(12px, 1.7vw, 18px);
                    padding-bottom: 5px;
                    letter-spacing: 0;
                    span{
                      font-size: clamp(11px, 1.7vw, 16px);
                      padding: 4px 4px 3px 3px;
                      &.pink{
                      }
                      &.purple{
                      }
                      &.green{
                      }
                    }
                  }
                  .p2{
                    font-size: clamp(11px, 1.7vw, 18px);
                    padding-top: 5px;
                    letter-spacing: 0;
                  }
                }
                .pink_tex{
                  margin: -21px 0 0 0;
                }
                  .circle{
                    margin: -9% 0 0 auto;
                    p{
                      font-size: clamp(12px, 1.7vw, 15px);
                      .fs1{
                        font-size: clamp(18px, 1.7vw, 30px);
                        
                      }
                      .fs2{
                      }
                    }
                    &.pink{
                    }
                    &.purple{
                    }
                    &.green{
                    }
                  }
              }
            }
          }
        }
      }
      .sec3{
        .sec3_bg{
          .sec3_bg_in{
            .sec3_area{
              .sec3_title{
                text-align: left;
                margin: auto;
              }
              .area1{
                .sec3_1{
                  
                }
              }
              .area2{
                .area2_img3{
                  margin: 50px auto;
                }
              }
            }
            .sec3_wh_tex_wrap{
              padding: 19% 0 5%;
              margin: 0 auto;
              width: auto;
              .sec3_wh_tex{
              }
            }
            .sec3_2{
            }
            .sec3_3{
              img{
                width: 80%;
                margin-left: auto;
                display: block;
              }
            }
            .sec3_4{
            }
            .sec3_img{
              padding-top: 0;
            }

          }
        }
      }
      .sec4_5_bg{
        background-image: url('../img/child/sec4_5_bg_sp.png');
        .sec4{
          margin: 70px 0;
          .sec4_in{
            .sec4_area{
              .area1{
                flex-direction: column-reverse;
                .area1_img1{
                  width: 100%;
                  margin: 0 auto;
                }
                .sec4_wh_tex_wrap{
                  padding: 24% 0 5%;
                  margin: 0 auto;
                  width: auto;
                  .sec4_wh_tex{
                    font-size: clamp(10px, 3.1vw, 25px);
                  }
                }
                &::after{
                  right: 0;
                  left: auto;
                  transform: scaleX(-1);
                }
              }
              .area2{
                margin: 60px auto 0;
                max-width: 1300px;
                width: 95%;
                .area2_img1{
                  width: 100%;
                  order: 4;
                }
                .area2_img2{
                  width: 100%;
                  margin: 20px auto 50px;
                }
                .area2_img4{
                  width: 100%;
                }
                .area2_img5{
                  margin: 0 auto;
                }
                
              }
            }
            .sec_title_sec4{
              margin: auto;
            }
            .sec_tex_area{
              &.f_area{
                width: 100%;
                margin: auto;
              }
              &.c_area{
                width: 100%;
                margin: auto;
                padding: 0;
              }
              &.b_area{
                margin: 0 0 0 auto;
              }
            }
            .sec4_3{
              padding: 70px 0 0;
            }
            .b_area{
            }
          }
        }
        .sec5{
          padding: 70px 0 30px;
          .sec5_in{
            .sec5_title{
              text-align: left;
              margin: auto;
            }
            .area1{
              .sec5_wh_tex_wrap{
                margin: 0 auto;
                width: auto;
                padding: 22% 0 5%;
                .sec5_wh_tex{
                  font-size: clamp(10px, 3.1vw, 25px);
                }
              }
              .area1_img1{
                width: 100%;
              }
              .sec5_img{
                padding-top: 50px;
                .img{
                  width: 100%;
                }
              }
              &::after{
                height: clamp(21%, 5vw, 100%);
              }
            }
          }
        }

      }
    }

  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
}
