@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#top{
  header{
    display: none;
    transition: .5s;
    &.display_b{
      display: block;
    }
  }
  footer{
    .apology_wrap{
      display: block;
      text-align: center;
      padding: 50px 0 0;
      p{
        font-size: 13px;
      }
    }
  }
  .loading{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 9999;
    transition: 0s;
    .loading-wrapper{
      max-width: none;
      width: 35%;
    }
    &.isLoaded{
      transition: 0.5s;
      opacity: 0;
      z-index: -1;
    }
  }

  .flt_bnr_wrap{
    flex-direction: column-reverse;
    position: fixed;
    z-index: 101;
    right: 0;
    bottom: 5px;
  }
  .flt_bnr{
    // width: 85%;
    // max-width: 530px;
    // width: 85%;
    // width: clamp(300px,30vw,530px);
    width: clamp(300px,28vw,500px);
    // max-width: 530px;

    // position: fixed;
    // right: 0;
    // bottom: 5px;
    // bottom: 55px;
    // z-index: 101;
    transition: 1s;
    cursor: pointer;
    position: relative;
    // &.no_active{
    //   opacity: 0;
    //   z-index: -10;
    // }
    &.hide{
      opacity: 0;
      z-index: -10;
    }
    &:before{
      position: relative;
    }
    .close{
      width: 30px;
      height: 30px;
      right: 0;
      top: -35px;
      position: absolute;
      border: 1px solid #fff;
      background: #8a5e00;
      border-radius: 50%;
      // cursor: pointer;
      &:before{
        position: relative;
      }
      span{
        width: 20px;
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        &:nth-of-type(1){
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &:nth-of-type(2){
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }

  .flt_bnr2{
    // bottom: clamp(200px,22vw,400px);
    // bottom: clamp(200px,20vw,400px);

    // bottom: 22vw;
    // bottom: clamp(250px,75vw,300px);
    margin-bottom: 45px;
    position: relative;
    .bnr{
      .btn{
        width: 100%;
        text-align: center;
        position: relative;
        padding: 8px 0;
        a{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        p{
          font-size: clamp(10px,3.5vw,16px);
        }
        &.btn_req{
          background: #ED1C24;
        }
        &.btn_res{
          background: #29ABE2;
        }
      }
      .close{
        background: #29ABE2;
      }
    }
    // .flt_btn{
    //   .btn{
    //     width: 49%;
    //     text-align: center;
    //     position: relative;
    //     a{
    //       width: 100%;
    //       height: 100%;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //     }
    //     p{
    //       font-size: clamp(10px,3.5vw,16px);
    //     }
    //   }
    // }
  }


  .mdl_bnr{
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.5);
    transition: 1s;
    &.no_active{
      opacity: 0;
      z-index: -10;
    }
  .bnr{
    // width: 90%;
    // max-width: 700px;
    // max-width: 450px;
    width: 80%;
    max-width: 800px;
    // margin: 0 auto;
    position: relative;
    &.bnr_r{
      width: 40%;
      max-width: 397px;
      margin-left: 1vw;
    }
    a{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .mdl_btn{
      .btn{
        width: 100%;
        text-align: center;
        position: relative;
        // padding: 8px 0;
        padding: 1px 0;
        p{
          // font-size: clamp(10px,3.5vw,16px);
          font-size: clamp(10px,1vw,16px);
        }
        &.btn_req{
          background: #ED1C24;
        }
        &.btn_res{
          background: #29ABE2;
        }
        &.res{
          background: #c1272d;
          border: solid 1px #c1272d;
        }
      }
    }
    .close{
      width: 30px;
      height: 30px;
      right: 0;
      top: -35px;
      position: absolute;
      border: 1px solid #fff;
      background: #c1272d;
      border-radius: 50%;
      cursor: pointer;
      &:before{
        position: relative;
      }
      span{
        width: 20px;
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        &:nth-of-type(1){
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &:nth-of-type(2){
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
    &:hover{
      .mdl_btn{
        .res{
            background: #fff;
          p{
            color: #c1272d;
          }
        }
      }
    }
  }
}

  .main_wrap{
    width: 100%;
    // height: 100vh;
    overflow: hidden;
    .main_mov{
      width: 100%;
      position: relative;

      // transition: 2s;
      // z-index: 10;
      video{
        width: 100%;
      }
      &.show_n{
        opacity: 0;
        z-index: 0;
      }
      .wappen{
        position: absolute;
        bottom: 0;
        right: 2%;
        width: 23%;
        max-width: 285px;
      }
    }

    .main_in{
      width: 100%;
      height: 100%;
      background-image: url('../img/top/main_bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;

      // opacity: 0;
      // transition: 2s;
      // &.show{
      //   opacity: 1;
      //   z-index: 10;
      // }
      .main_txt{
        width: 100%;
        height: 100%;
        .ani_txt{
          width: 100%;
          height: 100%;
          background-image: url('../img/top/ani_txt.png');
          background-repeat: no-repeat;
          background-size: 97%;
          background-position: top;
          opacity: 0;
          transition: 2s;
          &.show{
            opacity: 1;
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .loading{
      .loading-wrapper{
        width: 80%;
        max-width: 450px;
      }
    }

    .flt_bnr_wrap{
      bottom: 55px;
    }

    .flt_bnr{
      width: clamp(300px,33vw,530px);
      // bottom: 55px;
    }

    .flt_bnr2{
      // bottom: clamp(250px,75vw,300px);
    }

    .mdl_bnr{
      flex-direction: column;
      .bnr{
        width: 95%;
        max-width: 750px;
        &.bnr_r{
          width: 55%;
          max-width: 300px;
          margin-top: 2vw;
          margin-left: 0;
        }
        // .close{
        //   right: -35px;
        //   top: 0;
        // }
      }
    }

    .main_wrap{
      height: 136vw;
      .main_mov{
        .wappen{
          width: 40%;
          bottom: 2%;
          right: -1%;
        }
      }
      .main_in{
        background-image: url('../img/top/main_bg_sp.jpg');
        background-size: 100%;
        background-position: top;
        .main_txt{
          width: 100%;
          height: 100%;
          // top: 66vw;
          // width: 58%;
          // .mv_p1{
          //   font-size: clamp(10px,7vw,85px);
          // }
          // .mv_p2{
          //   font-size: clamp(10px,5vw,50px);
          //   margin: 5px 0 40px;
          // }
          // .mv_img{
          //   width: 85%;
          // }
          .ani_txt{
            background-image: url('../img/top/ani_txt_sp.png');
            background-size: 97%;
            background-position: top;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .top_wrap{
    .price_area{
      background: #869001;
      padding: 40px 0 20px;
      width: 100%;
      position: relative;
      z-index: 0;
      .price_in{
        justify-content: center;
        // max-width: 980px;
        // max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        .price{
          // width: 28%;
          min-width: 50px;
          // max-width: 300px;
          &.price2{
            margin: 0 clamp(20px,2vw,50px);
            // width: 29%;
          }
          &.price3{
            // width: 36%;
            // max-width: 385px;
          }
          p{
            color: #fff;
            font-family: 'k_goth6',sans-serif;
            letter-spacing: .1em;
          }
          .pri_note{
            font-size: 10px;
          }
          .c{
            margin: 0 7px;
          }
          .pri_p1{
            margin-top: 5px;
            span{
              font-size: clamp(10px,2vw,30px);
              b{
                font-size: clamp(10px,1.6vw,20px);
              }
            }
          }
          .pri_p2{
            span{
              // font-size: clamp(10px,5.8vw,70px);
              font-size: clamp(10px,5vw,65px);
            }
          }
          .pri_p3{
            font-size: clamp(10px,1vw,20px);
            line-height: 1em;
          }
          .pri_p4{
            font-size: clamp(10px,2vw,22px);
            line-height: 1.3em;
            text-align: center;
          }
          .l{
            flex-direction: column;
          }
          .c{
          }
          .r{
            flex-direction: column;
          }
        }
      }
    }

    .sec1{
      .catch{
        background: var(--col-green2);
        padding: 35px 0 30px;
        p{
          color: var(--col-white);
          font-family: 'k_goth6',sans-serif;
        }
        .catch_p{
          text-align: center;
          font-size: clamp(10px,5vw,30px);
          letter-spacing: .01em;
        }

        .merit_wrap{
          max-width: 1300px;
          .merit_box{
            width: 100%;
            &.merit_box_t{
              border-top: solid 1px var(--col-white);
              padding-top: 20px;
              margin: 20px 0 30px;
              justify-content: center;
              .merit_01{
                justify-content: center;
              }
              // .merit_02{
              //   border-left: solid 1px var(--col-white);
              //   border-right: solid 1px var(--col-white);
              //   padding: 0 10px;
              //   margin:0 10px;
              // }
            }
            .merit{
              height: 55px;
              p{
                letter-spacing: .01em;
                span{
                  &.s1{
                    // font-size: clamp(10px,3.5vw,50px);
                    font-size: clamp(10px,12vw,50px);
                    padding: 0 2px 0 0;
                    line-height: 1.1em;
                    color: #ffd93b;
                  }
                }
              }
              .rl{
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                font-size: clamp(10px,3vw,12px);
                padding: 3px 0;
              }
              .m_p1{
                font-size: clamp(10px,3vw,13px);
                line-height: 1;
              }
              .m_p2{
                // font-size: clamp(10px,1.6vw,24px);
                font-size: clamp(10px,5.5vw,24px);
                padding: 0 5px;
              }
              .m_p2_sp{
                font-size: clamp(10px,4.8vw,24px);
                padding: 0 1px;
              }
              .m_p3{
                // font-size: clamp(10px,1.2vw,18px);
                font-size: clamp(10px,4.5vw,18px);
                line-height: 1em;
              }
              &.merit_02{
                border-left: solid 1px var(--col-white);
                // border-right: solid 1px var(--col-white);
                padding: 0 10px;
                margin:0 10px;
              }
            }

            &.merit_box_b{
              border-top: solid 1px var(--col-white);
              padding-top: 10px;
            }
            .box{
              width: calc(100%/2);
              margin: 0 auto;
              position: relative;
              .box_in{
                height: 105px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .m_p4{
                text-align: center;
                font-size: clamp(10px, 2.1vw, 22px);
                letter-spacing: 0;
                // font-size: clamp(10px,4.5vw,22px);
                // letter-spacing: .01em;
                // height: 100%;
                  .m_p4_fs{
                    font-size: clamp(10px, 10vw, 40px);
                    display: inline-block;
                    margin-right: 1px;
                  }
                  .co_ye{
                    color: #ffd93b;
                  }
                }
              &:nth-of-type(-n+4){
                &::before{
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 95%;
                  height: 1px;
                  background: var(--col-white);
                }
              }
              &:nth-of-type(1),&:nth-of-type(3){
                &::before{
                  left: 0%;
                }
              }
              &:nth-of-type(2),&:nth-of-type(4){
                &::before{
                  right: 0%;
                }
              }
              &:nth-of-type(odd){
                &:after{
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translate(0,-50%);
                  width: 1px;
                  height: 90%;
                  background: var(--col-white);
                }
              }
            }
          }
        }
      }
      .info_wrap{
        background: #dff0dd;
        padding: 45px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p{
          text-align: center;
        }
        .inf_p1{
          font-size: clamp(10px,4.2vw,20px);
        }
        .inf_p2{
          font-size: clamp(10px,7.3vw,45px);
          color: #929c01;
          margin: 12px 0;
          font-family: 'k_goth6',sans-serif;
          span{
            font-size: clamp(10px,6.5vw,35px);
            display: block;
            margin-top: -10px;
          }
        }
        .inf_p3{
          font-size: clamp(10px,3.5vw,15px);
        }
        .btn_wrap{
          max-width: 720px;
          width: 85%;
          margin: 35px auto 0;
          justify-content: space-between;
          .req_btn{
            width: 45%;
            // max-width: 300px;
            padding: 10px 0;
            color: var(--col-white);
            transition: .5s;
            position: relative;
            p{
              font-size: clamp(10px,4vw,16px);
            }
            a{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
            span{
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translate(0,-50%);
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-left: 17px solid var(--col-white);
              border-bottom: 10px solid transparent;
            }
            &.req{
              &:hover{
                span{
                  border-left: 17px solid var(--col-red);
                }
              }
            }
            &.res{
              &:hover{
                span{
                  border-left: 17px solid var(--col-blue);
                }
              }
            }
          }
        }
        .bnr_area{
          max-width: 800px;
          width: 90%;
          margin: 50px auto 0;
          .bnr{
            margin: 30px 0;
            position: relative;
            &:hover{
              opacity: .7;
            }
            a{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .holiday_wrap{
          width: 85%;
          max-width: 720px;
          margin: 50px auto;
          background-color: #fff;
          border: 1px solid #960e16;
          padding: 10px;
          box-sizing: border-box;
          text-align: center;
          p{
            font-size: clamp(10px,3.5vw,15px);
            color: #960e16;
            margin: 10px 0;
            line-height: 1.2em;
            a{
              color: #960e16;
              border-bottom: solid 1px #960e16;
            }
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .top_wrap{
      .price_area{
        padding: 15px 0 10px;
        z-index: 0;
        .price_in{
          .price{
            width: 96%;
            max-width: 470px;
            justify-content: center;
            margin: 10px auto;
            .l{
              width: clamp(90px,25vw,155px);
            }
            &.price1{
              .pri_p1{
                span{
                  // font-size: clamp(10px,6vw,36px);
                  display: inline-block;
                  margin: 1px 0;
                }
              }
            }
            &.price2{
              // width: 100%;
              margin: 15px auto;
              .l{
                // margin-right: 7px;
              }
            }
            &.price3{
              // width: 100%;
              .pri_p1{
                span{
                  // font-size: clamp(10px,9vw,36px);
                  display: inline-block;
                  margin: 1px 0;
                }
              }
              // margin-top: 8vw;
              // position: relative;
              // .l2{
              //   position: absolute;
              //   top: -7vw;
              //   left: 53%;
              //   transform: translate(-50%,0);
              //   width: 100%;
              // }
            }
            .pri_p1{
              span{
                font-size: clamp(10px,7vw,38px);
                b{
                  font-size: clamp(10px,4vw,20px);
                }
              }
            }
            .pri_p2{
              span{
                font-size: clamp(10px,16vw,75px);
              }
            }
            .pri_p3{
              font-size: clamp(10px,4.5vw,20px);
            }
            .pri_p4{
              font-size: clamp(10px,4vw,20px);
            }
            .c{
              margin-top: 10px;
            }
            .r{
              margin-bottom: -5px;
            }
          }
        }
      }
      .sec1{
        .catch{
          .merit_wrap{
            .merit_box{
              justify-content: center;
              &.merit_box_t{
                padding-top: 10px;
                margin: 20px 0;
              }
              .merit{
                height: auto;
                // padding: 5px 0;
                margin: 5px 0;
                align-items: flex-end;
                .m_p1{
                  &.m_b{
                    margin-bottom: 4px;
                  }
                }
                .m_p3{
                  margin-bottom: -5px;
                }
                .m_p5{
                  margin-bottom: -10px;
                }
                &.merit_02{
                  border-left: none;
                  border-right: none;
                  justify-content: center;
                }
              }

              &.merit_box_b{
              }
              .box{
                width: calc(100%/1);
                // margin: 0 auto;
                padding: 10px 0;
                .box_in{
                  height: auto;
                }
                .m_p4{
                  letter-spacing: -0.01em;
                  font-size: clamp(10px,4.5vw,23px);
                  // span{
                  //   font-size: clamp(10px,4.5vw,23px);
                  // }
                }
                &:nth-of-type(-n+4){
                  &::before{
                    display: none;
                  }
                }
                &:nth-of-type(odd){
                  &:after{
                    display: none;
                    right: auto;
                    top: auto;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%,0);
                    width: 100%;
                    height: 1px;
                    // background: var(--col-white);
                  }
                }
              }
            }
          }
        }
        .info_wrap{
          padding: 30px 0;
          .inf_p2{
            // font-weight: 600;
            letter-spacing: .01em;
            span{
              margin-top: -5px;
            }
          }
          .inf_p3{
            letter-spacing: .01em;
          }
          .btn_wrap{
            .req_btn{
              width: 100%;
              margin: 5px 0;
            }
          }
          .bnr_area{
            margin: 15px auto 0;
            .bnr{
              margin: 15px 0;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec{
    .sec_text_area{
      text-align: center;
      .sec_p1{
        color: var(--col-green);
        font-size: clamp(10px,8vw,95px);
        font-family: "apotf", sans-serif;
        line-height: 1em;
      }
      .sec_p2{
        font-size: clamp(10px,2vw,24px);
        font-family: "k_goth6", sans-serif;
        margin: 25px 0;
      }
      .sec_p3{
        font-size: clamp(10px,1.7vw,18px);
      }
    }

    .click_btn{
      background: #42210B;
      border: solid 1px #42210B;
      max-width: 270px;
      width: 65%;
      margin: 50px auto 0;
      border-radius: 20px 20px 0 20px;
      padding: 10px 0;
      position: relative;
      p{
        color: var(--col-white);
        font-size: clamp(10px,4vw,18px);
        font-family: "apotf", sans-serif;
        letter-spacing: .1em;
        text-align: center;
        transition: .5s;
      }
      .arrow{
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background: var(--col-white);
        width: 20px;
        height: 15px;
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translate(0,-50%) rotate(90deg);
        transition: transform .5s;
      }
      a{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:hover{
        p{
          letter-spacing: .2em;
        }
        .arrow{
          transition: transform .5s;
          transform: translate(0,-50%) rotate(90deg) rotateY(180deg);
        }
      }
    }

    .anime_img{
      // &.scrollin {
      //   animation: kani1 2s forwards;
      // }
      img{
        // transition: 0.6s 0s cubic-bezier(.52, .08, .18, 1);
        transform: scale(1);
        opacity: 0;
        &.scrollin {
          animation: kani1 2s .4s forwards;
        }
      }

      .cont_bg{
        img{
          // transition: 0.6s 0s cubic-bezier(.52, .08, .18, 1);
          transform: scale(1);
          opacity: 0;
          &.scrollin {
            // animation: kani1 2s .6s forwards;
            animation: kani2 2s 1s forwards;

          }
        }
      }
    }
  }

  @keyframes kani1 {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
    60% {
      opacity: 1;
      transform: scale3d(1.05, 1.05, 1.05);
    }
    80% {
      transform: scale3d(1);
    }
    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }

  @keyframes kani2 {
    0%, 20%, 40%, 60%, 80%, 70%, 90%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    0% {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
    60% {
      opacity: 1;
      transform: scale3d(1.2, 1.2, 1.2);
    }
    80% {
      transform: scale3d(1);
    }
    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec{
      .sec_text_area{
        width: 95%;
        margin: 0 auto;
        p{
          letter-spacing: .05em;
        }
        .sec_p1{
          font-size: clamp(10px,16vw,95px);
        }
        .sec_p2{
          font-size: clamp(10px, 4.8vw, 24px);
        }
        .sec_p3{
          font-size: clamp(10px,3.8vw,18px);
          letter-spacing: -0.05em;
        }
      }

      .click_btn{
        margin: 25px auto 0;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________



  .sec2{
    position: relative;
    padding: 50px 0 100px;
    &::before{
      content: "";
      width: 100%;
      height: 50%;
      background: #dff0dd;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      padding: 30px 0 50px;
      &::before{
        height: 20%;
      }
      .cont_img{
        display: flex;
        justify-content: center;
        img{
          width: 150%;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________

  .sec3{
    background: #dff0dd;
    padding: 80px 0 0;
    .sec_text_area{
      .sec_p1{
        font-size: clamp(10px,6vw,65px);
      }
    }
    .cont_img{
      position: relative;
      transform: translate(0,0);
      transition: 2s;
      &::before,&::after{
        content: "";
        width: 50%;
        height: 100%;
        background: #dff0dd;
        position: absolute;
        top: 0;
        transition: 2s;
      }
      &::before{
        left: 0;
      }
      &::after{
        right: 0;
      }
      &.scrollin{
        &::before{
          left: -50%;
        }
        &::after{
          right: -50%;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
      background: #dff0dd;
      padding: 35px 0 0;
      .sec_text_area{
        .sec_p1{
          font-size: clamp(10px,12vw,65px);
        }
      }
      .cont_img{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        img{
          width: 140%;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________

  .sec4{
    // padding: 80px 0 80px;
    justify-content: flex-end;
    overflow: hidden;
    flex-wrap: nowrap;
    .sec_text_area{
      margin-right: 5vw;
      text-align: left;
    }
    .click_btn{
      margin-left: 0;
    }
    .cont_img{
      width: 58%;
      // padding-top: 45px;
      position: relative;
      overflow: hidden;
      &::before{
        content: "";
        width: 2%;
        height: 100%;
        background: linear-gradient(90deg,#fff 0%,rgba(255,255,255,.0));
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .bg_img{
        display: flex;
        overflow: hidden;
        // img{
        //   width: 100%;
        // }
      }
      .cont_bg{
        position: absolute;
        bottom: 0;
        right: 0;
        // width: 110%;
        // z-index: -1;
      }
      // img{
      //   vertical-align: bottom;
      // }
      .anime_scroll1{
        animation: loop 60s -30s linear infinite;
      }
      @keyframes loop {
        0% {
          transform: translateX(100%);
        }
        to {
          transform: translateX(-100%);
        }
      }
      .anime_scroll2{
        animation: loop2 60s linear infinite;
      }
      @keyframes loop2 {
        0% {
          transform: translateX(0);
        }
        to {
          transform: translateX(-200%);
        }
      }

      .anime_scroll3{
        animation: loop3 60s -30s linear infinite;
      }
      @keyframes loop3 {
        0% {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(100%);
        }
      }
      .anime_scroll4{
        animation: loop4 60s linear infinite;
      }
      @keyframes loop4 {
        0% {
          transform: translateX(-200%);
        }
        to {
          transform: translateX(0);
        }
      }
      // .anime_scroll1{
      //   animation: loop-slide 20s infinite linear 1s both;
      // }
      // @keyframes loop-slide {
      //   from {
      //     transform: translateX(0);
      //   }
      //   to {
      //     transform: translateX(-100%);
      //   }
      // }
    }
  }
  // // ______________________________________
  // @media screen and (max-width: 1160px){
  //   .sec4{
  //     .sec_text_area{
  //       margin-right: 0;
  //     }
  //     .cont_img{
  //       width: 48%;
  //     }
  //   }
  // /*@media screen end*/}
  // // ______________________________________
  // // ______________________________________
  // // ______________________________________
  //
  // // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec4{
      padding: 40px 0 0;
      justify-content: center;
      flex-wrap: wrap;
      .sec_text_area{
        margin-right: auto;
        position: relative;
        z-index: 2;
        // text-align: left;
      }
      .cont_img{
        width: 100%;
        // margin-top: -70vw;
        margin-top: -130px;
        // margin-right: -14vw;
        // position: relative;
        &::before{
          content: "";
          width: 110%;
          height: 90%;
          background: linear-gradient(180deg,#fff 25%,rgba(255,255,255,.0));
          position: absolute;
          top: -10%;
          left: -10vw;
          z-index: 1;
        }
        img{
          width: 115%;
        }
        // padding-top: 45px;
        // position: relative;

        // .cont_bg{
        //   right: -8%;
        //   width: 115%;
        // }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________

  .sec5{
    .btn_box{
      width: calc(100%/2);
      height: 450px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      transition: 1s;
      &.w_100{
        width: 100%;
      }
      &:hover{
        &::before{
          background: rgba(#534741,.2);
        }
      }
      &.none{
        pointer-events: none;
        &::before{
          background: rgba(#534741,.9);
        }
      }
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(#534741,.7);
        position: absolute;
        top: 0;
        left: 0;
        transition: 1s;
      }
      .btn_text{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        .btn_p1{
          width: 100%;
          color: var(--col-white);
          font-size: clamp(10px,8vw,55px);
          font-family: "apotf", sans-serif;
          line-height: 1.2em;
          letter-spacing: .1em;
        }
      }
      .arrow_r,.arrow_l{
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        background: #D8E7D7;
        width: 55px;
        height: 50px;
        position: absolute;
      }
      .arrow_r{
        top: 0;
        right: 0;
        transform: rotate(180deg);
      }
      .arrow_l{
        bottom: 0;
        left: 0;
      }
      .img_note{
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 10px;
        color: #fff;
        padding: 5px;
      }
      &.location{
        background-image: url('../img/top/location_bg.jpg');
      }
      &.design{
        background-image: url('../img/top/design_bg.jpg');
      }
      &.access{
        background-image: url('../img/top/access_bg.jpg');
      }
      &.quality{
        background-image: url('../img/top/quality_bg.jpg');
      }
    }
    .click_btn{
      background: var(--col-white);
      p{
        color: #42210B;
      }
      .arrow{
        background: #42210B;
      }
      &:hover{
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: inc_set.$media_sp){
    .sec5{
      .btn_box{
        width: calc(100%/1);
        height: 60vw;
        background-size: 150%;
        background-position: center;
        .arrow_r,.arrow_l{
          width: 45px;
          height: 40px;
        }
        &.design{
          background-image: url('../img/top/design_bg_sp.jpg');
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
